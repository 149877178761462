import conftentfulConfig from "contentful.config"

export * from "./icons"
export * from "./routes"

/**
 * Payment
 */
export const PAYENGINE_STAGES = ["sandbox", "production"] as const
// https://github.com/prettier/prettier/issues/14985
// prettier-ignore
export type PayengineStage = typeof PAYENGINE_STAGES[number]

/**
 * https://docs.payengine.de/systemurl
 */
export const payengineConfigUrls: {
  [key in PayengineStage]: {
    merchantCenter: string
    apiHost: string
    paymentPage: string
    jsBridge: string
    widget: string
  }
} = {
  sandbox: {
    merchantCenter: "https://merchanttest.payengine.de",
    apiHost: "https://apitest.payengine.de/v1",
    paymentPage: "https://pptest.payengine.de",
    jsBridge: "https://pptest.payengine.de/bridge/1.0/payengine.min.js",
    widget: "https://pptest.payengine.de/widgetjs/payengine.widget.min.js",
  },
  production: {
    merchantCenter: "https://merchant.payengine.de",
    apiHost: "https://api.payengine.de/v1",
    paymentPage: "https://pp.payengine.de",
    jsBridge: "https://pp.payengine.de/bridge/1.0/payengine.min.js",
    widget: "https://pp.payengine.de/widgetjs/payengine.widget.min.js",
  },
}

/**
 * Environment Variables
 */
export type EnvironmentVariables = {
  googleMapsApiKey?: string
  payengineStage?: PayengineStage
  registrationUrl?: string
  environment?: string
}

/**
 * Global variables that could be used in single or multiple components without much props drilling.
 */
export type GlobalVariables = {
  registrationUrl?: string
}

/**
 * Auth config
 */
export const AUTH_CONFIG = {
  // Mininum value is 1 for each setting below
  sessionDurationSeconds: 300, // 5 minutes
  sessionRefreshIntervalSeconds: 60, // 1 minute
} as const

// ⚠️ Only add values below that are intended for public use.
// This may include tokens such as the Google Maps API key, as long as they are
// read-only scoped keys that are officially intended to be made public.
export const EXTERNAL_SERVICES = {
  google: {
    gtm: {
      containerId: "GTM-MCQCD7H",
    },
  },
  contentful: conftentfulConfig,
  userCentrics: "wgfIssISl",
  metaVerificationCode: "woh9vcftzbd0p3ajhwtwuow5ykjndj",
} as const
