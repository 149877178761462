import { getTheme, mergeTheme } from "src/states/theme"
import { isBrowser } from "src/utils/envUtils"

type themeType = "huk24" | "hukcoburg"
type UseThemeProps = {
  isIframeBookingFlow?: boolean
  themeOverride?: themeType
}

const applyTheme = (theme: themeType) => {
  mergeTheme({ theme })
  const html = document.getElementsByTagName("html")[0]

  // Avoid duplicated themes
  html.classList.remove(`theme-huk24`)
  html.classList.remove(`theme-hukcoburg`)

  html.classList.add(`theme-${theme}`)
}

/**
 * Sets correct theme
 */
export function useTheme(options?: UseThemeProps): void {
  const { isIframeBookingFlow, themeOverride } = options || {}

  if (themeOverride) {
    applyTheme(themeOverride)
    return
  }

  if (isIframeBookingFlow) {
    applyTheme("huk24")
    return
  }

  if (isBrowser()) {
    const urlParams = new URLSearchParams(window.location.search)
    const themeFromUrl = urlParams.get("theme") as themeType

    if (themeFromUrl) {
      applyTheme(themeFromUrl)
      return
    }

    const { theme } = getTheme()
    if (theme) {
      applyTheme(theme)
      return
    }

    applyTheme("hukcoburg")
  }
}
