import { useRouter } from "next/router"
import { FC } from "react"

import { Modal, ModalContent } from "src/components/common/modal"

import { mergeNewsLetterStatus } from "src/states/newsLetterStatus"

import { useNewsLetterTrackingEvents } from "./hooks/useNewsLetterTrackingEvents"

export const NewsLetterModal: FC = () => {
  const Router = useRouter()

  const openModal = !!Router?.query?.openNewsLetter

  const { trackOpenCloseNewsLetter } = useNewsLetterTrackingEvents()

  function onOpenChangeModal(open: boolean) {
    Router?.replace(
      {
        // To fix the slug in the URL
        pathname: Router?.asPath.split("?")[0],
        query: open ? { openNewsLetter: true } : undefined,
      },
      undefined,
      {
        shallow: true,
      },
    )
    trackOpenCloseNewsLetter(open)

    if (!open) {
      mergeNewsLetterStatus({ interacted: true })
    }
  }

  return (
    <Modal open={openModal} onOpenChange={onOpenChangeModal}>
      <ModalContent variant="central">
        <div>
          <iframe
            height="550"
            width="99%"
            src="https://forms.zohopublic.eu/haghukautowelt/form/NewsLetter/formperma/LBOEZY3ieHBXf3GITRerh6L_OCJ2GuXj-gIo8GGHTjU"
          />
        </div>
      </ModalContent>
    </Modal>
  )
}
