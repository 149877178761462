import { DialogOverlayProps, Overlay } from "@radix-ui/react-dialog"
import { forwardRef } from "react"

import { create } from "src/helpers/bem"

import styles from "./ModalOverlay.module.scss"

const bem = create(styles, "ModalOverlay")

export const ModalOverlay = forwardRef<
  HTMLDivElement,
  DialogOverlayProps & { variant?: string }
>(({ variant = "", ...props }, ref) => (
  <Overlay
    ref={ref}
    className={bem(undefined, { [variant]: true })}
    {...props}
  />
))

ModalOverlay.displayName = "ModalOverlay"
