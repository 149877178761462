import { useEffect, useState } from "react"

/**
 * Switches to mounted after first render.
 */
export function useIsMounted() {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])
  return mounted
}
