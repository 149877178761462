import { StorageOptions } from "../syncToPersistentStorage"
import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

export type UtmProperties = {
  utm_source?: string
  utm_campaign?: string
  utm_medium?: string
}

const sessionStorage: StorageOptions = {
  key: "utm-properties",
  version: "2024-01-24",
  storage: "sessionStorage",
}

const { getValue, setValue, useValue, mergeValue } =
  createSimpleGlobalState<UtmProperties>({}, sessionStorage)

export {
  getValue as getUtmProperties,
  setValue as setUtmProperties,
  useValue as useUtmProperties,
  mergeValue as mergeUtmProperties,
}
