import { pushTrackingEventToDataLayer } from "src/helpers/tracking/gtm"
import { EventHandler } from "src/helpers/tracking/tracking-event-handlers/types"

export const support: EventHandler<undefined, "support"> = (
  { gaEvent },
  eventType = "support",
) => {
  return pushTrackingEventToDataLayer({
    eventType,
    payload: { gaEvent },
  })
}
