import { isAfter } from "date-fns/isAfter"

import { Session } from "next-auth"

type IsSessionValidType = (auth: Session) => Promise<boolean>

export const isSessionValid: IsSessionValidType = async (auth) => {
  if (auth?.expires) {
    const expirationDate = new Date(auth.expires)
    const today = new Date()

    if (isAfter(today, expirationDate)) {
      return false
    }
  }

  return true
}
