import { isBrowser } from "src/utils/envUtils"

import type {
  ServiceId,
  ServiceInfo,
  ServiceInfoFromLocalStorage,
  SettingsFromLocalStorage,
  UCWindow,
} from "./types"

/**
 * Programmatic way to show First Layer.
 * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=showfirstlayer
 *
 * @example showFirstLayer()
 */
export const showFirstLayer = (): void => {
  if (isBrowser()) {
    /**
     * This file type-casts the Window to possibly include `window.UC_UI`, which is the
     * main entry point for Usercentrics integration. It is intentionally not declared
     * globally so that it wouldn't get used directly somewhere else in the code, but
     * always through these utils.
     */
    ;(window as UCWindow).UC_UI?.showFirstLayer?.()
  }
}

/**
 * Programmatic way to show Second Layer. If a service/vendor Id value is passed,
 * Second Layer will open the right tab, scroll to the given service/vendor entry and expand it.
 * If no Id is passed, Second Layer will be shown without srcolling to any specific service/vendor.
 *
 * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=showsecondlayer
 *
 * @example showSecondLayer('my-service-id')
 * @example showSecondLayer()
 */
export const showSecondLayer = (serviceId?: ServiceId): void => {
  if (isBrowser()) {
    ;(window as UCWindow).UC_UI?.showSecondLayer?.(serviceId)
  }
}

/**
 * Get array of all services with their basic information
 * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=getservicesbaseinfo
 *
 * @example
 * const services = getServicesBaseInfo()
 * const myService = services.find((service) => service.id === 'my-service-id')
 */
export const getServicesBaseInfo = (): ServiceInfo[] =>
  (isBrowser() && (window as UCWindow).UC_UI?.getServicesBaseInfo?.()) || []

/**
 * A method for accepting multiple services without open the user centrics modal.
 * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=acceptservices
 *
 * @example
 * acceptServices([serviceIds.googleAnalitics])
 */
export const acceptServices = (serviceIds: ServiceId[]) => {
  if (isBrowser()) {
    const acceptUCServices = (window as UCWindow).UC_UI?.acceptServices
    acceptUCServices?.(serviceIds)
  }
}

/**
 * Get array of all services from local storage
 *
 * @example
 * const services = getServicesFromLocalStorage()
 * const myService = services.find((service) => service.id === 'my-service-id')
 */
export const getServicesFromLocalStorage =
  (): ServiceInfoFromLocalStorage[] => {
    const ucSettings = isBrowser() && localStorage?.getItem("uc_settings")
    if (ucSettings) {
      try {
        const ucSettingsObj = JSON.parse(ucSettings) as SettingsFromLocalStorage
        /** Leave out any other untyped fields */
        return ucSettingsObj.services.map(({ id, status }) => ({ id, status }))
      } catch {
        /** Ignore failures */
      }
    }

    return []
  }

/**
 * Returns true if Usercentrics service has been given consent
 *
 * @example
 * const services = getServicesBaseInfo()
 * const myService = services.find((service) => service.id === 'my-service-id')
 * const hasConsent = hasServiceConsent(myService)
 *
 * if (hasConsent) {
 *   loadMyService()
 * }
 */
export const hasServiceConsent = (service: ServiceInfo | null): boolean =>
  !!service?.consent.status
