import { logError } from "src/helpers/logging/logError"
import { Sentry } from "src/helpers/sentry"
import { addNotificationToast } from "src/states/notificationToasts"

type throwErrorProps = {
  /** User facing error message */
  userMessage?: string
  /** Message logged in Sentry and other services */
  logMessage?: string
  /** Where did the error happen? */
  source?: string
  /** Error object */
  error: Error
  /** Sentry logging boolean */
  shouldLogToSentry?: boolean
  /** Sentry custom tag */
  tags?: { [key: string]: string }
}

export const throwError = ({
  userMessage,
  logMessage,
  source,
  error,
  shouldLogToSentry = true,
  tags,
}: throwErrorProps) => {
  const message = logMessage ?? error.message

  logError({ exception: error, message, source })

  if (!!userMessage) {
    addNotificationToast({
      variant: "error",
      content: userMessage,
    })
  }

  if (!shouldLogToSentry) return

  Sentry.captureException(error, {
    source,
    message,
    tags,
  })
}
