import { sumServiceCategoriesPricing } from "src/helpers/sumServiceCategoriesPricing"
import {
  buildGa4EcommerceEvent,
  extractInternalServiceCategoryIds,
  parseServiceCategoriesToECommerceItem,
  parseServiceCategoriesToMetaContents,
} from "src/helpers/tracking/eventParsersAndHandlers"
import {
  GTMEvents,
  pushTrackingEventToDataLayer,
} from "src/helpers/tracking/gtm"
import {
  EventHandler,
  MetaEventData,
} from "src/helpers/tracking/tracking-event-handlers/types"
import { isValue } from "src/utils/typeGuards"

type VehicleEventData = Pick<
  MetaEventData,
  "serviceCategories" | "detailedGarage"
>

export const garages: EventHandler<VehicleEventData, "garages"> = (
  { data, gaEvent },
  eventType = "garages",
) => {
  const contents = parseServiceCategoriesToMetaContents(data?.serviceCategories)
  const meta: GTMEvents[typeof eventType]["meta"] = isValue(data)
    ? {
        content_category: eventType,
        content_ids: extractInternalServiceCategoryIds(data.serviceCategories),
        contents: parseServiceCategoriesToMetaContents(data.serviceCategories),
        currency: "EUR",
        value: sumServiceCategoriesPricing(
          data.serviceCategories,
          data.detailedGarage?.services,
        ),
        num_items: contents.reduce(
          (itemsCount, item) => (itemsCount += item.quantity),
          0,
        ),
        workshop_name: data.detailedGarage?.garage.name,
        workshop_type: data.detailedGarage?.garage.dmsProviderType,
      }
    : undefined

  const builtGaEvent = Object.assign(gaEvent || {}, {
    ecommerce:
      gaEvent?.ecommerce ??
      buildGa4EcommerceEvent(
        sumServiceCategoriesPricing(
          data?.serviceCategories,
          data?.detailedGarage?.services,
        ),
        parseServiceCategoriesToECommerceItem(
          data?.serviceCategories,
          data?.detailedGarage,
        ),
      ),
  })

  return pushTrackingEventToDataLayer({
    eventType,
    payload: { meta, gaEvent: builtGaEvent },
  })
}
