import { pushTrackingEventToDataLayer } from "src/helpers/tracking/gtm"
import { EventHandler } from "src/helpers/tracking/tracking-event-handlers/types"

export const serviceWizard: EventHandler<undefined, "serviceWizard"> = (
  { gaEvent },
  eventType = "serviceWizard",
) => {
  return pushTrackingEventToDataLayer({
    eventType,
    payload: { gaEvent },
  })
}
