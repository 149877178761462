import { signIn, useSession, UseSessionOptions } from "next-auth/react"

import { AuthData } from "../types"
import { throwError } from "src/components/pages/error/throwError"
import { ROUTES } from "src/config"

export * from "../types"

export async function signInWithOAuth(callbackUrl?: string) {
  try {
    await signIn("oauth", { callbackUrl })
  } catch (error) {
    throwError({
      error,
      logMessage: "OAuth signin failed",
      source: "signInWithOAuth",
    })
  }
}

export function signOut() {
  window.location.href = ROUTES.api.account.signOut
}

/**
 * This is just a convenience wrapper around useSession to change the type.
 */
export function useAuth(
  options: UseSessionOptions<boolean> = { required: true },
): AuthData {
  const { data } = useSession(options)

  return { ...data } as any
}
