import { createContext } from "react"

import { ServiceInfoFromLocalStorage } from "./types"

interface UsercentricsContextType {
  isFailed: boolean
  isInitialized: boolean
  isOpen: boolean
  localStorageState: ServiceInfoFromLocalStorage[]
  ping: symbol
}

const initialState: UsercentricsContextType = {
  isFailed: false,
  isInitialized: false,
  isOpen: false,
  localStorageState: [],
  ping: Symbol(),
}

export const UsercentricsContext =
  createContext<UsercentricsContextType>(initialState)
