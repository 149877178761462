export const isProdBuild = () =>
  process.env.NODE_ENV === "production" && process.env.PRODUCTION_ENV !== "test"

export const isDevBuild = () => process.env.NODE_ENV === "development"

export const isProdEnv = () => process.env.ENVIRONMENT === "prod"

export const isTestEnv = () => process.env.ENVIRONMENT === "test"

export const isDevEnv = () => process.env.ENVIRONMENT === "dev"

export const isPREnv = () => process.env.ENVIRONMENT === "pr"

export const isLocalEnv = () => process.env.ENVIRONMENT === "local"

export const isBrowser = () => typeof window !== "undefined"
