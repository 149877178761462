import { SessionProvider } from "next-auth/react"
import { FC, PropsWithChildren } from "react"

import { NotificationModal } from "src/components/core/notificationModal"
import { NotificationToasts } from "src/components/core/notificationToasts"
import { AUTH_CONFIG, EnvironmentVariables, GlobalVariables } from "src/config"
import { UsercentricsProvider } from "src/helpers/usercentrics/components/UsercentricsProvider"
import { TranslationProvider } from "src/hooks/translation"
import { MessageProps } from "src/types/MessageProps"
import { SharedPageProps } from "src/types/SharedPageProps"

import { EnvironmentVariablesProvider } from "./EnvironmentVariablesProvider"
import { GlobalVariablesProvider } from "./GlobalVariablesProvider"

export type ProvidersProps = PropsWithChildren<{
  messages: MessageProps["messages"]
  auth: SharedPageProps["auth"]
  environmentVariables?: EnvironmentVariables
  globalVariables?: GlobalVariables
}>

export const Providers: FC<ProvidersProps> = ({
  children,
  messages,
  auth,
  environmentVariables,
  globalVariables,
}) => {
  return (
    <EnvironmentVariablesProvider variables={environmentVariables}>
      <GlobalVariablesProvider variables={globalVariables}>
        <TranslationProvider messages={messages}>
          <UsercentricsProvider>
            <SessionProvider
              session={auth}
              refetchInterval={AUTH_CONFIG.sessionRefreshIntervalSeconds}
              refetchOnWindowFocus
            >
              {children}
              <NotificationModal />
              <NotificationToasts />
            </SessionProvider>
          </UsercentricsProvider>
        </TranslationProvider>
      </GlobalVariablesProvider>
    </EnvironmentVariablesProvider>
  )
}
