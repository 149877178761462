import isArray from "lodash/isArray"
import { useRouter } from "next/router"
import { useEffect } from "react"

import { ROUTES } from "src/config"
import { regex } from "src/const/regex"
import { HttpRequestParams, performRequest } from "src/helpers/http/http"
import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"

import {
  mergeLeadTrackingData,
  setLeadTrackingData,
  useLeadTrackingData,
} from "src/states/leadTrackingData"

const trackOnpierLeadCount = (utmSource: string) => {
  const request: HttpRequestParams = {
    method: "GET",
    path: ROUTES.api.leads.tracking,
    query: {
      utmSource,
    },
  }
  return performRequest(request)
}

export const useLeadTracking = (shouldTrack?: boolean) => {
  const router = useRouter()
  const { utmSource, tracked } = useLeadTrackingData()
  const query = router.query["utm_source"]

  useEffect(() => {
    if (!tracked) {
      if (utmSource && shouldTrack) {
        trackOnpierLeadCount(utmSource)
        sendTrackingEvent.onpier({
          gaEvent: {
            action: "onpier_request_success",
            value: utmSource,
          },
        })
        mergeLeadTrackingData({ tracked: true })
      } else if (!!query) {
        const utmSourceQuery = isArray(query) ? query.join(",") : query
        const isValidOnpierTraffic =
          regex.onpierBooking.VALID_SOURCE.test(utmSourceQuery)

        if (isValidOnpierTraffic) {
          mergeLeadTrackingData({ utmSource: utmSourceQuery, tracked: false })
        } else {
          setLeadTrackingData({})
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracked])
}
