import { pushTrackingEventToDataLayer } from "src/helpers/tracking/gtm"
import { EventHandler } from "src/helpers/tracking/tracking-event-handlers/types"

export const checkoutSummary: EventHandler<undefined, "checkoutSummary"> = (
  { gaEvent },
  eventType = "checkoutSummary",
) => {
  return pushTrackingEventToDataLayer({
    eventType,
    payload: { gaEvent },
  })
}
