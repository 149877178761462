import isArray from "lodash/isArray"
import { NextRouter, useRouter } from "next/router"
import { useEffect } from "react"

import { regex } from "src/const/regex"
import settingsActions from "src/states/serviceFlow/settingsActions"
import utmActions from "src/states/utmProperties/utmActions"

const cleanAndReturnString = (item: string | string[] | undefined) => {
  return isArray(item)
    ? item
        .map((string) => string.replaceAll('"', "").replaceAll("'", ""))
        .join(",")
    : item?.replaceAll('"', "").replaceAll("'", "")
}

const addHukSalesmanData = (
  query: NextRouter["query"],
  asPath: NextRouter["asPath"],
) => {
  const validCode = asPath.match(regex.salesmanReferralCode.VALID_CODE)

  if (validCode && validCode[0]) {
    const hukSalesmanIdentificationNumber = validCode[0].slice(-7)

    settingsActions.addHukSalesmanData({
      hukSalesManIdentifier: hukSalesmanIdentificationNumber,
      source: "huk.de",
      campaign: query?.utm_campaign?.toString(),
    })
  }
}

export const useCheckUtmValues = () => {
  const router = useRouter()

  useEffect(() => {
    const { utm_source, utm_campaign, utm_medium } = router.query
    const utmCampaign = cleanAndReturnString(utm_campaign)
    const utmMedium = cleanAndReturnString(utm_medium)
    const utmSource = cleanAndReturnString(utm_source)

    if (utmCampaign || utmMedium || utmSource) utmActions.reset()

    if (utmSource) utmActions.setUtmSource(utmSource)
    if (utmCampaign) utmActions.setUtmCampaign(utmCampaign)
    if (utmMedium) utmActions.setUtmMedium(utmMedium)

    if (utmSource === "huk.de") {
      addHukSalesmanData(router.query, router.asPath)
    }
  }, [router.asPath, router.query])
}
