import { isPWSGarage } from "../isPWSGarage"
import { Category, Service } from "src/helpers/autoservice-core-backend/types"
import { sumServiceCategoriesPricing } from "src/helpers/sumServiceCategoriesPricing"
import { MetaEventData } from "src/helpers/tracking/tracking-event-handlers/types"
import {
  DetailedGarage,
  DmsProviderType,
} from "src/states/serviceFlow/garageActions"
import { ServiceCategory } from "src/states/serviceFlow/types"
import { isValue } from "src/utils/typeGuards"

import {
  Ga4ECommerceEvent,
  Ga4ECommerceItem,
  Ga4ECommercePurchaseEvent,
  GTMEvents,
  MetaEvents,
} from "./gtm"

export const extractInternalServiceCategoryIds = (
  serviceCategories: ServiceCategory[] | undefined,
) => {
  if (!isValue(serviceCategories) || serviceCategories.length === 0) return []
  return serviceCategories
    .filter((c) => !!c)
    .reduce((serviceCategoryIds: string[], category) => {
      const internalServiceCategoryId = category.internalServiceCategoryId
      if (isValue(internalServiceCategoryId))
        return serviceCategoryIds.concat(internalServiceCategoryId)
      return serviceCategoryIds
    }, [])
}

export const parseServiceCategoriesToMetaContents = (
  serviceCategories: ServiceCategory[] | undefined,
): MetaEvents["contents"] => {
  if (!isValue(serviceCategories) || serviceCategories.length === 0) return []

  return serviceCategories
    .filter((c) => !!c)
    .reduce((contents: MetaEvents["contents"], category) => {
      const internalServiceId = category.service?.internalServiceId
      const service = isValue(internalServiceId)
        ? [
            {
              id: internalServiceId,
              quantity: 1,
            },
          ]
        : []
      const items = category?.additionalServices.reduce(
        (contents: MetaEvents["contents"], additionalService) => {
          const internalAdditionalServiceId =
            additionalService?.internalAdditionalServiceId
          if (isValue(internalAdditionalServiceId))
            return contents.concat({
              id: internalAdditionalServiceId,
              quantity: 1,
            })
          return contents
        },
        service,
      )
      return contents.concat(...items)
    }, [])
}

export const parseServiceCategoriesToECommerceItem = (
  serviceCategories: ServiceCategory[] | undefined,
  detailedGarage: DetailedGarage | undefined,
): Ga4ECommerceItem[] => {
  if (!isValue(serviceCategories) || serviceCategories.length === 0) return []

  return serviceCategories
    .filter((c) => !!c)
    .reduce((contents: Ga4ECommerceItem[], category) => {
      const serviceId = category.service?.internalServiceId ?? ""
      const isPWS = isPWSGarage(detailedGarage?.garage.dmsProviderType)

      const garageService = detailedGarage?.services?.find(
        ({ internalServiceId }) => internalServiceId === serviceId,
      )

      const servicePrice = !isPWS
        ? (garageService?.priceAfterDiscount ?? category.service?.price ?? 0)
        : 0

      const service = isValue(category)
        ? [
            {
              item_id: category.service?.internalServiceId,
              item_name: category.service?.title,
              affiliation: detailedGarage?.garage.dmsProviderType,
              currency: "EUR" as const,
              price: servicePrice,
              quantity: 1,
            },
          ]
        : []
      const items = category.additionalServices.reduce(
        (contents: Ga4ECommerceItem[], additionalService) => {
          if (isValue(additionalService)) {
            const internalAdditionalServiceId =
              additionalService.internalAdditionalServiceId ?? ""

            const additionalGarageService =
              garageService?.additionalServices.find(
                ({ additionalServiceId }) =>
                  additionalServiceId === internalAdditionalServiceId,
              )

            const additionServicePrice = !isPWS
              ? (additionalGarageService?.priceAfterDiscount ??
                additionalService.price ??
                0)
              : 0
            return contents.concat({
              item_id: additionalService.internalAdditionalServiceId,
              item_name: additionalService.title,
              affiliation: detailedGarage?.garage.dmsProviderType,
              currency: "EUR" as const,
              price: additionServicePrice,
              quantity: 1,
            })
          }
          return contents
        },
        service,
      )
      return contents.concat(...items)
    }, [])
}

export const buildGa4EcommerceEvent = (
  value: number,
  items: Ga4ECommerceItem[],
  garage?: string,
): Ga4ECommerceEvent => ({
  currency: "EUR",
  value,
  items,
  garage,
})

export const buildGa4EcommercePurchaseEvent = (
  affiliation: DmsProviderType,
  transaction_id: string,
  value: number,
  originalTotalPrice: number,
  items: Ga4ECommerceItem[],
): Ga4ECommercePurchaseEvent => ({
  ...buildGa4EcommerceEvent(value, items),
  affiliation,
  transaction_id,
  originalTotalPrice,
})

const buildGa4EcommerceItem = (
  item_id?: string,
  item_name?: string,
  price?: number,
  affiliation?: DmsProviderType,
): Ga4ECommerceItem => ({
  item_id,
  item_name,
  affiliation,
  price,
  quantity: 1,
  currency: "EUR",
})

export const gaEventEcommerceCategory = (
  category: Category,
): Ga4ECommerceEvent => {
  return buildGa4EcommerceEvent(category.price ?? 0, [
    buildGa4EcommerceItem(
      category.internalServiceCategoryId?.toString(),
      category.title,
      category.price ?? 0,
    ),
  ])
}

export const gaEventEcommerceService = (
  service: Service,
): Ga4ECommerceEvent => {
  return buildGa4EcommerceEvent(service.price ?? 0, [
    buildGa4EcommerceItem(
      service.internalServiceId?.toString(),
      service.title,
      service.price ?? 0,
    ),
  ])
}

export type PreselectService = {
  title?: string
  internalId?: string
  price?: number
}

export const gaEventEcommercePreselectService = (
  preselectService: PreselectService,
): Ga4ECommerceEvent => {
  return buildGa4EcommerceEvent(preselectService.price ?? 0, [
    buildGa4EcommerceItem(
      preselectService.internalId,
      preselectService.title,
      preselectService.price ?? 0,
    ),
  ])
}

export const buildMetaEvent = <
  Event extends keyof GTMEvents,
  Data extends MetaEventData,
>(
  event: Event,
  data: Data | undefined,
) => {
  if (!isValue(data)) return

  return {
    content_name: event,
    content_ids: extractInternalServiceCategoryIds(data.serviceCategories),
    currency: "EUR" as const,
    contents: parseServiceCategoriesToMetaContents(data.serviceCategories),
    value: sumServiceCategoriesPricing(
      data.serviceCategories,
      data.detailedGarage?.services,
    ),
  }
}

export const buildMetaPurchaseEvent = <
  Event extends keyof GTMEvents,
  Data extends MetaEventData,
>(
  event: Event,
  data: Data,
) => {
  const metaEvent = buildMetaEvent(event, data)
  if (!metaEvent) {
    return
  }

  return {
    ...metaEvent,
    originalTotalPrice: metaEvent.value,
    value: data.priceAfterDiscount ?? metaEvent.value,
    num_items: parseServiceCategoriesToMetaContents(
      data?.serviceCategories,
    ).reduce((itemsCount, item) => (itemsCount += item.quantity), 0),
  }
}
