import accountPlaceholder from "src/assets/account/placeholder.png"
import appIcon144 from "src/assets/meta/appicon-144x144.png"
import appIcon192 from "src/assets/meta/appicon-192x192.png"
import appIcon512 from "src/assets/meta/appicon-512x512.png"
import appleTouchIcon120 from "src/assets/meta/apple-touch-120x120.png"
import appleTouchIcon152 from "src/assets/meta/apple-touch-152x152.png"
import appleTouchIcon167 from "src/assets/meta/apple-touch-167x167.png"
import appleTouchIcon180 from "src/assets/meta/apple-touch-180x180.png"
import favIcon16 from "src/assets/meta/favicon-16x16.png"
import favIcon192 from "src/assets/meta/favicon-192x192.png"
import favIcon32 from "src/assets/meta/favicon-32x32.png"
import favIco from "src/assets/meta/favicon.ico"

export const APP_ICONS = {
  app: {
    "144x144": appIcon144,
    "192x192": appIcon192,
    "512x512": appIcon512,
  },
  appleTouch: {
    // For iPhone, iPad, iPad Pro and iPad mini:
    // https://developer.apple.com/design/human-interface-guidelines/ios/icons-and-images/app-icon/
    "120x120": appleTouchIcon120,
    "152x152": appleTouchIcon152,
    "167x167": appleTouchIcon167,
    "180x180": appleTouchIcon180,
  },
  favIco,
  favIcons: {
    "16x16": favIcon16,
    "32x32": favIcon32,
    "192x192": favIcon192,
  },
  account: {
    placeholder: accountPlaceholder,
  },
} as const
