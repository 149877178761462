import { AppProps as NextAppProps } from "next/app"
import { FC } from "react"

import { Fonts } from "src/components/core/fonts"
import { NewsLetterModal } from "src/components/core/newsLetter"
import { PreviewWatermark } from "src/components/core/preview-watermark"
import { Providers } from "src/components/core/providers"
import { ThirdPartyScripts } from "src/components/core/scripts"
import { ContentPageProps } from "src/components/pages/ContentPage"
import { UsercentricsModal } from "src/helpers/usercentrics/components/UsercentricsModal"
import { useCheckUserSession } from "src/hooks/useCheckUserSession"
import { useCheckUtmValues } from "src/hooks/useCheckUtmValues"
import { useLeadTracking } from "src/hooks/useLeadTracking"
import { useTheme } from "src/hooks/useTheme"
import { MessageProps } from "src/types/MessageProps"
import { SharedPageProps } from "src/types/SharedPageProps"
import "what-input"
import "./_app.scss"

type AppProps = NextAppProps<
  SharedPageProps & ContentPageProps & MessageProps
> & {
  /** Workaround for https://github.com/vercel/next.js/issues/8592 */
  err: any
}

const App: FC<AppProps> = ({ Component: Page, pageProps, err }) => {
  const { preview, messages, auth, environmentVariables, globalVariables } =
    pageProps

  useTheme()
  useCheckUserSession()
  useLeadTracking()
  useCheckUtmValues()

  return (
    <Providers
      messages={messages}
      auth={auth}
      environmentVariables={environmentVariables}
      globalVariables={globalVariables}
    >
      <Fonts />

      {preview && <PreviewWatermark />}

      <Page {...pageProps} err={err} />

      <ThirdPartyScripts />

      <UsercentricsModal />
      <NewsLetterModal />
    </Providers>
  )
}

export default App
