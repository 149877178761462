import { useRouter } from "next/router"

import { useEffect } from "react"

import { useIsInitialized } from "../hooks/useIsInitialized"
import { showSecondLayer } from "../utils"

export const UsercentricsModal = () => {
  const router = useRouter()
  const openModal = !!router.query?.openUsercentrics

  const isUsercentricsInitialized = useIsInitialized()

  useEffect(() => {
    if (openModal && isUsercentricsInitialized) {
      showSecondLayer()
      router.replace(
        {
          // To fix the slug in the URL
          pathname: router.asPath.split("?")[0],
          query: undefined,
        },
        undefined,
        { shallow: true },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, isUsercentricsInitialized])

  return null
}
