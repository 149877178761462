import { Close, Content, Description, Portal } from "@radix-ui/react-dialog"
import { FC, useEffect, ReactNode, PropsWithChildren } from "react"

import { Button } from "../button"
import { Info } from "../info"
import { Icon, IconName } from "src/components/common/icon"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"
import { getNotificationToasts } from "src/states/notificationToasts"

import styles from "./ModalContent.module.scss"
import { ModalOverlay } from "./ModalOverlay"

const bem = create(styles, "ModalContent")

export type ModalContentVariant = "sm" | "md" | "lg" | "menu" | "central"

export type ExpandableContentProps = {
  title?: string
  content: ReactNode
  contentClassName?: string
  onClose?: () => void
}

export type ModalContentProps = PropsWithChildren<{
  title?: string
  titleIcon?: IconName
  contentClassName?: string
  bodyClassName?: string
  variant?: ModalContentVariant
  noPadding?: boolean
  noHeader?: boolean
  hideCloseButton?: boolean
  footer?: ReactNode
  highlightedHeader?: boolean
  expandableProps?: ExpandableContentProps
  goBack?: () => void
  onClose?: () => void
}>

export const ModalContent: FC<ModalContentProps> = ({
  title,
  titleIcon,
  bodyClassName,
  contentClassName,
  variant = "md",
  children,
  noHeader,
  noPadding = false,
  footer,
  highlightedHeader = false,
  expandableProps,
  goBack,
  onClose,
  hideCloseButton,
  ...props
}) => {
  const { messages } = useTranslation()
  const translations = messages.components.common.modal

  const modifiers = {
    "no-padding": noPadding,
    "highlighted-header": highlightedHeader,
    [variant]: true,
  }

  function resetBodyPointerEvents() {
    document.body.style.pointerEvents = "auto"
  }

  useEffect(() => {
    /**
     * We need this to fix pointer-events: "none" styles on body
     * during component mount/unmount.
     * Issue: https://github.com/radix-ui/primitives/issues/1241.
     */
    resetBodyPointerEvents()

    return () => {
      resetBodyPointerEvents()
    }
  }, [])

  const handleOutsideClick = (
    event: CustomEvent<{
      originalEvent: PointerEvent
    }>,
  ) => {
    const notifications = getNotificationToasts()

    // If there are available notifications, the modal won't close when clicking outside/elsewhere
    if (notifications.length) {
      event.preventDefault()
    }
  }

  const renderIconOrBack = () => {
    if (titleIcon === "info") {
      return <Info variant="primary" />
    } else if (titleIcon) {
      return <Icon className={bem("title-icon")} name={titleIcon} />
    }

    if (goBack) {
      return (
        <Button variant="tertiary" onClick={goBack}>
          <Icon className={bem("back")} name="navArrowLeft" />
        </Button>
      )
    }

    return <div /> // This div is needed because of the grid CSS
  }

  return (
    <Portal>
      <ModalOverlay variant={variant}>
        <Content
          className={bem(
            undefined,
            { [variant]: true, expanded: !!expandableProps },
            contentClassName,
          )}
          onPointerDownOutside={handleOutsideClick}
          {...props}
        >
          <div className={bem("container")}>
            <div
              className={bem("modal", {
                [variant]: true,
              })}
            >
              {!noHeader && (
                <div className={bem("header", modifiers)}>
                  {renderIconOrBack()}
                  <div className={bem("title", modifiers)}>{title}</div>
                  {!hideCloseButton && (
                    <Close
                      className={bem("close")}
                      aria-label={translations.buttons.close.label}
                      data-cy="modal-close-button"
                      onClick={onClose}
                    >
                      <Icon className={bem("icon")} name="cancel" />
                    </Close>
                  )}
                </div>
              )}

              <Description className={bem("body", modifiers)} asChild>
                <div className={bodyClassName}>{children}</div>
              </Description>
              {!!footer && footer}
            </div>

            {!!expandableProps && (
              <div className={bem("expandable")}>
                <div className={bem("header", { expandable: true })}>
                  <div className={bem("title")}>{expandableProps.title}</div>
                  <button
                    className={bem("close")}
                    onClick={expandableProps.onClose}
                  >
                    <Icon className={bem("icon")} name="cancel" />
                  </button>
                </div>

                <div className={bem("expandable-content")}>
                  {expandableProps.content}
                </div>
              </div>
            )}
          </div>
        </Content>
      </ModalOverlay>
    </Portal>
  )
}
