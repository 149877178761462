import { StorageOptions } from "../syncToPersistentStorage"
import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

import { OfferNotification } from "./types"

const localStorage: StorageOptions = {
  key: "offer-notification",
  version: "2023-12-11",
  storage: "localStorage",
}

const { getValue, setValue, useValue, mergeValue } =
  createSimpleGlobalState<OfferNotification>({}, localStorage)

export {
  getValue as getOfferNotification,
  setValue as setOfferNotification,
  useValue as useOfferNotification,
  mergeValue as mergeOfferNotification,
}
