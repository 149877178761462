import { nanoid } from "nanoid"
import { stringify } from "query-string"

import { HttpError } from "../http/HttpError"
import { Sentry } from "../sentry"

// What is this?
// https://blog.sentry.io/2021/08/12/distributed-tracing-101-for-full-stack-developers
const spanId = nanoid()

export async function extractErrorMessage(response: Response) {
  // If this is a HUK Autoservice API request we can try to extract error details
  // from the response body:
  try {
    // Clone the response so we can parse it as text afterwards if this fails.
    const data = await response.clone().json()
    if (data?.trace) {
      return data.trace
    }
    if (data?.message) {
      return data.message
    }
  } catch (error) {
    // Ignore this since we can't expect error responses to contain JSON.
  }

  const text = await response.text()

  return [response.status, text || response.statusText].join(" ")
}

export type HttpMethod = "GET" | "POST" | "PATCH" | "PUT" | "DELETE"

export type HttpQuery = Record<string, string | number | boolean | string[]>

export interface HttpRequestParams {
  method: HttpMethod
  baseUrl?: string
  path: string
  query?: HttpQuery
  headers?: HeadersInit
  body?: Record<string, any>
}

export async function performRequest<T = unknown>({
  baseUrl,
  method,
  path,
  query,
  headers,
  body,
}: HttpRequestParams): Promise<T> {
  const traceId = nanoid()

  const scope = Sentry.getCurrentScope()
  scope.setTag("trace_id", traceId)
  scope.setTag("span_id", spanId)

  const queryStr = query ? "?" + stringify(query) : undefined
  const url = [baseUrl, path, queryStr].filter(Boolean).join("")
  const bodyStr = body ? JSON.stringify(body) : undefined

  const options: RequestInit = {
    method,
    body: bodyStr,
    headers: {
      "X-Trace-ID": traceId,
      "X-Parent-ID": spanId,
      "Content-Type": "application/json",
      ...headers,
    },
  }

  let response: Response

  try {
    response = await fetch(url, options)
  } catch (error) {
    throw new HttpError(error.message, "OTHER")
  }

  if (response?.status < 0) {
    throw new HttpError(response.statusText, "REQUEST_TIMEOUT")
  }

  if (!response?.ok) {
    const message = await extractErrorMessage(response)
    throw new HttpError(message, response.status)
  }

  if (response?.status === 204) {
    return undefined as any as T
  }

  try {
    return await response.json()
  } catch (error) {
    throw new HttpError("Unable to parse response", "PARSE_RESPONSE")
  }
}
