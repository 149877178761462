import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"

export const useNewsLetterTrackingEvents = () => {
  function trackOpenNewsLetter() {
    sendTrackingEvent.newsLetter({
      gaEvent: {
        action: "open_news_letter",
      },
    })
  }

  function trackCloseNewsLetter() {
    sendTrackingEvent.newsLetter({
      gaEvent: {
        action: "close_news_letter",
      },
    })
  }
  function trackOpenCloseNewsLetter(open?: boolean) {
    open ? trackOpenNewsLetter() : trackCloseNewsLetter()
  }

  return {
    trackOpenCloseNewsLetter,
  }
}
