import { FC } from "react"

import { create } from "src/helpers/bem"
import {
  useNotificationToasts,
  removeNotificationToast,
} from "src/states/notificationToasts"

import { NotificationToast } from "./NotificationToast"
import styles from "./NotificationToasts.module.scss"

const bem = create(styles, "NotificationToasts")

export const NotificationToasts: FC = () => {
  const notifications = useNotificationToasts()

  if (notifications.length === 0) {
    return null
  }

  return (
    <div className={bem()}>
      {notifications.map((notification) => {
        return (
          <NotificationToast
            key={notification.id}
            {...notification.data}
            id={notification.id}
            onClose={removeNotificationToast}
          />
        )
      })}
    </div>
  )
}
