import { FC } from "react"

import { Icon, IconName } from "../icon"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"

import styles from "./Info.module.scss"

const bem = create(styles, "Info")

export type InfoProps = {
  variant: "primary" | "secondary"
  className?: string
}

export const Info: FC<InfoProps> = ({ variant, className, ...props }) => {
  const { messages } = useTranslation()
  const translations = messages.components.common.info
  const iconName: IconName = variant === "primary" ? "infoFilled" : "info"

  return (
    <div
      aria-label={translations.button.label}
      className={bem(undefined, undefined, className)}
      {...props}
    >
      <Icon name={iconName} className={bem("icon")} />
    </div>
  )
}
