import { pushTrackingEventToDataLayer } from "src/helpers/tracking/gtm"
import { EventHandler } from "src/helpers/tracking/tracking-event-handlers/types"

export const contact: EventHandler<undefined, "contact"> = (
  { gaEvent },
  eventType = "contact",
) => {
  return pushTrackingEventToDataLayer({
    eventType,
    payload: { gaEvent },
  })
}
