import TagManager from "react-gtm-module"

import {
  GaEventActions,
  GaEvents,
} from "src/helpers/tracking/gaEventCategoriesAndActions"
import { DmsProviderType } from "src/states/serviceFlow/garageActions"

export type MetaEvents = {
  content_category: keyof GTMEvents
  content_name: keyof GTMEvents
  content_ids: string[]
  currency: "EUR"
  contents: { id: string; quantity: number }[]
  value: number
  workshop_name: string | undefined
  workshop_type: string | undefined
  login_type: "login" | "guest"
  authentication_type:
    | "signup_guest"
    | "sign_in"
    | "signup_update_data"
    | "signup_create_account"
  status: boolean
  day_of_week: string
  time_of_day_start: string
  num_items: number
}
type PickMetaEvents<Keys extends keyof MetaEvents> = Pick<MetaEvents, Keys>
export type GaEvent<Event extends GaEvents> = {
  action?: GaEventActions<Event>
  label?: string
  value?: any
  ecommerce?: Ga4ECommerceEvent
}

export type Ga4ECommerceItem = {
  item_id?: string
  item_name?: string
  affiliation?: DmsProviderType
  currency: string
  price?: number
  quantity: number
}
export type Ga4ECommerceEvent = {
  currency: "EUR"
  value: number
  items: Ga4ECommerceItem[]
  garage?: string
}
export type Ga4ECommercePurchaseEvent = Ga4ECommerceEvent & {
  transaction_id: string
  affiliation: DmsProviderType
  originalTotalPrice: number
}

/*
  🚨 warning
  The key values in this type are use in the Google Tag Manager container
  Only change their value if you also change the variables in the GTM side
*/
export type GTMEvents = {
  home: {
    gaEvent?: GaEvent<"home">
  }
  services: {
    meta?: PickMetaEvents<
      "content_name" | "content_ids" | "currency" | "contents" | "value"
    >
    gaEvent?: GaEvent<"services"> & { ecommerce: Ga4ECommerceEvent }
  }
  vehicle: {
    meta?: PickMetaEvents<
      "content_name" | "content_ids" | "currency" | "contents" | "value"
    >
    gaEvent?: GaEvent<"vehicle"> & { ecommerce: Ga4ECommerceEvent }
  }
  garages: {
    meta?: PickMetaEvents<
      | "content_category"
      | "content_ids"
      | "contents"
      | "currency"
      | "num_items"
      | "value"
      | "workshop_name"
      | "workshop_type"
    >
    gaEvent?: GaEvent<"garages"> & { ecommerce: Ga4ECommerceEvent }
  }
  address: {
    gaEvent?: GaEvent<"address">
  }
  appointment: {
    meta?: PickMetaEvents<
      | "content_category"
      | "content_ids"
      | "contents"
      | "currency"
      | "num_items"
      | "value"
      | "day_of_week"
      | "time_of_day_start"
    >
    gaEvent?: GaEvent<"appointment"> & { ecommerce: Ga4ECommerceEvent }
  }
  checkout: {
    meta?: PickMetaEvents<
      "content_name" | "content_ids" | "currency" | "contents" | "value"
    >
    gaEvent?: GaEvent<"checkout"> & { ecommerce: Ga4ECommerceEvent }
  }
  pwsCheckout: {
    meta?: PickMetaEvents<
      "content_name" | "content_ids" | "currency" | "contents" | "value"
    >
    gaEvent?: GaEvent<"pwsCheckout"> & { ecommerce: Ga4ECommerceEvent }
  }
  checkin: {
    gaEvent?: GaEvent<"checkin">
  }
  authentication: {
    meta?: PickMetaEvents<"content_name" | "authentication_type" | "status">
    gaEvent?: GaEvent<"authentication">
  }
  payment: {
    meta?: PickMetaEvents<
      "content_category" | "content_ids" | "currency" | "contents" | "value"
    >
    gaEvent?: GaEvent<"payment">
  }
  completed: {
    meta?: PickMetaEvents<
      | "content_name"
      | "content_ids"
      | "currency"
      | "contents"
      | "value"
      | "num_items"
    >
    gaEvent: GaEvent<"completed"> & { ecommerce: Ga4ECommercePurchaseEvent }
  }
  pwsCompleted: {
    meta: PickMetaEvents<
      | "content_name"
      | "content_ids"
      | "currency"
      | "contents"
      | "value"
      | "num_items"
    >
    gaEvent: GaEvent<"completed"> & { ecommerce: Ga4ECommercePurchaseEvent }
  }
  account: {
    gaEvent?: GaEvent<"account">
  }
  landing: {
    gaEvent?: GaEvent<"landing">
  }
  navigationBar: {
    gaEvent?: GaEvent<"navigationBar">
  }
  support: {
    gaEvent?: GaEvent<"support">
  }
  contact: {
    gaEvent?: GaEvent<"contact">
  }
  serviceWizard: {
    gaEvent?: GaEvent<"serviceWizard">
  }
  shoppingCart: {
    gaEvent?: GaEvent<"shoppingCart">
  }
  newsLetter: {
    gaEvent?: GaEvent<"newsLetter">
  }
  checkoutSummary: {
    gaEvent?: GaEvent<"checkoutSummary">
  }
  footer: {
    gaEvent?: GaEvent<"footer">
  }
  customerCard: {
    gaEvent?: GaEvent<"customerCard">
  }
  ratgeber: {
    gaEvent?: GaEvent<"ratgeber">
  }
  inspection: {
    gaEvent?: GaEvent<"inspection">
  }
  referFriends: {
    gaEvent?: GaEvent<"referFriends">
  }
  onpier: {
    gaEvent?: GaEvent<"onpier">
  }
  werkstatt: {
    gaEvent?: GaEvent<"werkstatt">
  }
}

type PushTrackingEventToDataLayer = <T extends keyof GTMEvents>({
  eventType,
  payload,
}: {
  eventType: T
  payload?: GTMEvents[T]
}) => void

export const pushTrackingEventToDataLayer: PushTrackingEventToDataLayer = ({
  eventType,
  payload,
}) => {
  TagManager.dataLayer({
    dataLayer: {
      event: `huk.${eventType}`,
      huk: {
        [eventType]: payload,
      },
    },
  })
}
