import { messages as defaultMessages } from "./messages/de"

export const LOCALE = "de"
// https://github.com/prettier/prettier/issues/14985
// prettier-ignore
export type Locale = typeof LOCALE

export type TranslationMessages = typeof defaultMessages

type TranslationModule = {
  messages: TranslationMessages
}

export async function fetchTranslationMessages(): Promise<TranslationMessages> {
  const { messages } = (await import(`./messages/de`)) as TranslationModule
  return messages
}
