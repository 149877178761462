import { getSession } from "next-auth/react"
import { useEffect } from "react"

import { signOut } from "src/helpers/next-auth/client"
import { isSessionValid } from "src/helpers/next-auth/server/isSessionValid"

export const useCheckUserSession = () => {
  useEffect(() => {
    getSession().then((session) => {
      if (session && !isSessionValid(session)) {
        signOut()
      }
    })
  }, [])
}
