export const regex = {
  vin: {
    VALID_VIN: /^[A-HJ-PR-Z\d]{9,14}\d{3}$/,
    // We need to diferentiate these two regexes because of how Input masking works.
    INPUT_VIN: /[^A-HJ-PR-Z\d]/,
  },
  salesmanReferralCode: {
    VALID_CODE: /700\d{6}[A-Z]/,
  },
  hsnTsn: {
    lettersAndNumbers: /^[A-Za-z0-9]*$/,
    numbers: /^[0-9]+$/,
  },
  licensePlate: {
    FIRST_PART: /[^a-zA-ZäöüÄÖÜß¨]/gi,
    SECOND_PART: /[^a-zA-Z]/gi,
    THIRD_PART: /[^0-9]/gi,
    INTERNATIONAL: /[^a-zA-ZäöüÄÖÜß¨0-9]/gi,
  },
  customerData: {
    HOUSE_NUMBER: /^[^A-Za-z].*$/,
    STREET: /^[^0-9].*$/,
    CITY: /^[a-zA-ZäöüôéèÁàÁà\u00C0-\u017F][^0-9!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]*/,
    GERMAN_POSTAL_CODE: /^\d{5}$/,
    OTHERS_POSTAL_CODE: /^(?!.* {2})(?=.*[a-zA-Z0-9]).{1,10}$/,
    GERMAN_CITY: /^(?!.* {2})[.A-ZöäüÖÄÜß\-\(\)\, ]{1,30}$/i,
    OTHERS_CITY:
      /^(?!.* {2})(?!.*-{2})(?!.*\.{2})([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð&*\(\)_+=\[\]|{}:;',\-\. ]{2,30}|\w)$/,
    GERMAN_HOUSE_NUMBER:
      /^([0-9]{1,4}\s?[a-zA-Z]?)(\s?[-\/]\s?[0-9]{1,4}\s?[a-zA-Z]?)?$/,
    OTHERS_HOUSE_NUMBER: /^([\w\-\/]{2,9}|\w)$/,
    GERMAN_STREET_1:
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð](?!.* {2})(?=.{2,49}$)[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð() '-.0-9´`]+[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð() '-.](?<!\d\w)$/,
    GERMAN_STREET_2: /^[a-zA-Z][0-9]{1,2}$/,
    GERMAN_STREET_3: /^(straße|strasse|str\.) ?\d{1,3}$/i,
    OTHERS_STREET: /^(?!.* {2})(?=.*[a-zA-Z]).{2,50}$/,
    NAME: /^(?!.* {2})[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð .&'\\-]{2,50}$/,
    PHONE_NUMBER: /^[\d\s\+\-\(\)\/]+$/,
  },
  dateInputFormat: {
    VALID_DATE: /^(0[1-9]|1[0-2])\.\d{4}$/,
  },
  onpierBooking: {
    VALID_SOURCE: /^huk/i,
  },
}

export const pattern = {
  number: "[0-9]*",
}
