import { ExtensionFactory, StateValueAtRoot } from "@hookstate/core"

export const storagePrefix = "huk-autoservice-"

export type StorageOptions = {
  key: string
  version: string
  storage: "sessionStorage" | "localStorage"
}

type LocaleStoragePayload = {
  version: string
  data: any
}

export function syncToPersistentStorage<S, E>(
  options: StorageOptions,
): ExtensionFactory<S, E, {}> {
  const key = storagePrefix + options?.key

  const storePerSession = options?.storage === "sessionStorage"

  const read = () => {
    const persisted = storePerSession
      ? sessionStorage.getItem(key)
      : localStorage.getItem(key)

    if (persisted == null || persisted === undefined) {
      return null
    }
    const payload: LocaleStoragePayload = JSON.parse(persisted)
    return payload
  }

  const write = (data: StateValueAtRoot) => {
    const payload: LocaleStoragePayload = {
      version: options.version,
      data,
    }
    const value = JSON.stringify(payload)
    storePerSession
      ? sessionStorage.setItem(key, value)
      : localStorage.setItem(key, value)
  }

  const clear = () => {
    storePerSession
      ? sessionStorage.removeItem(key)
      : localStorage.removeItem(key)
  }

  return () => ({
    onInit: (state) => {
      // To make sure that local storage is available
      if (typeof window === "undefined" || !options) return

      const payload = read()
      if (payload?.version === options?.version) {
        state.set(payload.data)
      } else if (!state.promised && !state.error) {
        write(state.value)
      }
    },
    onSet: (state) => {
      // To make sure that local storage is available
      if (typeof window === "undefined") return

      if (state.promised || state.error !== undefined) {
        clear()
      } else {
        write(state.value)
      }
    },
  })
}
