import type { ServiceId, ServiceInfo } from "../types"

import { getServicesBaseInfo } from "../utils"

import { useServiceDebug } from "./useServiceDebug"

/**
 * Returns basic info for specific Usercentrics service, or null if not found.
 *
 * The typing is *not complete* and contains only the info used internally:
 *
 * - `id` of the service, autogenerated by Usercentrics
 * - `name` of the service, configured in the admin interface
 * - `consent.status` of the service
 *
 * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=getservicesbaseinfo
 */
export const useServiceInfo = (serviceId: ServiceId): ServiceInfo | null => {
  useServiceDebug(serviceId)
  return getServicesBaseInfo().find(({ id }) => serviceId === id) || null
}
