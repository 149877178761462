import * as Sentry from "@sentry/nextjs"
import { NextPageContext } from "next"

import { logError } from "../logging/logError"

const captureException = Sentry.captureException
const captureUnderscoreErrorException = Sentry.captureUnderscoreErrorException

const SentryWithLogging = {
  ...Sentry,
  captureException: (
    exception: Error,
    {
      amazonTraceId,
      tags,
      ...args
    }: {
      amazonTraceId?: string
      tags?: { [key: string]: string }
      [x: string]: unknown
    },
  ) => {
    captureException(exception, {
      extra: {
        ...args,
      },
      tags,
    })
  },

  captureUnderscoreErrorException: (context: NextPageContext) => {
    const amazonTraceId = context.req?.headers["x-amzn-trace-id"] as string

    logError({ exception: context.err, path: context.asPath, amazonTraceId })

    captureUnderscoreErrorException(context)
  },
}

// This is re-exported for convenience because the above imports are not suggested by TS.
export { SentryWithLogging as Sentry }
