import { isPitstopGarage } from "src/helpers/isPitstopGarage"
import { sumServiceCategoriesPricing } from "src/helpers/sumServiceCategoriesPricing"
import {
  buildGa4EcommercePurchaseEvent,
  buildMetaPurchaseEvent,
  parseServiceCategoriesToECommerceItem,
} from "src/helpers/tracking/eventParsersAndHandlers"
import { pushTrackingEventToDataLayer } from "src/helpers/tracking/gtm"
import {
  EventHandler,
  MetaEventData,
} from "src/helpers/tracking/tracking-event-handlers/types"
import { isValue } from "src/utils/typeGuards"

type CompletedEventData = Pick<
  MetaEventData,
  | "serviceCategories"
  | "detailedGarage"
  | "bookingId"
  | "flow"
  | "priceAfterDiscount"
>

export const completed: EventHandler<
  CompletedEventData,
  "completed" | "pwsCompleted"
> = ({ data, gaEvent }) => {
  if (!isValue(data) || !isValue(data.detailedGarage) || !isValue(gaEvent))
    return

  const isPitstop = isPitstopGarage(data.detailedGarage.garage.dmsProviderType)

  const eventType = isPitstop ? "completed" : "pwsCompleted"

  const meta = buildMetaPurchaseEvent(eventType, data)

  const builtGaEvent = Object.assign(gaEvent, {
    ecommerce: buildGa4EcommercePurchaseEvent(
      data.detailedGarage.garage.dmsProviderType || "NO_DMS_PROVIDER_TYPE",
      data.bookingId || "",
      data.priceAfterDiscount || 0,
      sumServiceCategoriesPricing(
        data.serviceCategories,
        data.detailedGarage?.services,
      ),
      parseServiceCategoriesToECommerceItem(
        data.serviceCategories,
        data.detailedGarage,
      ),
    ),
  })

  return pushTrackingEventToDataLayer({
    eventType,
    payload: { meta, gaEvent: builtGaEvent },
  })
}
