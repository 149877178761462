import NextjsLink, { LinkProps as NextjsLinkProps } from "next/link"
import { useRouter } from "next/router"
import { AnchorHTMLAttributes, forwardRef } from "react"

export type BaseLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> &
  NextjsLinkProps & {
    href: string
  }

const appUrlRegExp = /^\w+:/

/**
 * This is a generic unstyled link component that
 * - adds `target="_blank"` on external links
 * - adds `rel="noopener noreferrer"` on external links
 * - forces a plain link if the `download` attribute is present
 * - ensures anchors lead to the expected page
 */
export const BaseLink = forwardRef<HTMLAnchorElement, BaseLinkProps>(
  (
    {
      children,
      href,
      as,
      replace,
      scroll,
      shallow,
      passHref,
      prefetch,
      ...htmlLinkProps
    },
    ref,
  ) => {
    const nextjsLinkProps: Omit<NextjsLinkProps, "href"> = {
      as,
      replace,
      scroll,
      passHref,
      prefetch,
    }

    const isAbsolute = href.startsWith("http")

    // Next.js ignores the download attribute and opens the file instead of
    // downloading it. To force a download we need a regular HTML link.
    const isDownload = htmlLinkProps.download !== undefined

    // e.g. `mailto`, `tel`
    const isAppUrl = appUrlRegExp.test(href)

    // External links should be opened in a new tab and the target page should
    // not have access to the opener window.
    // https://web.dev/external-anchors-use-rel-noopener
    const target = htmlLinkProps.target || (isAbsolute ? "_blank" : undefined)
    const rel =
      htmlLinkProps.rel || (isAbsolute ? "noopener noreferrer" : undefined)

    const router = useRouter()

    // Support for query params.
    if (href.startsWith("?")) {
      // if queryParam is already available in the URL no need to add it again
      const isQueryParamAlreadyAvailable = router.asPath.includes(
        href.substring(1),
      )
      if (!isQueryParamAlreadyAvailable) {
        const hasQueryString = router.asPath.includes("?")
        href = router.asPath + (hasQueryString ? "&" : "?") + href.substring(1)
      }
      shallow = true
    }

    if (isAbsolute || isDownload || isAppUrl) {
      return (
        <a {...htmlLinkProps} ref={ref} href={href} target={target} rel={rel}>
          {children}
        </a>
      )
    }

    return (
      <NextjsLink
        {...nextjsLinkProps}
        {...htmlLinkProps}
        ref={ref}
        href={href}
        shallow={shallow}
      >
        {children}
      </NextjsLink>
    )
  },
)

BaseLink.displayName = "BaseLink"
