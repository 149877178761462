import { sumServiceCategoriesPricing } from "src/helpers/sumServiceCategoriesPricing"
import {
  buildGa4EcommerceEvent,
  extractInternalServiceCategoryIds,
  parseServiceCategoriesToECommerceItem,
  parseServiceCategoriesToMetaContents,
} from "src/helpers/tracking/eventParsersAndHandlers"
import {
  GTMEvents,
  pushTrackingEventToDataLayer,
} from "src/helpers/tracking/gtm"
import {
  EventHandler,
  MetaEventData,
} from "src/helpers/tracking/tracking-event-handlers/types"
import { isValue } from "src/utils/typeGuards"

export const appointment: EventHandler<MetaEventData, "appointment"> = (
  { data, gaEvent },
  eventType = "appointment",
) => {
  const contents = parseServiceCategoriesToMetaContents(data?.serviceCategories)
  const formattingOptions = { weekday: "long", hour12: false } as const
  const [appointmentDay, appointmentTimeStart] =
    isValue(data) && isValue(data.timeSlot)
      ? new Date(data.timeSlot)
          .toLocaleTimeString("en-US", formattingOptions)
          .split(" ")
      : []

  const meta: GTMEvents[typeof eventType]["meta"] =
    isValue(appointmentDay) && isValue(appointmentTimeStart) && isValue(data)
      ? {
          content_category: eventType,
          content_ids: extractInternalServiceCategoryIds(
            data.serviceCategories,
          ),
          contents: parseServiceCategoriesToMetaContents(
            data.serviceCategories,
          ),
          currency: "EUR",
          value: sumServiceCategoriesPricing(
            data.serviceCategories,
            data.detailedGarage?.services,
          ),
          num_items: contents.reduce(
            (itemsCount, item) => (itemsCount += item.quantity),
            0,
          ),
          day_of_week: appointmentDay,
          time_of_day_start: appointmentTimeStart,
        }
      : undefined

  const builtGaEvent =
    gaEvent &&
    Object.assign(gaEvent, {
      ecommerce:
        gaEvent?.ecommerce ??
        buildGa4EcommerceEvent(
          sumServiceCategoriesPricing(
            data?.serviceCategories,
            data?.detailedGarage?.services,
          ),
          parseServiceCategoriesToECommerceItem(
            data?.serviceCategories,
            data?.detailedGarage,
          ),
        ),
    })

  return pushTrackingEventToDataLayer({
    eventType,
    payload: { meta, gaEvent: builtGaEvent },
  })
}
