import { hookstate, useHookstate, none } from "@hookstate/core"

import { ButtonProps } from "src/components/common/button"

export type NotificationModalButton = {
  type?: "link" | "regular"
  fullWidth?: boolean
  href?: string
  label: string
  background?: boolean
  onClick?: () => void
} & ButtonProps

type NotificationModalSize = "sm" | "lg"

export type NotificationModalData = {
  title?: string
  content: string | string[]
  buttons?: NotificationModalButton[]
  size?: NotificationModalSize
}

type NotificationState = {
  modal?: NotificationModalData
}

const state = hookstate<NotificationState>({
  modal: undefined,
})

/**
 * Opens the notification modal
 * @param data the notification to be added
 */
export const openNotificationModal = (data: NotificationModalData) =>
  state.modal.set({ ...data })

/**
 * Closes the modal notification.
 */
export const closeNotificationModal = () => state.modal?.set(none)

/**
 * Returns the notification modal added to the state.
 * @returns the notification modal
 */
export const getNotificationModal = () => state.modal.get({ noproxy: true })

/**
 * React hook to receive notification modal from the state.
 * @returns the notification modal
 */
export const useNotificationModal = () =>
  useHookstate(state).modal.get({ noproxy: true })

/**
 * Only for testing purposes to receive the state. Do not use in production,
 * because it will throw an error.
 * @internal
 * @returns the state
 * @throws Error when used outside of a test environment
 */
export function getState() {
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== "test") {
    throw Error("Accessing state is only allowed for testing")
  }

  return state
}
