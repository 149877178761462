import { AriaAttributes, AriaRole, FC } from "react"

import { ReactComponent as brandAudi } from "src/assets/icons/brands/brandAudi.svg"
import { ReactComponent as brandBMW } from "src/assets/icons/brands/brandBMW.svg"
import { ReactComponent as brandFord } from "src/assets/icons/brands/brandFord.svg"
import { ReactComponent as brandMercedes } from "src/assets/icons/brands/brandMercedes.svg"
import { ReactComponent as brandOpel } from "src/assets/icons/brands/brandOpel.svg"
import { ReactComponent as brandSeat } from "src/assets/icons/brands/brandSeat.svg"
import { ReactComponent as brandSkoda } from "src/assets/icons/brands/brandSkoda.svg"
import { ReactComponent as brandVolkswagen } from "src/assets/icons/brands/brandVolkswagen.svg"
import { ReactComponent as bankcardSvg } from "src/assets/icons/common/bankcard.svg"
import { ReactComponent as calendarFilledSvg } from "src/assets/icons/common/calendar-filled.svg"
import { ReactComponent as calendarSvg } from "src/assets/icons/common/calendar.svg"
import { ReactComponent as cancelSvg } from "src/assets/icons/common/cancel.svg"
import { ReactComponent as carSvg } from "src/assets/icons/common/car.svg"
import { ReactComponent as cardSvg } from "src/assets/icons/common/card.svg"
import { ReactComponent as celebrationSvg } from "src/assets/icons/common/celebration.svg"
import { ReactComponent as checkCircledFilledSvg } from "src/assets/icons/common/check-circled-filled.svg"
import { ReactComponent as checkCircledSuccessSvg } from "src/assets/icons/common/check-circled-sucess.svg"
import { ReactComponent as checkCircledSvg } from "src/assets/icons/common/check-circled.svg"
import { ReactComponent as checklistSvg } from "src/assets/icons/common/checklist.svg"
import { ReactComponent as checkmarkSvg } from "src/assets/icons/common/checkmark.svg"
import { ReactComponent as clockSvg } from "src/assets/icons/common/clock.svg"
import { ReactComponent as connectionErrorSvg } from "src/assets/icons/common/connection-error.svg"
import { ReactComponent as creditCardSvg } from "src/assets/icons/common/credit-card.svg"
import { ReactComponent as deleteTextFillSvg } from "src/assets/icons/common/delete-text-fill.svg"
import { ReactComponent as distanceSvg } from "src/assets/icons/common/distance.svg"
import { ReactComponent as docSvg } from "src/assets/icons/common/doc.svg"
import { ReactComponent as downloadSvg } from "src/assets/icons/common/download.svg"
import { ReactComponent as dropdownMenuDownSvg } from "src/assets/icons/common/dropdown-menu-down.svg"
import { ReactComponent as dropdownMenuUpSvg } from "src/assets/icons/common/dropdown-menu-up.svg"
import { ReactComponent as editSvg } from "src/assets/icons/common/edit.svg"
import { ReactComponent as emptyShoppingCartSvg } from "src/assets/icons/common/empty-shopping-cart.svg"
import { ReactComponent as euFlagSvg } from "src/assets/icons/common/eu-flag.svg"
import { ReactComponent as externalLinkBlueSvg } from "src/assets/icons/common/external-link-blue.svg"
import { ReactComponent as externalLinkSvg } from "src/assets/icons/common/external-link.svg"
import { ReactComponent as eyeFilledSvg } from "src/assets/icons/common/eye-filled.svg"
import { ReactComponent as eyeSvg } from "src/assets/icons/common/eye.svg"
import { ReactComponent as faqSvg } from "src/assets/icons/common/faq.svg"
import { ReactComponent as hamburgerMenuSvg } from "src/assets/icons/common/hamburger-menu.svg"
import { ReactComponent as handCoinSvg } from "src/assets/icons/common/hand-coin.svg"
import { ReactComponent as handShakeSvg } from "src/assets/icons/common/hand-shake.svg"
import { ReactComponent as hidePasswordSvg } from "src/assets/icons/common/hide-password.svg"
import { ReactComponent as inclusiveSvg } from "src/assets/icons/common/inclusive.svg"
import { ReactComponent as infoDotsSvg } from "src/assets/icons/common/info-dots.svg"
import { ReactComponent as infoFilledSvg } from "src/assets/icons/common/info-filled.svg"
import { ReactComponent as infoSvg } from "src/assets/icons/common/info.svg"
import { ReactComponent as licensePlateDSvg } from "src/assets/icons/common/license-plate-d.svg"
import { ReactComponent as listSvg } from "src/assets/icons/common/list.svg"
import { ReactComponent as localSvg } from "src/assets/icons/common/local.svg"
import { ReactComponent as locationSvg } from "src/assets/icons/common/location.svg"
import { ReactComponent as lockSvg } from "src/assets/icons/common/lock.svg"
import { ReactComponent as magnifyingGlassSvg } from "src/assets/icons/common/magnifying-glass.svg"
import { ReactComponent as mailSvg } from "src/assets/icons/common/mail.svg"
import { ReactComponent as map1Svg } from "src/assets/icons/common/map-1.svg"
import { ReactComponent as mapSvg } from "src/assets/icons/common/map.svg"
import { ReactComponent as maskSvg } from "src/assets/icons/common/mask.svg"
import { ReactComponent as maximizeSvg } from "src/assets/icons/common/maximize.svg"
import { ReactComponent as menuPointSvg } from "src/assets/icons/common/menu-point.svg"
import { ReactComponent as minimizeSvg } from "src/assets/icons/common/minimize.svg"
import { ReactComponent as minusSvg } from "src/assets/icons/common/minus.svg"
import { ReactComponent as multipleWorkshopLocationSvg } from "src/assets/icons/common/multiple-workshop-location.svg"
import { ReactComponent as navArrowLeftSvg } from "src/assets/icons/common/nav-arrow-left.svg"
import { ReactComponent as navArrowRightSvg } from "src/assets/icons/common/nav-arrow-right.svg"
import { ReactComponent as navNextSvg } from "src/assets/icons/common/nav-next.svg"
import { ReactComponent as navPrevSvg } from "src/assets/icons/common/nav-prev.svg"
import { ReactComponent as noGarageSvg } from "src/assets/icons/common/noGarage.svg"
import { ReactComponent as pinSummarySvg } from "src/assets/icons/common/pin-summary.svg"
import { ReactComponent as pinWorkshopCardSvg } from "src/assets/icons/common/pin-workshop-card.svg"
import { ReactComponent as plusSvg } from "src/assets/icons/common/plus.svg"
import { ReactComponent as prizeComparisonSvg } from "src/assets/icons/common/prize-comparison.svg"
import { ReactComponent as questionMarkSvg } from "src/assets/icons/common/question-mark.svg"
import { ReactComponent as questionSvg } from "src/assets/icons/common/question.svg"
import { ReactComponent as repairSvg } from "src/assets/icons/common/repair.svg"
import { ReactComponent as routeStartSvg } from "src/assets/icons/common/route-start.svg"
import { ReactComponent as searchSvg } from "src/assets/icons/common/search.svg"
import { ReactComponent as selectionCheckmarkSvg } from "src/assets/icons/common/selection-checkmark.svg"
import { ReactComponent as shoppingCartSvg } from "src/assets/icons/common/shopping-cart.svg"
import { ReactComponent as showPasswordSvg } from "src/assets/icons/common/show-password.svg"
import { ReactComponent as starSvg } from "src/assets/icons/common/star.svg"
import { ReactComponent as stepperNextSvg } from "src/assets/icons/common/stepper-next.svg"
import { ReactComponent as successCircledFillSvg } from "src/assets/icons/common/success-circled-fill.svg"
import { ReactComponent as timerSvg } from "src/assets/icons/common/timer.svg"
import { ReactComponent as trashSvg } from "src/assets/icons/common/trash.svg"
import { ReactComponent as trustSvg } from "src/assets/icons/common/trust.svg"
import { ReactComponent as uncheckedCircledSvg } from "src/assets/icons/common/unchecked-circled.svg"
import { ReactComponent as userSvg } from "src/assets/icons/common/user.svg"
import { ReactComponent as warningCircledFillSvg } from "src/assets/icons/common/warning-circled-fill.svg"
import { ReactComponent as warningTriangle1Svg } from "src/assets/icons/common/warning-triangle-1.svg"
import { ReactComponent as warningTriangleSvg } from "src/assets/icons/common/warning-triangle.svg"
import { ReactComponent as workshopLocationActive1Svg } from "src/assets/icons/common/workshop-location-active-1.svg"
import { ReactComponent as workshopLocationActiveSvg } from "src/assets/icons/common/workshop-location-active.svg"
import { ReactComponent as workshopLocationInactiveSvg } from "src/assets/icons/common/workshop-location-inactive.svg"
import { ReactComponent as wrenchSvg } from "src/assets/icons/common/wrench.svg"
import { ReactComponent as abolaufzeitSvg } from "src/assets/icons/marketing/abolaufzeit.svg"
import { ReactComponent as auszeichnungUndQualität1Svg } from "src/assets/icons/marketing/auszeichnung-und-qualität-1.svg"
import { ReactComponent as auszeichnungUndQualitätSvg } from "src/assets/icons/marketing/auszeichnung-und-qualität.svg"
import { ReactComponent as autoserviceSvg } from "src/assets/icons/marketing/autoservice.svg"
import { ReactComponent as begrüßungSvg } from "src/assets/icons/marketing/begrüßung.svg"
import { ReactComponent as businessUndFirmaSvg } from "src/assets/icons/marketing/business-und-firma.svg"
import { ReactComponent as dokumentUndVertragSvg } from "src/assets/icons/marketing/dokument-und-vertrag.svg"
import { ReactComponent as elektroautoBatterienLadenSvg } from "src/assets/icons/marketing/elektroauto-batterien-laden.svg"
import { ReactComponent as fahrzeugaboSvg } from "src/assets/icons/marketing/fahrzeugabo.svg"
import { ReactComponent as fairerPreisSvg } from "src/assets/icons/marketing/fairer-preis.svg"
import { ReactComponent as filmUndVideoSvg } from "src/assets/icons/marketing/film-und-video.svg"
import { ReactComponent as foodSvg } from "src/assets/icons/marketing/food.svg"
import { ReactComponent as geschenkSvg } from "src/assets/icons/marketing/geschenk.svg"
import { ReactComponent as heartSvg } from "src/assets/icons/marketing/heart.svg"
import { ReactComponent as hilfeUndSupportSvg } from "src/assets/icons/marketing/hilfe-und-support.svg"
import { ReactComponent as hotlineSvg } from "src/assets/icons/marketing/hotline.svg"
import { ReactComponent as häckchenSvg } from "src/assets/icons/marketing/häckchen.svg"
import { ReactComponent as inklusiveSvg } from "src/assets/icons/marketing/inklusive.svg"
import { ReactComponent as jubiläumSvg } from "src/assets/icons/marketing/jubiläum.svg"
import { ReactComponent as karteUndNavigationSvg } from "src/assets/icons/marketing/karte-und-navigation.svg"
import { ReactComponent as kostenlosSvg } from "src/assets/icons/marketing/kostenlos.svg"
import { ReactComponent as kreativitätUndInnovationSvg } from "src/assets/icons/marketing/kreativität-und-innovation.svg"
import { ReactComponent as kundeSvg } from "src/assets/icons/marketing/kunde.svg"
import { ReactComponent as leidenschaftMobilitätSvg } from "src/assets/icons/marketing/leidenschaft-mobilität.svg"
import { ReactComponent as lenkradSvg } from "src/assets/icons/marketing/lenkrad.svg"
import { ReactComponent as neuwagenUndReinigungSvg } from "src/assets/icons/marketing/neuwagen-und-reinigung.svg"
import { ReactComponent as notesSvg } from "src/assets/icons/marketing/notes.svg"
import { ReactComponent as onlineBuchungSvg } from "src/assets/icons/marketing/online-buchung.svg"
import { ReactComponent as onlineTermin1Svg } from "src/assets/icons/marketing/online-termin-1.svg"
import { ReactComponent as onlineTerminSvg } from "src/assets/icons/marketing/online-termin.svg"
import { ReactComponent as preissieger1Svg } from "src/assets/icons/marketing/preissieger-1.svg"
import { ReactComponent as preissiegerSvg } from "src/assets/icons/marketing/preissieger.svg"
import { ReactComponent as rabattUndVorteilSvg } from "src/assets/icons/marketing/rabatt-und-vorteil.svg"
import { ReactComponent as radwechselSvg } from "src/assets/icons/marketing/radwechsel.svg"
import { ReactComponent as requestSvg } from "src/assets/icons/marketing/request.svg"
import { ReactComponent as searchAndReturnSvg } from "src/assets/icons/marketing/searchAndReturn.svg"
import { ReactComponent as serviceMarketingSvg } from "src/assets/icons/marketing/service.svg"
import { ReactComponent as standortSvg } from "src/assets/icons/marketing/standort.svg"
import { ReactComponent as startUndSchnelligkeitSvg } from "src/assets/icons/marketing/start-und-schnelligkeit.svg"
import { ReactComponent as sucheUndRücknahmeSvg } from "src/assets/icons/marketing/suche-und-rücknahme.svg"
import { ReactComponent as tankstand01Svg } from "src/assets/icons/marketing/tankstand-01.svg"
import { ReactComponent as tankstand02Svg } from "src/assets/icons/marketing/tankstand-02.svg"
import { ReactComponent as teamSvg } from "src/assets/icons/marketing/team.svg"
import { ReactComponent as thumbUp1Svg } from "src/assets/icons/marketing/thumb-up-1.svg"
import { ReactComponent as thumbUpSvg } from "src/assets/icons/marketing/thumb-up.svg"
import { ReactComponent as uhrzeitUndDauerSvg } from "src/assets/icons/marketing/uhrzeit-und-dauer.svg"
import { ReactComponent as updateUndBenachrichtigungSvg } from "src/assets/icons/marketing/update-und-benachrichtigung.svg"
import { ReactComponent as wichtigSvg } from "src/assets/icons/marketing/wichtig.svg"
import { ReactComponent as navigationCheckoutSvg } from "src/assets/icons/navigation/checkout.svg"
import { ReactComponent as navigationGarageSvg } from "src/assets/icons/navigation/garage.svg"
import { ReactComponent as navigationInquirySvg } from "src/assets/icons/navigation/inquiry.svg"
import { ReactComponent as navigationServicesSvg } from "src/assets/icons/navigation/services.svg"
import { ReactComponent as acDisinfectionSvg } from "src/assets/icons/services/ac-disinfection.svg"
import { ReactComponent as acInspectionSvg } from "src/assets/icons/services/ac-inspection.svg"
import { ReactComponent as acSvg } from "src/assets/icons/services/ac.svg"
import { ReactComponent as airPumpSvg } from "src/assets/icons/services/air-pump.svg"
import { ReactComponent as batterySvg } from "src/assets/icons/services/battery.svg"
import { ReactComponent as bonnetSvg } from "src/assets/icons/services/bonnet.svg"
import { ReactComponent as brakeDiscAndPadSvg } from "src/assets/icons/services/brake-disc-and-pad.svg"
import { ReactComponent as breakCheckSvg } from "src/assets/icons/services/break-check.svg"
import { ReactComponent as breakPadSvg } from "src/assets/icons/services/break-pad.svg"
import { ReactComponent as cabinFilterSvg } from "src/assets/icons/services/cabin-filter.svg"
import { ReactComponent as carTopViewSvg } from "src/assets/icons/services/car-top-view.svg"
import { ReactComponent as carWashSvg } from "src/assets/icons/services/car-wash.svg"
import { ReactComponent as catalyticConverterSvg } from "src/assets/icons/services/catalytic-converter.svg"
import { ReactComponent as coneSvg } from "src/assets/icons/services/cone.svg"
import { ReactComponent as coolantSvg } from "src/assets/icons/services/coolant.svg"
import { ReactComponent as crashSvg } from "src/assets/icons/services/crash.svg"
import { ReactComponent as crossWrenchSvg } from "src/assets/icons/services/cross-wrench.svg"
import { ReactComponent as discBrakeSvg } from "src/assets/icons/services/disc-brake.svg"
import { ReactComponent as doorglassSvg } from "src/assets/icons/services/doorglass.svg"
import { ReactComponent as exhaustPipeSvg } from "src/assets/icons/services/exhaust-pipe.svg"
import { ReactComponent as filterSvg } from "src/assets/icons/services/filter.svg"
import { ReactComponent as garageSvg } from "src/assets/icons/services/garage.svg"
import { ReactComponent as gasStationSvg } from "src/assets/icons/services/gas-station.svg"
import { ReactComponent as gearShiftSvg } from "src/assets/icons/services/gear-shift.svg"
import { ReactComponent as glassSvg } from "src/assets/icons/services/glass.svg"
import { ReactComponent as handbrakeSvg } from "src/assets/icons/services/handbrake.svg"
import { ReactComponent as inspektionSvg } from "src/assets/icons/services/inspektion.svg"
import { ReactComponent as jackSvg } from "src/assets/icons/services/jack.svg"
import { ReactComponent as keySvg } from "src/assets/icons/services/key.svg"
import { ReactComponent as keylessSvg } from "src/assets/icons/services/keyless.svg"
import { ReactComponent as liftSvg } from "src/assets/icons/services/lift.svg"
import { ReactComponent as machineSvg } from "src/assets/icons/services/machine.svg"
import { ReactComponent as mainInspectionTuvSvg } from "src/assets/icons/services/main-inspection-tuv.svg"
import { ReactComponent as maintenanceSvg } from "src/assets/icons/services/maintenance.svg"
import { ReactComponent as manualGearShiftSvg } from "src/assets/icons/services/manual-gear-shift.svg"
import { ReactComponent as nitrogenInflationSvg } from "src/assets/icons/services/nitrogen-inflation.svg"
import { ReactComponent as oilSvg } from "src/assets/icons/services/oil.svg"
import { ReactComponent as paintSpraySvg } from "src/assets/icons/services/paint-spray.svg"
import { ReactComponent as partsRecylingSvg } from "src/assets/icons/services/parts-recyling.svg"
import { ReactComponent as pedalsSvg } from "src/assets/icons/services/pedals.svg"
import { ReactComponent as pistonSvg } from "src/assets/icons/services/piston.svg"
import { ReactComponent as preCheckSvg } from "src/assets/icons/services/pre-check.svg"
import { ReactComponent as pulleySvg } from "src/assets/icons/services/pulley.svg"
import { ReactComponent as radiatorSvg } from "src/assets/icons/services/radiator.svg"
import { ReactComponent as rearViewSvg } from "src/assets/icons/services/rear-view.svg"
import { ReactComponent as rimSvg } from "src/assets/icons/services/rim.svg"
import { ReactComponent as safetyTriangleSvg } from "src/assets/icons/services/safety-triangle.svg"
import { ReactComponent as serviceSvg } from "src/assets/icons/services/service.svg"
import { ReactComponent as shockAbsorberSvg } from "src/assets/icons/services/shock-absorber.svg"
import { ReactComponent as sparkSvg } from "src/assets/icons/services/spark.svg"
import { ReactComponent as stabillitySvg } from "src/assets/icons/services/stabillity.svg"
import { ReactComponent as steeringSvg } from "src/assets/icons/services/steering.svg"
import { ReactComponent as suspensionSvg } from "src/assets/icons/services/suspension.svg"
import { ReactComponent as tankSvg } from "src/assets/icons/services/tank.svg"
import { ReactComponent as temperatureSvg } from "src/assets/icons/services/temperature.svg"
import { ReactComponent as timingBeltSvg } from "src/assets/icons/services/timing-belt.svg"
import { ReactComponent as turboSvg } from "src/assets/icons/services/turbo.svg"
import { ReactComponent as tyreSvg } from "src/assets/icons/services/tyre.svg"
import { ReactComponent as voltageSvg } from "src/assets/icons/services/voltage.svg"
import { ReactComponent as wheelAlignSvg } from "src/assets/icons/services/wheel-align.svg"
import { ReactComponent as wheelCompleteSvg } from "src/assets/icons/services/wheel-complete.svg"
import { ReactComponent as wheelLevelingSvg } from "src/assets/icons/services/wheel-leveling.svg"
import { ReactComponent as wheelPressureSvg } from "src/assets/icons/services/wheel-pressure.svg"
import { ReactComponent as wheelSpraySvg } from "src/assets/icons/services/wheel-spray.svg"
import { ReactComponent as wheelStorageSvg } from "src/assets/icons/services/wheel-storage.svg"
import { ReactComponent as wheelSvg } from "src/assets/icons/services/wheel.svg"
import { ReactComponent as whellAlignmentSvg } from "src/assets/icons/services/whell-alignment.svg"
import { ReactComponent as wiperSvg } from "src/assets/icons/services/wiper.svg"
import { ReactComponent as facebookSvg } from "src/assets/icons/social/facebook.svg"
import { ReactComponent as googleSvg } from "src/assets/icons/social/google.svg"
import { ReactComponent as instagramSvg } from "src/assets/icons/social/instagram.svg"
import { ReactComponent as linkedinSvg } from "src/assets/icons/social/linkedin.svg"
import { ReactComponent as newsletterSvg } from "src/assets/icons/social/newsletter.svg"
import { ReactComponent as onlineAppointmentSvg } from "src/assets/icons/social/online-appointment.svg"
import { ReactComponent as xingSvg } from "src/assets/icons/social/xing.svg"
import { ReactComponent as amexSvg } from "src/assets/logos/amex.svg"
import { ReactComponent as dinersSvg } from "src/assets/logos/diners.svg"
import { ReactComponent as discoverSvg } from "src/assets/logos/discover.svg"
import { ReactComponent as giropaySvg } from "src/assets/logos/giropay.svg"
import { ReactComponent as hukSvg } from "src/assets/logos/huk.svg"
import { ReactComponent as jcbSvg } from "src/assets/logos/jcb.svg"
import { ReactComponent as klarnaSvg } from "src/assets/logos/klarna.svg"
import { ReactComponent as maestroSvg } from "src/assets/logos/maestro.svg"
import { ReactComponent as mastercardSvg } from "src/assets/logos/mastercard.svg"
import { ReactComponent as paydirektSvg } from "src/assets/logos/paydirekt.svg"
import { ReactComponent as paypalSvg } from "src/assets/logos/paypal.svg"
import { ReactComponent as sofortSvg } from "src/assets/logos/sofort.svg"
import { ReactComponent as visaSvg } from "src/assets/logos/visa.svg"

export const serviceCategoriesIcons = {
  basisinspektion: inspektionSvg,
  batteriewechsel: batterySvg,
  bremsbelagwechsel: breakPadSvg,
  bremsenwechsel: brakeDiscAndPadSvg,
  bremsscheibenwechsel: discBrakeSvg,
  diagnose: serviceSvg,
  endschalldaempferwechsel: exhaustPipeSvg,
  klimaanlage: acSvg,
  oelwechsel: oilSvg,
  radwechsel: wheelCompleteSvg,
  stossdaempferwechsel: shockAbsorberSvg,
  tuvHuAu: mainInspectionTuvSvg,
}
export const servicesIcons = {
  acDisinfection: acDisinfectionSvg,
  acInspection: acInspectionSvg,
  ac: acSvg,
  airPump: airPumpSvg,
  battery: batterySvg,
  bonnet: bonnetSvg,
  breakCheck: breakCheckSvg,
  breakPad: breakPadSvg,
  cabinFilter: cabinFilterSvg,
  carTopView: carTopViewSvg,
  carWash: carWashSvg,
  catalyticConverter: catalyticConverterSvg,
  cone: coneSvg,
  coolant: coolantSvg,
  crash: crashSvg,
  crossWrench: crossWrenchSvg,
  discBrake: discBrakeSvg,
  doorglass: doorglassSvg,
  exhaustPipe: exhaustPipeSvg,
  filter: filterSvg,
  garage: garageSvg,
  gasStation: gasStationSvg,
  gearShift: gearShiftSvg,
  glass: glassSvg,
  handbrake: handbrakeSvg,
  inspektion: inspektionSvg,
  jack: jackSvg,
  key: keySvg,
  keyless: keylessSvg,
  lift: liftSvg,
  machine: machineSvg,
  mainInspectionTuv: mainInspectionTuvSvg,
  maintenance: maintenanceSvg,
  manualGearShift: manualGearShiftSvg,
  nitrogenInflation: nitrogenInflationSvg,
  oil: oilSvg,
  paintSpray: paintSpraySvg,
  partsRecyling: partsRecylingSvg,
  pedals: pedalsSvg,
  piston: pistonSvg,
  preCheck: preCheckSvg,
  pulley: pulleySvg,
  radiator: radiatorSvg,
  rearView: rearViewSvg,
  rim: rimSvg,
  safetyTriangle: safetyTriangleSvg,
  service: serviceSvg,
  shockAbsorber: shockAbsorberSvg,
  spark: sparkSvg,
  stabillity: stabillitySvg,
  steering: steeringSvg,
  suspension: suspensionSvg,
  tank: tankSvg,
  temperature: temperatureSvg,
  timingBelt: timingBeltSvg,
  turbo: turboSvg,
  tyre: tyreSvg,
  voltage: voltageSvg,
  wheelAlign: wheelAlignSvg,
  wheelComplete: wheelCompleteSvg,
  wheelLeveling: wheelLevelingSvg,
  wheelPressure: wheelPressureSvg,
  wheelSpray: wheelSpraySvg,
  wheelStorage: wheelStorageSvg,
  wheel: wheelSvg,
  whellAlignment: whellAlignmentSvg,
  wiper: wiperSvg,
}
export const paymentIcons = {
  amex: amexSvg,
  dinersclub: dinersSvg,
  discover: discoverSvg,
  giropay: giropaySvg,
  jcb: jcbSvg,
  klarna: klarnaSvg,
  maestro: maestroSvg,
  mastercard: mastercardSvg,
  paydirekt: paydirektSvg,
  paypal: paypalSvg,
  visa: visaSvg,
  sofort: sofortSvg,
}
export const marketingIcons = {
  abolaufzeit: abolaufzeitSvg,
  auszeichnungUndQualität1: auszeichnungUndQualität1Svg,
  auszeichnungUndQualität: auszeichnungUndQualitätSvg,
  autoservice: autoserviceSvg,
  begrüßung: begrüßungSvg,
  businessUndFirma: businessUndFirmaSvg,
  dokumentUndVertrag: dokumentUndVertragSvg,
  elektroautoBatterienLaden: elektroautoBatterienLadenSvg,
  fahrzeugabo: fahrzeugaboSvg,
  fairerPreis: fairerPreisSvg,
  filmUndVideo: filmUndVideoSvg,
  food: foodSvg,
  geschenk: geschenkSvg,
  heart: heartSvg,
  häckchen: häckchenSvg,
  hilfeUndSupport: hilfeUndSupportSvg,
  hotline: hotlineSvg,
  inklusive: inklusiveSvg,
  jubiläum: jubiläumSvg,
  karteUndNavigation: karteUndNavigationSvg,
  kostenlos: kostenlosSvg,
  kreativitätUndInnovation: kreativitätUndInnovationSvg,
  kunde: kundeSvg,
  leidenschaftMobilität: leidenschaftMobilitätSvg,
  lenkrad: lenkradSvg,
  neuwagenUndReinigung: neuwagenUndReinigungSvg,
  notes: notesSvg,
  onlineBuchung: onlineBuchungSvg,
  onlineTermin1: onlineTermin1Svg,
  onlineTermin: onlineTerminSvg,
  preissieger1: preissieger1Svg,
  preissieger: preissiegerSvg,
  rabattUndVorteil: rabattUndVorteilSvg,
  radwechselMarketing: radwechselSvg,
  request: requestSvg,
  searchAndReturn: searchAndReturnSvg,
  serviceMarketing: serviceMarketingSvg,
  standort: standortSvg,
  startUndSchnelligkeit: startUndSchnelligkeitSvg,
  sucheUndRücknahme: sucheUndRücknahmeSvg,
  tankstand01: tankstand01Svg,
  tankstand02: tankstand02Svg,
  team: teamSvg,
  thumbUp1: thumbUp1Svg,
  thumbUp: thumbUpSvg,
  uhrzeitUndDauer: uhrzeitUndDauerSvg,
  updateUndBenachrichtigung: updateUndBenachrichtigungSvg,
  wichtig: wichtigSvg,
}
export const commonIcons = {
  euFlag: euFlagSvg,
  licensePlateD: licensePlateDSvg,
  mail: mailSvg,
  handCoin: handCoinSvg,
  creditCard: creditCardSvg,
  magnifyingGlass: magnifyingGlassSvg,
  huk: hukSvg,
  bankcard: bankcardSvg,
  calendar: calendarSvg,
  calendarFilled: calendarFilledSvg,
  cancel: cancelSvg,
  car: carSvg,
  card: cardSvg,
  celebration: celebrationSvg,
  checkCircledFilled: checkCircledFilledSvg,
  checkCircledSuccess: checkCircledSuccessSvg,
  checkCircled: checkCircledSvg,
  checklist: checklistSvg,
  checkmark: checkmarkSvg,
  clock: clockSvg,
  close: cancelSvg,
  connectionError: connectionErrorSvg,
  deleteTextFill: deleteTextFillSvg,
  distance: distanceSvg,
  doc: docSvg,
  download: downloadSvg,
  dropdownMenuDown: dropdownMenuDownSvg,
  dropdownMenuUp: dropdownMenuUpSvg,
  edit: editSvg,
  emptyShoppingCart: emptyShoppingCartSvg,
  externalLinkBlue: externalLinkBlueSvg,
  externalLink: externalLinkSvg,
  eyeFilled: eyeFilledSvg,
  eye: eyeSvg,
  faq: faqSvg,
  hamburgerMenu: hamburgerMenuSvg,
  handShake: handShakeSvg,
  hidePassword: hidePasswordSvg,
  inclusive: inclusiveSvg,
  info: infoSvg,
  infoDots: infoDotsSvg,
  infoFilled: infoFilledSvg,
  list: listSvg,
  local: localSvg,
  location: locationSvg,
  lock: lockSvg,
  map: mapSvg,
  map1: map1Svg,
  mask: maskSvg,
  maximize: maximizeSvg,
  menuPoint: menuPointSvg,
  minimize: minimizeSvg,
  minus: minusSvg,
  multipleWorkshopLocation: multipleWorkshopLocationSvg,
  navArrowLeft: navArrowLeftSvg,
  navArrowRight: navArrowRightSvg,
  navNext: navNextSvg,
  navPrev: navPrevSvg,
  noGarage: noGarageSvg,
  pinSummary: pinSummarySvg,
  pinWorkshopCard: pinWorkshopCardSvg,
  plus: plusSvg,
  prizeComparison: prizeComparisonSvg,
  repair: repairSvg,
  question: questionSvg,
  questionMark: questionMarkSvg,
  routeStart: routeStartSvg,
  search: searchSvg,
  selectionCheckmark: selectionCheckmarkSvg,
  shoppingCart: shoppingCartSvg,
  showPassword: showPasswordSvg,
  star: starSvg,
  stepperNext: stepperNextSvg,
  successCircledFill: successCircledFillSvg,
  timer: timerSvg,
  trash: trashSvg,
  trust: trustSvg,
  uncheckedCircled: uncheckedCircledSvg,
  user: userSvg,
  warningCircledFill: warningCircledFillSvg,
  warningTriangle1: warningTriangle1Svg,
  warningTriangle: warningTriangleSvg,
  workshopLocationActive1: workshopLocationActive1Svg,
  workshopLocationActive: workshopLocationActiveSvg,
  workshopLocationInactive: workshopLocationInactiveSvg,
  wrench: wrenchSvg,
}
export const carBrandsIcons = {
  brandOpel: brandOpel,
  brandAudi: brandAudi,
  brandBMW: brandBMW,
  brandFord: brandFord,
  brandMercedes: brandMercedes,
  brandSeat: brandSeat,
  brandSkoda: brandSkoda,
  brandVolkswagen: brandVolkswagen,
}
export const navigationIcons = {
  navigationServices: navigationServicesSvg,
  navigationGarage: navigationGarageSvg,
  navigationInquiry: navigationInquirySvg,
  navigationCheckout: navigationCheckoutSvg,
  navigationVehicle: carSvg,
  navigationTermin: calendarSvg,
}
export const socialIcons = {
  facebook: facebookSvg,
  instagram: instagramSvg,
  linkedin: linkedinSvg,
  newsletter: newsletterSvg,
  onlineAppointment: onlineAppointmentSvg,
  xing: xingSvg,
  google: googleSvg,
}

export const icons = {
  ...serviceCategoriesIcons,
  ...servicesIcons,
  ...paymentIcons,
  ...marketingIcons,
  ...commonIcons,
  ...carBrandsIcons,
  ...navigationIcons,
  ...socialIcons,
} as const

export type IconName = keyof typeof icons

export type IconProps = {
  name: IconName
  className?: string
  role?: AriaRole
} & AriaAttributes

/**
 * Icon library built with multiple SVGs
 *
 * The parent element controls the size of the icon
 *
 * Make sure the icons don't have a clip-path attribute: this makes them look cropped on some displays.
 */
export const Icon: FC<IconProps> = ({ name, ...props }) => {
  const Component = icons[name]

  if (!Component) {
    return null
  }

  return (
    <Component
      role="presentation"
      aria-hidden="true"
      data-cy={`icon-${name}`}
      {...props}
    />
  )
}
