import { DmsProviderType } from "src/states/serviceFlow/garageActions"

export const isPWSGarage = (
  dmsProviderType: DmsProviderType | undefined | null,
) => {
  // dmsProviderType undefined could mean that garage is not yet selected
  // Only if dmsProviderType is null, SFK, or NO_DMS_PROVIDER_TYPE means it is PWS
  // We'll need to add here when new dmsProviderType e.g, REIFF comes
  return (
    dmsProviderType === null ||
    dmsProviderType === "SFK" ||
    dmsProviderType === "NO_DMS_PROVIDER_TYPE"
  )
}
