import { Flow } from "src/config"

import { mergeServiceFlowData } from "."

export type HukSalesManData = {
  hukSalesManIdentifier: string
  source?: string
  campaign?: string
}

export type SettingsState = {
  isHuk24Booking?: boolean
  hukSalesManData?: HukSalesManData
  flow?: Flow
}

const initialState: SettingsState = {
  isHuk24Booking: undefined,
  hukSalesManData: undefined,
  flow: "0",
}

const settingsActions = {
  reset: () => mergeServiceFlowData({ ...initialState }),

  setIsIframeBooking: () => mergeServiceFlowData({ isHuk24Booking: true }),

  addHukSalesmanData: (hukSalesManData: HukSalesManData) =>
    mergeServiceFlowData({ hukSalesManData }),

  setFlow: (flow: Flow) => mergeServiceFlowData({ flow }),

  clearFlow: () => mergeServiceFlowData({ flow: undefined }),
}

export default settingsActions
