// This file is located in the project root because we need it for both the app
// and for type generation (getContentfulEnvironment.js).

module.exports = {
  spaces: {
    autoservice: "g08ekperrx1v",
    cck: "29zmtb5aldmh",
  },
  environment: process.env.CONTENTFUL_ENVIRONMENT || "master",
  assetUrls: {
    images: {
      contentfulUrl: "https://images.ctfassets.net",
      cacheUrl: "https://images.huk-autoservice.de",
    },
    videos: {
      contentfulUrl: "https://videos.ctfassets.net",
      cacheUrl: "https://videos.huk-autoservice.de",
    },
  },
}
