import { StorageOptions } from "../syncToPersistentStorage"
import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

import { ServiceFlowData } from "./types"

const initalState: ServiceFlowData = {
  serviceCategories: [],
}

const localStorage: StorageOptions = {
  key: "service-flow-data",
  version: "2023-12-14",
  storage: "localStorage",
}

const { getValue, setValue, useValue, mergeValue } =
  createSimpleGlobalState<ServiceFlowData>(initalState, localStorage)

export {
  getValue as getServiceFlowData,
  setValue as setServiceFlowData,
  useValue as useServiceFlowData,
  mergeValue as mergeServiceFlowData,
}
