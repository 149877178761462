import { createContext, FC, PropsWithChildren, useContext } from "react"

import { EnvironmentVariables } from "src/config"

const Context = createContext<EnvironmentVariables | null>(null)

export const useEnvironmentVariables = (): EnvironmentVariables => {
  return useContext(Context) as EnvironmentVariables
}

export type EnvironmentVariablesProviderProps = PropsWithChildren<{
  variables?: EnvironmentVariables
}>

export const EnvironmentVariablesProvider: FC<
  EnvironmentVariablesProviderProps
> = ({ children, variables }) => {
  const { Provider } = Context

  return <Provider value={variables ?? {}}>{children}</Provider>
}
