import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

import { StorageOptions } from "./syncToPersistentStorage"

export type LeadTrackingData = {
  utmSource?: string
  tracked?: boolean
}

const localStorage: StorageOptions = {
  key: "lead-tracking-status",
  version: "2023-12-11",
  storage: "localStorage",
}

const { getValue, setValue, useValue, mergeValue } =
  createSimpleGlobalState<LeadTrackingData>({}, localStorage)

export {
  getValue as getLeadTrackingData,
  setValue as setLeadTrackingData,
  useValue as useLeadTrackingData,
  mergeValue as mergeLeadTrackingData,
}
