import Head from "next/head"
import { CSSProperties, FC } from "react"

import helveticaBold from "src/assets/fonts/helvetica/HelveticaNeueforHUK-bold.woff2"
import helveticaRegular from "src/assets/fonts/helvetica/HelveticaNeueforHUK-regular.woff2"
import moderatBold from "src/assets/fonts/moderat/moderat-bold.woff2"
import moderatRegular from "src/assets/fonts/moderat/moderat-regular.woff2"

type FontProps = {
  family: CSSProperties["fontFamily"]
  style?: CSSProperties["fontStyle"]
  weight: CSSProperties["fontWeight"]
  url: string
}

const Font: FC<FontProps> = ({ family, style = "normal", weight, url }) => (
  <Head>
    <link
      rel="preload"
      href={url}
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    <style
      dangerouslySetInnerHTML={{
        __html: `
          @font-face {
            font-family: '${family}';
            font-style: ${style};
            font-weight: ${weight};
            font-display: swap;
            src: url('${url}') format('woff2');
          }`
          .replace(/\s+/g, " ")
          .trim(),
      }}
    />
  </Head>
)

export const Fonts: FC = () => (
  <>
    <Font url={moderatRegular} family="Moderat" weight="normal" />
    <Font url={moderatBold} family="Moderat" weight="bold" />
    <Font url={helveticaRegular} family="Helvetica-Condensed" weight="normal" />
    <Font url={helveticaBold} family="Helvetica-Condensed" weight="bold" />
  </>
)
