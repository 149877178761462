import { Portal, Root } from "@radix-ui/react-dialog"
import { FC } from "react"

import { ModalOverlay } from "src/components/common/modal/ModalOverlay"
import { useNotificationModal } from "src/states/notificationModal"

import { NotificationModalContent } from "./NotificationModalContent"

export const NotificationModal: FC = () => {
  const data = useNotificationModal()
  const open = !!data

  if (!open) {
    return null
  }

  return (
    <Root open={open}>
      {open && (
        <Portal>
          <ModalOverlay />
          <NotificationModalContent />
        </Portal>
      )}
    </Root>
  )
}
