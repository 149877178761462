import { sumServiceCategoriesPricing } from "src/helpers/sumServiceCategoriesPricing"
import {
  extractInternalServiceCategoryIds,
  parseServiceCategoriesToMetaContents,
} from "src/helpers/tracking/eventParsersAndHandlers"
import {
  GTMEvents,
  pushTrackingEventToDataLayer,
} from "src/helpers/tracking/gtm"
import {
  EventHandler,
  MetaEventData,
} from "src/helpers/tracking/tracking-event-handlers/types"
import { isValue } from "src/utils/typeGuards"

type PaymentEventData = Pick<
  MetaEventData,
  "serviceCategories" | "detailedGarage"
>

export const payment: EventHandler<PaymentEventData, "payment"> = (
  { data, gaEvent },
  eventType = "payment",
) => {
  const meta: GTMEvents[typeof eventType]["meta"] = isValue(data)
    ? {
        content_category: eventType,
        content_ids: extractInternalServiceCategoryIds(data.serviceCategories),
        currency: "EUR",
        contents: parseServiceCategoriesToMetaContents(data.serviceCategories),
        value: sumServiceCategoriesPricing(
          data.serviceCategories,
          data.detailedGarage?.services,
        ),
      }
    : undefined

  return pushTrackingEventToDataLayer({
    eventType,
    payload: { meta, gaEvent },
  })
}
