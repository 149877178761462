import { setOfferNotification } from "./offerNotification"
import { OfferNotification } from "./types"

const initialState: OfferNotification = {}

const offerNotificationActions = {
  setOfferNotificationInteracted: (value: boolean) => {
    setOfferNotification({
      offerNotificationInteracted: value,
    })
  },

  clearOfferNotification: () => setOfferNotification(initialState),
}

export default offerNotificationActions
