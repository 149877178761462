import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

import { StorageOptions } from "./syncToPersistentStorage"

export type Theme = {
  theme?: "huk24" | "hukcoburg"
}

const sessionStorage: StorageOptions = {
  key: "theme",
  version: "2024-04-05",
  storage: "sessionStorage",
}

const { getValue, setValue, useValue, mergeValue } =
  createSimpleGlobalState<Theme>({}, sessionStorage)

export {
  getValue as getTheme,
  setValue as setTheme,
  useValue as useTheme,
  mergeValue as mergeTheme,
}
