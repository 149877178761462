import { UtmProperties, mergeUtmProperties } from "./utmProperties"

const initialState: UtmProperties = {
  utm_campaign: undefined,
  utm_medium: undefined,
  utm_source: undefined,
}

const utmActions = {
  reset: () => mergeUtmProperties({ ...initialState }),
  setUtmCampaign: (utm_campaign: string) =>
    mergeUtmProperties({ utm_campaign }),
  setUtmMedium: (utm_medium: string) => mergeUtmProperties({ utm_medium }),
  setUtmSource: (utm_source: string) => mergeUtmProperties({ utm_source }),
}

export default utmActions
