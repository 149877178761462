import { useContext, useDebugValue, useEffect } from "react"

import { UsercentricsContext } from "../context"
import type { ServiceId } from "../types"
import { getServicesBaseInfo } from "../utils"

export const useServiceDebug = (serviceId: ServiceId) => {
  const { isInitialized, ping } = useContext(UsercentricsContext)

  useDebugValue(getServicesBaseInfo(), (services) =>
    services.find(({ id }) => serviceId === id),
  )

  useEffect(() => {
    if (!isInitialized) return

    if (!getServicesBaseInfo().find(({ id }) => serviceId === id)) {
      throw new Error(`Usercentrics Service not found for id "${serviceId}"`)
    }
  }, [isInitialized, ping, serviceId])
}
