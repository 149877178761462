import { DetailedGarage } from "src/states/serviceFlow/garageActions"
import { ServiceCategory } from "src/states/serviceFlow/types"

export const sumServiceCategoriesPricing = (
  serviceCategories: ServiceCategory[] = [],
  garagePricing: DetailedGarage["services"] = [],
) => {
  return serviceCategories
    .filter((c) => !!c)
    .reduce((total, category) => {
      const contentfulServicePrice = category?.service?.price ?? 0

      const serviceGaragePricing = garagePricing.find(
        ({ internalServiceId }) =>
          internalServiceId === category.service?.internalServiceId,
      )

      const servicePrice =
        serviceGaragePricing?.priceBeforeDiscount ?? contentfulServicePrice ?? 0

      const categoryAdditionalService = category?.additionalServices

      const additionalServicesPriceSum = categoryAdditionalService.reduce(
        (total, service) => {
          const garageInfo = serviceGaragePricing?.additionalServices?.find(
            ({ additionalServiceId }) =>
              additionalServiceId === service?.internalAdditionalServiceId,
          )

          return (
            total +
            ((garageInfo ? garageInfo.priceBeforeDiscount : service?.price) ??
              0)
          )
        },
        0,
      )

      return total + (servicePrice ?? 0) + additionalServicesPriceSum
    }, 0)
}
