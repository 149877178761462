import {
  extend,
  hookstate,
  SetPartialStateAction,
  useHookstate,
} from "@hookstate/core"

import {
  syncToPersistentStorage,
  StorageOptions,
} from "src/states/syncToPersistentStorage"

//hookstate 4 state is immutable and to make it writable we need to create this type and assign this type to getValue AND useValue
type Writeable<T> = { -readonly [P in keyof T]: T[P] }

export function createSimpleGlobalState<T>(
  initialValue: T,
  storageOptions?: StorageOptions,
) {
  // if no storage options are provided, only runtime state will be created by default
  const state = storageOptions
    ? hookstate<T>(
        initialValue,
        extend(syncToPersistentStorage(storageOptions)),
      )
    : hookstate<T>(initialValue)

  const getValue = () => state.get() as Writeable<T>
  const setValue = (value: T) => state.set(value)
  const mergeValue = (value: SetPartialStateAction<T>) => state.merge(value)
  const useValue = () =>
    useHookstate(state).get({ noproxy: true }) as Writeable<T>

  return {
    getValue,
    setValue,
    useValue,
    mergeValue,
  }
}
