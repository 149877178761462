import { useRouter } from "next/router"
import { FC } from "react"

import { Button } from "src/components/common/button"
import { Icon, IconName } from "src/components/common/icon"

import { ROUTES } from "src/config"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"
import {
  NotificationToastLink,
  NotificationToastVariant,
} from "src/states/notificationToasts"

import offerNotificationActions from "src/states/offerNotification/offerNotificationActions"

import styles from "./NotificationToast.module.scss"

const bem = create(styles, "NotificationToast")

export type NotificationToastProps = {
  id: number
  icon?: IconName
  variant: NotificationToastVariant
  content: string
  showOncePerSession?: boolean
  link?: NotificationToastLink
  onClose: (id: number) => void
}

const iconMap: Record<NotificationToastVariant, IconName | undefined> = {
  info: undefined,
  success: "checkCircledSuccess",
  error: "warningTriangle",
}

export const NotificationToast: FC<NotificationToastProps> = ({
  id,
  icon,
  variant,
  content,
  showOncePerSession,
  link,
  onClose,
}) => {
  const { messages, formatMessageRaw } = useTranslation()
  const { notification } = messages.components.core
  const iconName = icon || iconMap[variant]

  const router = useRouter()

  const navigateToPage = (route: string) => {
    router.push(route)
    onCloseNotification()
  }

  const onCloseNotification = () => {
    onClose(id)

    if (showOncePerSession) {
      offerNotificationActions.setOfferNotificationInteracted(true)
    }
  }

  const formattedContent = formatMessageRaw(content, {
    bold: (title: string) => (
      <span className={bem("bold")} key={title}>
        {title}
      </span>
    ),
    link: (linkText: string) => (
      <span
        className={bem("bold")}
        key={linkText}
        onClick={() => navigateToPage(link?.link ?? ROUTES.index)}
      >
        {linkText}
      </span>
    ),
  })

  const handleClick = () => {
    onCloseNotification()
    link?.tracking?.()
  }

  return (
    <div
      className={bem(undefined, { "as-toast": true, [`is-${variant}`]: true })}
    >
      <div className={bem("message")}>
        {iconName && <Icon className={bem("icon")} name={iconName} />}
        <p className={bem("content")}>{formattedContent}</p>

        <button
          type="button"
          aria-label={notification.close}
          className={bem("close")}
          onClick={() => onCloseNotification()}
          data-cy="close-notification-button"
        >
          <Icon className={bem("close__icon")} name="cancel" />
        </button>
      </div>
      {link?.variant && (
        <div className={bem("link")}>
          <Button
            variant={link.variant}
            href={link.link}
            onClick={handleClick}
            icon={{ position: "right", name: "navNext" }}
          >
            {link.text}
          </Button>
        </div>
      )}
    </div>
  )
}
