import { ValueOf } from "type-fest"

export const serviceIds = {
  googleAnalitics: "HkocEodjb7",
  googleMaps: "S1pcEj_jZX",
} as const

export type ServiceId = ValueOf<typeof serviceIds>

export type ServiceInfo = {
  id: ServiceId
  name: string
  consent: {
    status: boolean
  }
}

export type ServiceInfoFromLocalStorage = {
  id: string
  status: boolean
}

export type SettingsFromLocalStorage = {
  services: ServiceInfoFromLocalStorage[]
}

/**
 * Typing for the main `window.UC_UI` API used for integration
 * Do not declare this globally, but prefer to use the included utility functions instead.
 */
type UC_UI = {
  /**
   * Programmatic way to close the CMP layers
   * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=closecmp
   */
  closeCMP?: () => void

  /**
   * A method to get array of all services with their basic information
   * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=getservicesbaseinfo
   */
  getServicesBaseInfo?: () => ServiceInfo[]

  /**
   * A method to check if consent is required (no consent given or resurface). Return value is only available after UI has initialized.
   * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=isconsentrequired
   */
  isConsentRequired?: () => void

  /**
   * A method to check if app is initialized or not
   * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=isinitialized
   */
  isInitialized?: () => boolean

  /**
   * Programmatic way to show First Layer.
   * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=showfirstlayer
   */
  showFirstLayer?: () => void

  /**
   * Programmatic way to show Second Layer. If a service/vendor Id value is passed,
   * Second Layer will open the right tab, scroll to the given service/vendor entry and expand it.
   * If no Id is passed, Second Layer will be shown without srcolling to any specific service/vendor.
   *
   * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=showsecondlayer
   */
  showSecondLayer?: (serviceId?: ServiceId) => void

  /**
   * Programmatic way for accepting multiple services without open the user centrics modal.
   *
   * @see https://docs.usercentrics.com/#/cmp-v2-ui-api?id=acceptservices
   */
  acceptServices?: (serviceIds?: ServiceId[]) => void
}

/**
 * Augmented window type, possibly including the `UC_UI` API.
 * Do not declare this globally, but prefer to use the included utility functions instead.
 */
export type UCWindow = Window & typeof globalThis & { UC_UI?: UC_UI }

export enum UCUICMPEventType {
  ACCEPT_ALL = "ACCEPT_ALL",
  CMP_SHOWN = "CMP_SHOWN",
  DENY_ALL = "DENY_ALL",
  IMPRINT_LINK = "IMPRINT_LINK",
  MORE_INFORMATION_LINK = "MORE_INFORMATION_LINK",
  PRIVACY_POLICY_LINK = "PRIVACY_POLICY_LINK",
  SAVE = "SAVE",
}

/**
 * This event is triggered by the most important actions through the Consent Management Platform
 * @see https://docs.usercentrics.com/#/v2-events?id=uc_ui_cmp_event
 */
export type UCUICMPEvent = CustomEvent<{
  source?: "FIRST_LAYER" | "SECOND_LAYER"
  type?: UCUICMPEventType
}>
