export const messages = {
  components: {
    core: {
      accountIcon: {
        title: "Account",
        popover: {
          title: "Bereits registriert?",
          description:
            "Dann melde Dich an, damit wir Deine Daten vorbefüllen können und Du noch bequemer buchen kannst.",
          button: "Anmelden",
        },
      },
      bindingCard: {
        title: "Jetzt unverbindlich Preis und Termin anfragen",
        description:
          "Stelle einfach Deine unverbindliche Service-Anfrage. Unser Service-Partner wird sich zeitnah bei Dir melden.",
      },
      breadcrumbs: {
        home: "Startseite",
      },
      navigation: {
        desktop: {
          items: {
            aboutUs: "Über uns",
            allServices: "Ratgeber",
            hukAutoWallet: "HUK-AutoWallet",
            serviceBooking: "Service-Übersicht",
          },
        },
        mobile: {
          modalTitle: "Menü",
          items: {
            account: {
              title: "Mein Account",
              help: "Hilfe",
              subNavigation: {
                bookings: "Aufträge & Erneut Buchen",
                costOverview: "Kostenübersicht",
                home: "Meine Übersicht",
                myData: "Nutzerkonto & Vorteilskarte",
                qa: "Fragen und Antworten",
                support: "Support kontaktieren",
                vehicles: "Gespeicherte Fahrzeuge",
              },
            },
            services: {
              title: "Ratgeber",
              subNavigation: {
                battery: "Batteriewechsel",
                brakes: "Bremsen",
                climate: "Klima",
                exhaust: "Auspuff",
                inspection: "Inspektion",
                landingGear: "Fahrwerk",
                oil: "Ölwechsel",
                security: "Sicherheits-Check",
                tuv: "HU/AU",
                wheels: "Räder",
              },
            },
            aboutUs: {
              title: "Über uns",
              subNavigation: {
                contact: "Kontakt",
                qa: "Fragen und Antworten",
              },
            },
          },
          buttons: {
            back: "Zurück",
            bookService: "Jetzt Termin buchen",
            login: "Anmelden/Registrieren",
            logOut: "Abmelden",
          },
        },
      },
      payment: {
        scriptError: "Das Bezahlformular konnte nicht geladen werden",
        tryAgain: "Try Again",
        failed: "Die Terminreservierung ist fehlgeschlagen.",
      },
      header: {
        logo: "HUK Autowelt",
      },
      nextAppointmentHeader: {
        greeting: "Guten Tag",
        recommendation: {
          title: "Unsere Empfehlung",
          description:
            "Da wir noch Deine Daten von der letzten Buchung haben, kannst Du ganz bequem in wenigen Schritten erneut buchen.",
        },
        noAppointments: "Du hast keine bevorstehenden Termine.",
        nextAppointment: "Aktuelle Termine",
      },
      footer: {
        title: "HUK-COBURG Autowelt GmbH",
        modal: {
          sum: "Gesamt",
          link: "Meine Auswahl",
          tax: "inkl. MwSt",
          linkNoService: "Service auswählen",
          totalPrice: "Preis",
          onDemand: "auf Anfrage",
        },
        links: {
          contact: "Kontakt",
          privacy: "Datenschutz",
          imprint: "Impressum",
          about: "Über uns",
          terms: "AGB",
          userCentrics: "Privatsphäre Einstellungen",
        },
      },
      notification: {
        close: "Schließen",
      },
      covidNote: {
        title: "Corona-Hinweis",
        description:
          "Zutritt ist nur Personen gestattet, die einen aktuellen Impfausweis vorlegen und vor Ort Maske tragen.",
      },
      supportPopover: {
        headline: "Wie können wir Dir helfen?",
        options: {
          serviceWizard: "Service auswählen",
          services: "Service finden",
          appointment: "Abgabetermin buchen",
          support: "Support kontaktieren",
          findGarage: "Werkstatt finden",
          request: "Anfrage stellen",
          vehicle: "Fahrzeug finden",
          searchGarage: "Suche nutzen",
          garageRequest: "Werkstatt-Wunsch",
          booking: "Buchungsprozess unklar?",
          payment: "Bezahlmethoden",
          next: "Wie geht es weiter?",
        },
      },
      backNavigation: {
        back: "Zurück",
      },
      shoppingCart: {
        title: "Deine Auswahl",
        expandableTitle: "Enthaltene Leistungen",
        button: {
          label: "Tasche {itemsInCart} Artikel",
        },
        empty: {
          description: "Du hast noch keine Services gewählt",
          buttons: {
            bookService: "Jetzt Service buchen",
            close: "Schliessen",
          },
        },
        appointment: {
          vehicle: "Fahrzeug",
        },
        inquiry: {
          text: "Füge eine optionale Notiz hinzu",
          placeHolder: "Besondere Hinweise an die Werkstatt",
          subject: "Zusätzliche Angaben zu meiner Buchung",
        },
        navigation: {
          buttons: {
            forward: "Weiter im Bestellprozess",
            back: "Zurück",
            backToServices: "Weitere Services aussuchen",
          },
        },
        totalPrice: {
          total: "Gesamtsumme",
          tax: "inkl. MwSt",
        },
        faq: {
          title: "Hast Du noch Fragen?",
          link: "zu FAQs",
        },
      },
      mostBookedServices: {
        title: "Wird oft gemeinsam gebucht",
        all: "Alle Services",
      },
      backConfirmation: {
        title: "Deine bisherigen Informationen gehen verloren.",
        message:
          "Möchtest Du Deine Serviceauswahl oder Deine Fahrzeugdaten ändern, gehen Deine bisherigen Auswahlen verloren und Deine gewählte Werkstatt hat möglicherweise keinen Termin mehr frei.",
        buttonConfirm: "Zurück und Bearbeiten",
        buttonCancel: "Abbrechen und mit Buchung fortfahren",
      },
      bookingSummaryCard: {
        title: "Services Gesamtpreis",
        servicePrice: "Servicekosten",
        noPriceText:
          "Wähle einen Werkstattpartner und gib deine Fahrzeugdaten ein, um einen Preis zu erhalten.",
        buttons: {
          showDetails: "Details zeigen",
          hideDetails: "Details ausblenden",
        },
        priceModal: {
          title: "Enthaltene Leistungen",
          back: "Zurück",
        },
        inspection: {
          title: "Inspektion",
          car: "Fahrzeug",
        },
        table: {
          fields: {
            name: "Artikel",
            unit: "Menge",
            unitPrice: "Einzelpreis",
            totalAmountPaid: "Gesamt",
          },
          units: {
            min: "min.",
            l: "l.",
            stk: "stk.",
          },
        },
        replacementCar: "Ersatzwagen",
        deleteConfirmation: {
          title: "{serviceName} wirklich löschen?",
          content:
            "Bist Du sicher, dass Du den Service {serviceName} löschen möchtest?",
          buttons: {
            confirm: "Serviceauswahl löschen",
          },
        },
        notifications: {
          deleteServiceSucess: "Service erfolgreich gelöscht.",
          promoCodeRemovalNotification:
            "Dein Gutschein wurde entfernt. Bitte gebe ihn bei Bedarf erneut ein.",
        },
        addMore: "Weitere Services hinzufügen",
      },
    },
    contentful: {
      items: {
        packageTeaser: {
          promotionPeriodUntil: "Aktionszeitraum bis",
          from: "ab",
        },
      },
    },
    cck: {
      footer: {
        copyright: "© HUK-COBURG Autoservice GmbH",
      },
      boldTeaser: {
        garageSearchVariant: {
          servicePlaceholder: "Service auswählen",
          locationPlaceholder: "Standort eingeben",
          loading: "Wird geladen...",
          button: {
            goToServiceDetails: "Weiter zu den Servicedetails",
            goToGarage: "Weiter zur Werkstatt",
          },
          configuration: "Konfiguration",
        },
      },
    },
    common: {
      toggleCard: {
        free: "kostenlos",
        tax: "inkl. MwST.",
        recommended: "empfohlen",
        inclusive: "inklusive",
      },

      autocomplete: {
        labels: {
          minCharNeeded: "Bitte gib mindestens 3 Buchstaben ein",
          noRecordsFound: "Keine Ergebnisse",
          defaultPlaceholder: "Suche...",
        },
      },
      carousel: {
        buttons: {
          next: {
            label: "Nächste Seite",
            title: "Zur nächsten Seite",
          },
          prev: {
            label: "Vorherige Seite",
            title: "Zur vorherigen Seite",
          },
        },
        pagination: {
          button: {
            label: "{index}. Seite",
            title: "Zur {index}. Seite springen",
          },
        },
      },
      loading: {
        status: "Wird geladen...",
      },
      calendar: {
        title: "{currentDate, date, ::MMMM y}",
        buttons: {
          next: "Gehe zum nächsten Monat",
          previous: "Gehe zum vorherigen Monat",
        },
        weeknames: {
          // {date, date, ::EEEE} is currently not supported by Intl MessageFormat.
          // Therefore "fullDayName" is available for both options:
          title: "{fullDayName}",
          label: "{date, date, ::EE}",
        },
        day: {
          label: "{day, date, ::d. MMMM y}",
        },
      },
      countDownTimer: {
        expired: "Abgelaufen!!!",
        days: "<day>Tag</day><days>Tage</days>",
        hours: "<hour>Stunde</hour><hours>Stunden</hours>",
        minutes: "<minute>Minute</minute><minutes>Minuten</minutes>",
        seconds: "<second>Sekunde</second><seconds>Sekunden</seconds>",
        shortDay: "t :",
        shortHour: "st :",
        shortMinute: "m :",
        shortSecond: "s",
      },
      forms: {
        common: {
          or: "oder",
        },
        vehicleForm: {
          carSelection: {
            title: "Gespeicherte Fahrzeuge",
            newCar: "Neues Fahrzeug",
            incomplete: "Vervollständige Deine Fahrzeugdaten",
            error:
              "Vorhandene Autos können nicht abgerufen werden. Bitte fügt manuell eines hinzu",
          },
          licensePlate: {
            title: "KFZ-Kennzeichen",
            description:
              "Bitte gib Dein KFZ-Kennzeichen an, damit wir Deine Anfrage vor Ort direkt dem richtigen Fahrzeug zuweisen können.",
            checkbox: "Ausländisches Kennzeichen?",
          },
          VehicleDataPicker: {
            title: "Fahrzeugdaten",
            description:
              "Mit Deinem Fahrzeugschein kannst Du Dein Fahrzeug schnell über die dort vermerkte Herstellerschlüsselnummer (HSN) und Typschlüsselnummer (TSN) finden. Hast Du den Fahrzeugschein gerade nicht griffbereit, nutze unsere manuelle Suchfunktion.",
            toggle: {
              manual: "Manuelle Suche",
              hsntsn: "HSN/TSN",
            },
            button: "Fahrzeug suchen",
            cantFindMyCar: {
              button: "Ich kann mein Auto nicht finden",
              title: "Manuelle Fahrzeugeingabe",
              subtitle:
                "Deine Fahrzeugdaten konnten in unserem System nicht gefunden werden?",
              content:
                "Kein Problem! Gib einfach Deine HSN/TSN so ein, wie sie in Deinem Fahrzeugschein steht. Wir leiten Deinen Eintrag an die Werkstatt weiter.",
              hsn: "HSN",
              tsn: "TSN",
              back: "Abbrechen",
              confirm: "Bestätigen",
              error:
                "Leider konnte unser System Deine Fahrzeugdaten nicht finden. Bitte gehe auf “Ich kann mein Auto nicht finden” und trage dort Deine HSN/TSN so ein, wie sie in Deinem Fahrzeugschein steht. Wir leiten Deinen Eintrag an die Werkstatt weiter.",
            },
            success: "Fahrzeugdaten erfolgreich aktualisiert",
            error: "Speichern Fahrzeug ist fehlgeschlagen",
            hsnTsn: {
              explanation:
                "Die Hersteller-und Typschlüsselnummer (HSN/TSN) befindet sich in der Zulassungsbescheinigung Teil 1 im Feld 2.1 und 2.2.",
              label: "HSN/TSN (2.1 & 2.2 im Fahrzeugschein)",
              hsnPlaceholder: "HSN",
              tsnPlaceholder: "TSN",
              alt: "Image showing car document and location of HSN/TSN information",
              button: "Fahrzeug suchen",
              carNotFoundError: {
                hsnTsn:
                  "Leider konnte unser System Deine Fahrzeugdaten nicht finden. Bitte versuche es mit einer erneuten Eingabe oder nutze die manuelle Suche.",
                hsnTsnManual:
                  "Leider konnte unser System Deine Fahrzeugdaten nicht finden. Bitte gehe auf “<bold>Ich kann mein Auto nicht finden</bold>” und trage dort Deine HSN/TSN so ein, wie sie in Deinem Fahrzeugschein steht. Wir leiten Deinen Eintrag an die Werkstatt weiter.",
                makeModelTree:
                  "Leider haben wir für dieses Fahrzeug nicht die erforderlichen HSN/TSN-Daten in unserer Datenbank. Bitte gehe auf “<bold>Ich kann mein Auto nicht finden</bold>” und trage dort Deine HSN/TSN so ein, wie sie in Deinem Fahrzeugschein steht. Wir leiten Deinen Eintrag an die Werkstatt weiter.",
              },
              notification: {
                success: "Gültige Fahrzeuginformationen",
                close:
                  "Für den von Dir gewünschten Service benötigen wir die Eingabe Deiner HSN/TSN.",
              },
            },
            manualVehicleSelection: {
              brandButtons: {
                label: "Beliebte Marken",
              },
              brand: {
                label: "Marke",
                placeholderText: "Bitte Marke auswählen",
                error: "Fehler beim Abrufen von Marken",
                favouriteBrands: "Favoriten",
                allBrands: "Alle",
                loadingText: "Wird geladen",
              },
              model: {
                label: "Modell",
                placeholderText: "Bitte Modell auswählen",
                error: "Fehler beim Abrufen von Modellen",
                loadingText: "Wird geladen",
              },
              engine: {
                label: "Motorisierung",
                placeholderText: "Bitte Motorisierung auswählen",
                error: "Fehler beim Abrufen von Motorisierungen",
                loadingText: "Wird geladen",
              },
            },
          },
          vehicleInformationCard: {
            title: "Fahrzeugdaten",
            brandAndModel: "Marke & Modell",
            manufacturingPeriod: "Herstellungs-zeitraum",
            engine: "Motorisierung",
            body: "Karosserie",
            editButton: "bearbeiten",
            hsnTsn: "HSN/TSN",
          },
          additionalVehicleInformation: {
            title: "Basisangaben",
            description:
              "Um den exakten Preis zu berechnen, benötigen wir Deine Fahrzeugdaten.",
            registrationDate: {
              label: "Erstzulassung",
            },
            kmDriven: {
              label: "Kilometerstand",
              placeholder: "Kilometerstand eingeben",
            },
            vin: {
              label: "Fahrgestellnummer (FIN)",
              placeholder: "Fahrgestellnummer eingeben",
              infoTooltip:
                "Die Fahrgestellnummer findest Du im Fahrzeugschein Deines Autos (Feld E).",
            },
            gearType: {
              label: "Getriebeart",
              values: {
                automatic: "Automatik",
                manual: "Schaltung",
              },
            },
          },
          inspectionVehicleInformation: {
            title: "<bold>Inspektions-Historie</bold>",
            optional: "(optional)",
            description:
              "Die Inspektions-Historie hilft uns den tatsächlichen Serviceaufwand einzuschätzen uns unser Angbot noch besser zu kalkulieren.",
            type: {
              label: "Inspektionbezeichnung",
              placeholder: "Art der Inspektion",
            },
            kmDrivenInspection: {
              label: "Kilometerstand bei letzter Inspektion",
              placeholder: "Kilometerstand",
            },
            kmDrivenBeltChange: {
              label: "Kilometerstand bei letztem Zahnriemenwechsel",
              placeholder: "Kilometerstand",
            },
            inspectionDate: {
              label: "Datum der letzten Inspektion",
            },
            beltChangeDate: {
              label: "Datum des letzten Zahnriemenwechsels",
            },
            additional: {
              label: "Ergänzende Hinweise",
              placeholder: "Was sollte zur Inspektion beachtet werden?",
            },
          },
          optionalVin: {
            title: "<bold>Fahrgestellnummer</bold>",
            optional: "(optional)",
            description:
              "Mit Deiner Fahrgestellnummer (FIN) können wir exakte Details zu Deinem Auto abrufen. Teile sie uns mit, damit Dir unser Partner ein faires und auf Dein Fahrzeug zugeschnittenes Angebot kalkulieren kann.",
          },
        },
        fields: {
          optional: "(optional)",
          countries: {
            de: "Deutschland",
            be: "Belgien",
            nl: "Niederlande",
            other: "Andere",
          },
        },
        validations: {
          title: {
            required: "Wie dürfen wir Dich ansprechen?",
          },
          email: {
            required: "Bitte gib Deine E-Mail-Adresse ein",
            pattern: "Das scheint keine gütige E-Mail-Adresse zu sein",
            minLength:
              "Die E-Mail-Adresse ist zu kurz. Bitte verwende mindestens 6 Zeichen.",
          },
          name: {
            invalid:
              "Erlaubt sind 2-50 Buchstaben mit Leerzeichen, Bindestrichen oder Punkten",
          },
          firstName: {
            required: "Bitte gib Deinen Vornamen ein",
          },
          lastName: {
            required: "Bitte gib Deinen Nachnamen ein",
          },
          street: {
            required: "Bitte gib Deine Straße ein",
            invalidGerman:
              "Ungültige Eingabe, erlaubt sind 3-50 Zeichen: Buchstaben, Zahlen, einzelne Leerzeichen, Bindestriche, Punkte und Apostrophe",
            invalidOthers:
              "Ungültige Eingabe, erlaubt sind 2-50 Zeichen: mindestens ein Buchstabe, keine doppelten Leerzeichen",
          },
          houseNumber: {
            required: "Bitte gib Deine Hausnummer ein",
            invalidGerman:
              "Ungültiges Format: Bitte geben Sie eine Zahl (1-4 Ziffern), optional gefolgt von einem Buchstaben, und optional '-' oder '/'",
            invalidOthers:
              "Ungültiges Format: 1-4 Ziffern, optional gefolgt von einem Buchstaben. Kann mit '-' oder '/' und weiteren 1-4 Ziffern plus optionalem Buchstaben erweitert werden",
          },
          postalCode: {
            required: "Bitte gib Deine Postleitzahl ein",
            invalidGerman:
              "Die eingegebene Postleitzahl muss aus 5 Ziffern bestehen. Bitte verwenden Sie nur Ziffern von 0 bis 9",
            invalidOthers:
              "Die Postleitzahl darf maximal 10 Zeichen lang sein, muss mindestens ein alphanumerisches Zeichen enthalten und darf keine doppelten Leerzeichen aufweisen",
          },
          city: {
            required: "Bitte gib Deine Stadt ein",
            maxLength: "Deine Stadt muss maximal 30 Zeichen lang sein",
            invalidGerman:
              "Ungültige Eingabe: max. 30 Zeichen, keine doppelten Leerzeichen, erlaubt: Buchstaben, Punkte, Bindestriche, Klammern, Kommas, Leerzeichen",
            invalidOthers:
              "Ungültige Eingabe: max. 30 Zeichen. Keine doppelten Leerzeichen, Bindestriche oder Punkte. Erlaubt: Buchstaben, Zahlen, viele Sonderzeichen",
          },
          country: {
            required: "Bitte gib Dein Land ein",
          },
          phoneNumber: {
            required: "Bitte gib Deine Telefonnummer ein",
            pattern: "Die Telefonnummer ist nicht gültig",
            invalid:
              "Erlaubt sind Zahlen mit Leerzeichen , Bindestrichen, Klammern oder Schrägstrichen",
          },
          addressAddition: {
            invalid: "Erlaubt sind max. 30 Zeichen",
          },
          licensePlate: {
            required: "Bitte trag Dein KFZ Kennzeichen ein",
            invalid: "Dein KFZ Kennzeichen ist nicht gültig",
          },
          newPassword: {
            required: "Bitte gib Dein neues Passwort ein",
            minLength:
              "Dein neues Passwort muss mindestens 8 Zeichen lang sein",
          },
          passCreatorTerms: {
            required:
              "Für die Nutzung der Karte benötigen wir Dein Einverständnis zur Kontaktaufnahme. Bitte gib uns dieses durch Anwählen des obigen Einverständnisses.",
          },
          hsn: {
            required: "Bitte gib Deine HSN ein",
            length: "Die HSN muss vier Zeichen lang sein",
            number: "Der HSN muss eine Nummer sein",
          },
          tsn: {
            required: "Bitte gib Deine TSN ein",
            length: "Die TSN muss mindestens drei Zeichen lang sein",
            lettersAndNumbers:
              "Die TSN darf nur Buchstaben und Zahlen enthalten",
          },
          brand: {
            required: "Bitte wähle die Automarke aus",
          },
          model: {
            required: "Bitte wähle das Fahrzeugmodell aus",
          },
          engine: {
            required: "Bitte wähle die Fahrzeugmotorisierung aus",
          },
          gearType: {
            required: "Bitte Getriebeart eingeben",
          },
          registrationDate: {
            required: "Bitte Erstzulassung eingeben",
            invalid: "Bitte prüfe das Format: MM.JJJJ",
            invalidComparison: "Datum liegt vor der Erstzulassung.",
          },
          kmDriven: {
            required: "Bitte Kilometerstand eingeben",
            invalidComparison:
              "Der Wert übersteigt den aktuellen Kilometerstand.",
          },
          vin: {
            required: "Bitte Fahrgestellnummer eingeben",
          },
          discountCampaign: {
            kfz: {
              invalid: "Ungültige Versicherungsscheinnummer",
            },
            acceptTerms: {
              required: "Du musst die AGB lesen und akzeptieren",
            },
          },
          contact: {
            topic: {
              required: "Bitte wähle Dein Thema",
            },
            message: {
              required: "Bitte gib Dein Nachricht ein",
            },
          },
          invalid: "Bitte prüfe Deine Eingabe",
        },
      },
      info: {
        button: {
          label: "Mehr Details anzeigen",
        },
      },
      locationSearch: {
        placeholder: "Bitte gib Deine Adresse ein",
        button: {
          useCurrentLocation: "Aktuellen Standort verwenden",
          clear: "Eingabe löschen",
          error: "Falscher Wert",
        },
        geolocationPositionError: {
          permissionDenied: "Standortberechtigung verweigert",
          noGeolocation: "Unbekannter Fehler",
        },
        fakeLocationSearch: {
          placeholder: "Standort eingeben",
          modal: {
            title: "Zustimmung für Werkstatt-Suche",
            text: "Wir nutzen Google Maps für die Werkstatt-Suche. Dabei werden Daten an Google übermittelt. Weitere Informationen findest Du in unserer <privacy>Datenschutzerklärung</privacy>.",
            accept: "Akzeptieren",
            back: "Zurück",
          },
        },
      },
      modal: {
        buttons: {
          close: {
            label: "Schließen",
          },
        },
        deleteConfirmationModal: {
          buttons: {
            cancel: "Abbrechen",
          },
        },
      },
      rating: {
        label: "Bewertung ist {value} von {total}",
      },
      supportButton: {
        help: "Unser Support hilft Dir",
      },
    },
    service: {
      steps: {
        appointment: {
          onlineAppointment: {
            title: "Abgabetermin auswählen",
            description:
              "Wähle Deinen passenden Termin um Dein Fahrzeug vor Ort abzugeben. Den Termin zur Abholung stimmt Deine ausgewählte Werkstatt mit Dir ab.",
          },
          offlineAppointment: {
            title: "Wunschtermin auswählen",
            description:
              "Wir versuchen Deinen Terminwunsch zu erfüllen. Unser Werkstattpartner wird sich bezüglich Deiner Anfrage zeitnah bei Dir melden.",
          },
          button: {
            calendar: "Kalender",
            list: "Liste",
          },
          loadMoreDays: "Mehr Tage anzeigen",
          loadLessDays: "Weniger Tage anzeigen",
          showMoreSlots: "Mehr Zeitfenster anzeigen",
          showLessSlots: "Weniger Zeitfenster anzeigen",
          pickup: "Abholung ab 16:40",
          // {date, date, ::EEEE} is currently not supported by Intl MessageFormat.
          // Therefore "fullDayName" is available in dateFormat:
          dateFormat: "{fullDayName}, {selectedDate, date, ::dd.MM.yyyy}",
          timeFormat: "{time, date, ::H:m}",
        },
        summary: {
          card: {
            buttons: {
              showDetails: "Details zeigen",
            },
          },
          cancellationInfo: {
            title: "Stornierungsoptionen",
            description:
              "100% Rückerstattung bei Stornierung bis 24 Std. vor Termin.",
          },
          inquiry: {
            title: "Meine Anfrage",
          },
          promoCode: {
            title: "Gutscheincode",
            collapsible: "Hast Du einen Gutschein?",
            label: "Aktions- oder Gutscheincode",
            placeholder: "Aktions- oder Gutscheincode",
            activated: "eingereicht",
            button: {
              submit: "Einlösen",
            },
            later:
              "Diesen kannst Du nach Erhalt Deines persönlichen Angebots einlösen.",
            afterAppointment:
              "Diesen kannst Du nach der Terminauswahl einlösen.",
            invalidOfferPeriod:
              "Bitte beachte, Dein gewählter Termin liegt außerhalb des Aktionszeitraums ({earliestValidAppointmentTime} - {latestValidAppointmentTime}). Für die Rabattierung wähle bitte einen Termin der innerhalb des Zeitraums liegt.",
            messages: {
              success: "Dein Aktionscode wurde erfolgreich erkannt.",
              code: {
                required: "Bitte geben Sie einen Code ein",
                other:
                  "Ein unbekannter Fehler ist aufgetreten, versuchen Sie es später erneut",
                incorrect: "Code nicht erkannt. Bitte überprüfe Deine Angabe.",
                alreadyUsed: "Code wurde bereits eingelöst",
                expired: "Code ungültig. Die Aktion ist bereits abgelaufen",
                invalidGarage:
                  "Es tut uns leid. Die Werkstatt nimmt nicht an dieser Aktion teil.",
                userDoesNotExist:
                  "Um diesen Code einzulösen, ist eine Anmeldung erforderlich. Bitte melde Dich an.",
                invalidServices:
                  "Die Voraussetzungen für diese Aktion sind nicht erfüllt. Bitte prüfe Deine Serviceauswahl.",
                periodExpired:
                  "Es tut uns leid. Der von Dir gewählte Servicetermin liegt außerhalb des Aktionszeitraumes.",
                toBeAppliedLater:
                  "Sie sind dabei ein Angebot für eine Inspektion anzufragen. Die Eingabe des Gutscheincodes erfolgt erst bei kostenpflichtiger Buchung Ihres Angebots.",
                invalidAppointmentTime:
                  "Dein gewählter Termin liegt nicht im Aktionszeitraum ({earliestValidAppointmentTime} - {latestValidAppointmentTime}). Bitte wähle einen anderen Termin.",
                licensePlateAlreadyUsed:
                  "Dieser Gutschein kann nur einmal pro Fahrzeug eingelöst werden.",
                // Only relevant for 24-euro christmas coupon because of the hardcoded services
                moreThanOneServiceInShoppingCart:
                  "Bei Anwendung dieses Gutschein-Codes kann nur ein Service pro Buchung ausgewählt werden. Aktionsfähige Services sind HU/AU, Ölwechsel, Klimainspektion, Klima-Desinfektion, Klima-Inspektion & Desinfektion. Einlösbar bis zum 24.12. 2023 für Termine bis zum 29.02.2024.",
                invalidAdditionalServices:
                  "Die Voraussetzungen für diese Aktion sind nicht erfüllt. Bitte prüfe Deine Zusatzservices.",
                emailNotAssociated:
                  "Dieser Code ist nicht für die angegebene E-Mail Adresse gültig. Bitte überprüfe Deine Angabe.",
              },
              extraErrorInformation: {
                backToAppointmentPage: "Zurück zur Terminauswahl",
              },
            },
          },
          onDemand: {
            time: "Termin auf Anfrage",
            priceAndTime: "Preis und Termin auf Anfrage",
            price:
              "Unser Werkstattpartner wird Dir für Deine Anfrage ein individuelles Angebot zukommen lassen.",
          },
          inspectionInfo: {
            title: "Hinweise zur Anfrage einer Inspektion:",
            description:
              "Unser Werkstattpartner wird Dir für Deine Inspektion ein individuelles Angebot zukommen lassen.",
          },
          appointmentSelection: {
            title: {
              online: "Abgabetermin",
              offline: "Terminanfrage",
            },
            appointment: {
              title: {
                offline: "Anfragedetails",
                online: "Buchungsdetails",
              },
              offline: "Wunschtermin",
              online: "Abgabetermin",
              express: "Expresstermin",
              dateFormat: "{fullDayName}, {selectedDate, date, ::dd.MM.yyyy}",
              timeFormat: "um {time, date, ::H:m} Uhr",
              description: {
                dropOff:
                  "Die Durchführung des Services beginnt eventuell nicht direkt nach der Abgabe. Den Termin zur Abholung stimmt die Werkstatt mit Dir ab.",
                express:
                  "Die Durchführung des Services beginnt direkt nach der Abgabe und Dein Fahrzeug ist im Anschluss abholbereit.",
              },
            },
            car: {
              hsn: "HSN",
              tsn: "TSN",
            },
          },
          vehicle: {
            headline: "Fahrzeug",
            title: "Ihr Fahrzeug",
            hsn: "HSN",
            tsn: "TSN",
          },
          replacementCar: {
            title: "Ersatzwagen",
            description:
              "Soweit verfügbar, kannst Du einen kostenlosen Ersatzwagen für 24 Stunden inkl. 100 Freikilometern anfordern. Über das Wochenende wird Ersatzwagengebühr in Höhe von 29€ erhoben. Wenn Du mehr Kilometer brauchst werden zusätzliche Gebühren direkt mit unserem Service-Partner abgerechnet.",
            cannotCombineWithExpressAppointment:
              "nicht kombinierbar mit Expresstermin",
          },
          expressAppointment: {
            cannotCombineWithReplacementCar:
              "nicht kombinierbar mit Ersatzwagen",
          },
          garage: "Werkstatt",
          title: "Deine Übersicht",
          offer: "Unser Angebot",
          twoSteps: "2 Schritte bis zum genauen Preis",
          notification: "Dein Termin wurde für 5 Minuten reserviert.",
          cost: "Servicekosten",
          free: "kostenlos",
          included: "inklusive",
          total: {
            tax: "inkl. 19% Mwst.",
            serviceTotal: "Services Gesamtpreis",
            discounted: 'Gutschein <coupon>"Geschenk24"</coupon>',
            totalPrice: "Gesamtsumme",
          },
          actionButtons: {
            edit: "ändern",
            delete: "löschen",
          },
          additionalServices: {
            title: "Passende Zusatzleistungen für",
            wholeBooking: "gesamte Buchung",
          },
        },
        checkout: {
          error: {
            failed: "Die Terminreservierung ist fehlgeschlagen.",
          },
          contact: {
            title: "Buchung abschließen",
            fields: {
              name: "Name",
              email: "E-Mail",
              phoneNumber: "Tel-Nr.",
              terms:
                "Ich habe die <terms>AGB</terms> gelesen und erkläre mich einverstanden.",
            },
            validations: {
              name: {
                invalid: "Dein Name sollte mindestens 2 Zeichen enthalten.",
                required: "Wir benötigen Deinen vollständigen Namen.",
              },
              email: {
                invalid: "Die eingegebene E-Mail Adresse ist nicht gültig.",
                required: "Wir benötigen Deine E-Mail Adresse.",
              },
              phoneNumber: {
                invalid: "Die eingegebene Telefonnummer ist nicht gültig.",
                required: "Wir benötigen Deine Telefonnummer.",
              },
              terms: {
                required: "Du musst die AGB lesen und akzeptieren.",
              },
            },
          },
          cutomerInfo: {
            title: "Deine Kontaktdaten",
            buttons: {
              submit: "Änderungen übernehmen",
              cancel: "Abbrechen",
            },
          },
        },
        garages: {
          title: "Werkstatt auswählen",
          loading: "Finde verfügbare Werkstätten...",
          location: "Ort",
          loadMore: "Weitere Werkstätten anzeigen",
          locationInput: {
            noRecordsFound: "Keine Aufzeichnungen gefunden",
            label: "Ortseingabe",
          },
          emptyGarage: {
            title: "Werkstatt suchen",
            initialState:
              "Gib Deine Straße, Stadt oder PLZ ein, um Werkstätten in der Nähe zu finden.",
            noGaragesFound:
              "Leider sind keine Werkstätten für Deine eingegebene Adresse verfügbar.",
            resetFilters: "Filter zurücksetzen",
          },
          locationModal: {
            heading: "Werkstatt Infos",
            title: "Standort wählen",
            input: "Ortseingabe",
            button: "Ändern",
          },
          garageCard: {
            earliestAppointment: "Nächster Termin",
            today: "Heute",
            reviews: "Rezensionen",
            online: "Online Vorkasse",
            offline: "Bezahlung vor Ort",
            price: "Preis",
            garageInfo: "Fahrzeug nötig",
            onRequest: "auf Anfrage",
            tomorrow: "Morgen",
            details: "Details zeigen",
          },
          garagesSortToggle: {
            rating: "Bewertung",
            pricing: "Preis",
            distance: "Entfernung",
          },
          garagesFilterToggle: {
            ALL: "Alle",
            PWS: "Anfrage",
            PITSTOP: "Buchung",
            tooltip: {
              pws: "Für Deine Service-Auswahl steht die Individuelle Anfrage derzeit noch nicht zur Verfügung.",
              pitstop:
                "Für Deine Service-Auswahl steht die Online Buchung derzeit noch nicht zur Verfügung.",
            },
          },
          workshopDetails: {
            title: "Werkstatt Infos",
            totalRating: {
              reviews: "Bewertungen",
              copyright: "Verantwortlich für alle Inhalte ist Google Inc.",
            },
            openingHours: {
              title: "Öffnungszeiten",
              MONDAY: "Montag",
              TUESDAY: "Dienstag",
              WEDNESDAY: "Mittwoch",
              THURSDAY: "Donnerstag",
              FRIDAY: "Freitag",
              SATURDAY: "Samstag",
              SUNDAY: "Sonntag",
              closed: "Geschlossen",
              openOnRequest: "Nach Vereinbarung",
            },
            reviews: {
              yesterday: "Gestern",
              today: "Heute",
            },
          },
          fields: {
            location: "Ortseingabe",
          },
          appointment: {
            modal: {
              title: "Ausgewählte Werkstatt",
              garageTile: {
                details: "Details zeigen",
              },
              tiresStored: {
                pitstopDescription:
                  "Sind Deine Reifen bereits bei einer <garage>pitstop</garage> Werkstatt eingelagert?",
                pwsDescription:
                  "Sind Deine Reifen bereits bei <garage>dieser Partnerwerkstatt</garage> eingelagert?",
                yes: "Ja",
                no: "Nein",
              },
              button: "Weiter im Bestellprozess",
            },
          },
        },
        order: {
          title: "Deine Bestellung",
        },
      },
    },
    vehicle: {
      errors: {
        invalid: "Ungültige Fahrzeuginformationen",
      },
    },
  },
  helpers: {
    autoservice: {
      error: {
        modal: {
          title: "Es ist ein technischer Fehler aufgetreten.",
          content: {
            paragraphOne:
              "Leider können wir Deine Anfrage zurzeit nicht bearbeiten. Bitte versuche es später erneut.",
            paragraphTwo: "Gerne steht Dir auch unser Support zur Verfügung.",
          },
          button: {
            contact: "Support kontaktieren",
            homepage: "Zurück zur Startseite",
          },
        },
        timeout: {
          title: "Verbindungsproblem",
          content:
            "Es scheint ein Problem mit Deiner Internetverbindung zu geben. Bitte prüfe die Verbindung und versuche es noch einmal.",
          retry: "Erneut versuchen",
          cancel: "Abbrechen",
        },
        location: {
          title: "Karte und Standort",
          content:
            "Damit dies funktioniert, müssen wir auf Deinen Standort zugreifen",
          button: "Versuche es nochmal",
        },
        fallback: {
          content:
            "Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.",
        },
      },
    },
  },
  pages: {
    customerCard: {
      title: "Wie möchtest Du fortfahren?",
      loginButton: "Mit meinem HUK-Autoservice Konto",
      registerButton: "Ich will mich registrieren",
    },
    referFriends: {
      title: "Wie möchtest Du fortfahren?",
      recommendButton: "Jetzt empfehlen",
      registerButton: "Ich will mich registrieren",
      backButton: "Zurück",
      linkInvitee: {
        successNotification:
          "Danke für Deine Registrierung. Wir haben den Gutschein an Deine E-Mail Adresse verschickt.",
      },
      card: {
        title: "Freunde werben Freunde",
        description:
          "Lade Deine Freunde ein um einen 10% Gutschein zu erhalten",
        seeDetails: "Details anzeigen",
        activateNow: "Jetzt aktivieren",
      },
      page: {
        steps: [
          {
            title: "Kopiere den Link",
            description:
              "und versende Deinen persönlichen Link an so viele Freunde, wie Du möchtest",
          },
          {
            title: "Erhalte einen 10% Gutschein",
            description:
              "für jeden Freund, der eine Buchung bei uns abschließt",
          },
        ],
        details:
          "Nähere Informationen zum Freunde werben Freunde Programm findest Du <link>hier</link>.",
        label: "Dein persönlicher Link",
        termsAndConditions:
          "Teilnahmebedingungen findest Du <link>hier.</link>",
        copyLink: "Link kopieren",
        copySuccess: "Link wurde erfolgreich kopiert.",
      },
      notActivatedModal: {
        title: "Noch nicht aktiviert?",
        description:
          "Dieser Bereich wird nach Abschluss einer Buchung bei Pitstop für Dich freigeschaltet.",
        bookService: "Zur Service-Übersicht",
        back: "Zurück",
      },
      tag: {
        activated: "aktiviert",
        deactivated: "noch nicht aktiviert",
      },
    },
    home: {
      offerNotificationText:
        "Es liegt ein Angebot für Deine Inspektions-Anfrage vor. Du hast noch {daysLeft} Zeit, um das Angebot anzunehmen.",
      offerLink: "<link>Zum Angebot</link>",
      oneDay: "einen Tag",
      days: "Tage",
    },
    services: {
      flow: {
        services: {
          title: "Service auswählen",
          headerTitle: "Service",
          price: "ab {price}",
          free: "KOSTENLOS",
          priceAndTimeOnDemand: "Preis und Termin auf Anfrage",
          huAuAsterisk: "*Durch amtlich anerkannte Prüforganisation",
          availableSoon: "Bald verfügbar",
          categories: {
            maintenance: "Wartung und Instandhaltung",
            repair: "Reparaturservices",
          },
          supplementary: "Nichts passendes gefunden?",
          bookingTimelineTitle: "Unterschied zwischen Buchungen & Anfragen",
          inquiry: {
            title: "Andere Anfrage?",
            description:
              "Vermisst Du hier etwas? Kein Problem: Schick uns einfach eine <bold>individuelle Anfrage</bold>, um einen Service anzufordern, der hier nicht aufgeführt ist.",
            button: {
              add: "Anfrage hinzufügen",
              editOrDelete: "Anfrage ändern/löschen",
            },
            modal: {
              title: "Deine Anfrage",
              text1:
                "<bold>Individuelle Anfragen</bold> wirken sich auf die Werkstattauswahl aus.",
              text2:
                "Nicht jede Werkstatt (z.B. pitstop) kann diese Anfragen bearbeiten.",
            },
            form: {
              button: {
                confirm: "Bestätigen",
                delete: "Anfrage löschen",
                cancel: "Abbrechen",
              },
              subject: {
                placeHolder: "Bitte auswählen",
                one: "Service-Anfrage",
                two: "Zusätzliche Angaben zur Buchung",
              },
              message: {
                title: "Nachricht (Max. 500 Zeichen)",
                placeHolder: "Gebe hier Deine Nachricht ein",
              },
              validations: {
                subject: {
                  required: "Bitte wähle den Betreff aus",
                },
                message: {
                  required: "Bitte gib Deine Nachricht ein",
                  maxLength: "Die Nachricht sollte nur 500 Zeichen lang sein",
                },
              },
            },
            notification: {
              title: "Anfrage wirklich löschen?",
              content:
                "Mit dem Löschen Deiner Anfrage wird der Buchungsprozess zurück gesetzt und Deine bisherigen Angaben gelöscht.",
              buttons: {
                continue: "Anfrage löschen",
                back: "Zurück",
              },
            },
          },
          serviceWizard: {
            title: "Nicht sicher, welchen Autoservice Du brauchst?",
            description: "Wir helfen Dir mit unserem Service Finder.",
            button: "Zum Servicefinder",
          },
          inspection: {
            info: {
              title: "Hinweise zur Anfrage einer Inspektion",
              description:
                "Unser Servicepartner wird Dir für Deine Inspektion ein individuelles Angebot zukommen lassen.",
            },
            confirmationModal: {
              title: "Hinweis zur Inspektion",
              desc1:
                "Die Inspektion kann aktuell noch nicht in Kombination mit weiteren Services gebucht werden.",
              desc2:
                "Um Dir Dein exklusives Angebot für die Inspektion kalkulieren zu lassen, klicke Bestätigen.",
              desc3:
                "Wenn Du weitere Services buchen möchtest, kannst Du dies gerne separat machen.",
              question: "Möchtest Du eine Inspektion anfragen?",
              buttons: {
                confirm: "Bestätigen",
                cancel: "Abbrechen",
              },
            },
          },
          tireStorageModal: {
            title: "Reifeneinlagerung",
            pitstopDescription:
              "Sind Deine Reifen bereits bei einer <garage>pitstop</garage> Werkstatt eingelagert?",
            pwsDescription:
              "Sind Deine Reifen bereits bei <garage>dieser Partnerwerkstatt</garage> eingelagert?",
            yes: "Ja",
            no: "Nein",
          },
        },
        service: {
          cofiguratorTitle: "Produktkonfigurator",
          categoryCard: {
            huau: {
              title: "HU*/AU",
              footnote:
                "* Durchführung durch amtlich anerkannte Prüforganisation.",
              smokeBlowTest: {
                name: "HU für Elektrofahrzeuge",
                title: "HU für Elektrofahrzeuge - Bald verfügbar!",
                options:
                  "Wir arbeiten daran, Dir diesen neuen Service anzubieten. In der Zwischenzeit hast Du zwei Möglichkeiten:",
                option1:
                  "<bold>1.</bold> Buche eine reguläre HU/AU bei unserem Partner Pitstop. Die Preisdifferenz erstattet Dir unser Partner nach Abschluss des Services vor Ort.",
                option2:
                  "<bold>2.</bold> Frage individuell nach einer HU für Dein Elektrofahrzeug bei einem unserer anderen Partner an.",
                inform:
                  "Wir informieren Dich, sobald unser spezieller E-Auto-Service verfügbar ist. Danke für Deine Geduld!",
                back: "Zurück",
              },
            },
            inspection: {
              additionalInfo: {
                title: "Individuelles Inspektionsangebot",
                description:
                  "Um Dir den besten Preis anbieten zu können, berechnet unser Serviceparter Dein Angebot individuell auf Deine Bedürfnisse zugeschnitten. Hierzu wird Deine Anfrage von Experten bewertet, welche sich zeitnah bei Dir zurückmelden werden.",
                additional:
                  "Wenn Du weitere Services buchen möchtest, kannst Du dies gerne separat machen.",
                timeline: {
                  step1:
                    "Du trägst Deine Fahrzeugdaten ein und sendest die Anfrage",
                  step2:
                    "Unser Servicepartner prüft Deine Angaben und lässt Dir zeitnah ein Angebot zukommen",
                  step3:
                    "Du kannst Dich innerhalb von 7 Werktagen entscheiden das Angebot anzunehmen und einen Wunschtermin für die Abgabe festlegen.",
                },
              },
            },
            shockAbsorber: {
              title: "Wähle Deinen Stoßdämpferservice",
              selectArea: "Bereich wählen",
              toggleItems: {
                complete: "Komplett",
                front: "Vorne",
                back: "Hinten",
              },
            },
            brakesChange: {
              categoryTitle: "Bremsenservice",
              title: "Wähle Deinen Bremsenservice",
              subtitle: "Service wählen",
              categories: {
                discAndPads: "Bremsscheiben & -beläge",
                onlyPads: "Nur Bremsbeläge",
              },
              selectArea: "Bereich wählen",
              services: {
                complete: "Komplett",
                front: "Vorne",
                back: "Hinten",
              },
              infoBox: {
                title: "Bremsscheiben und Bremsbeläge",
                description:
                  "Mit den Bremsscheiben müssen auch die Beläge getauscht werden, da sie sich an die Scheibe anpassen müssen.",
              },
            },
            diagnose: {
              title: "Irgendwas stimmt nicht",
              infoBox: {
                title: "Leistungen des Diagnosetermins",
                description:
                  "Unser Servicepartner erwartet Dich vor Ort und sichtet gemeinsam mit Dir Dein Fahrzeug.",
                included: {
                  title: "Enthaltene Leistungen",
                  items: {
                    one: "Fixer Abgabetermin mit unserem Werkstattpartner",
                    two: "Gemeinsame Sichtung des Fahrzeugs",
                    three:
                      "Ausführliche Beratung zu empfohlenen Serviceleistungen",
                    four: "Unverbindliches Serviceangebot",
                  },
                },
                excluded: {
                  title: "Nicht enthalten",
                  items: {
                    one: "Durchführung von Autoservices",
                  },
                },
              },
              benefits: {
                title: "Faire Beratung zu transparenten Preisen!",
                benefit1: "Faire Beratungskonditionen",
                benefit2: "Transparente Preise",
                benefit3: "Zahl nur, was Du benötigst!",
              },
            },
            airConditioning: {
              climaFluid: {
                title: "Baujahr Deines Fahrzeugs",
                description:
                  "Um die richtige Klimaflüssigkeit für Dein Auto zu bestimmen, benötigen wir die Information, ob Dein Fahrzeug vor 2017 oder danach gebaut wurde. Bitte beachte: wir benötigen das Baujahr Deines Fahrzeugs, nicht das Datum der Erstzulassung!",
                before2017: "Bis 2017",
                after2017: "Ab 2017",
                error: "Bitte gib Deine Baujahr Deines Fahrzeugs ein.",
              },
            },
            servicesTitle: "Servicevariante wählen",
            recommended: "Empfohlen",
            action: "Aktion",
            categoryAdditionals: {
              titles: {
                single: "Passende Zusatzleistung für",
                multiple: "Passende Zusatzleistungen für",
                wholeBooking: "gesamte Buchung",
              },
            },
            category: {
              replacementCar: {
                description: {
                  pws: "Wir prüfen für Dich, ob die Werkstatt einen Ersatzwagen bereitstellen kann. Die Mietbedingungen werden mit unseren Service-Partner vereinbart.",
                  pitstop:
                    "Soweit verfügbar, kannst Du einen Ersatzwagen inkl. 100 Freikilometern anfordern. Zusätzliche Gebühren für mehr Kilometer oder zusätzliche Tage können direkt bei unserem Service-Partner abgerechnet werden. Wochenend-Tarif (Sa-Mo) 39,00 €.",
                  common:
                    "Soweit verfügbar, kannst Du einen Ersatzwagen anfordern. Genauere Informationen zu den Mietbedingungen kannst Du nach Auswahl einer Garage hier einsehen.",
                  inspectionRequest:
                    "Wir prüfen für Dich, ob die Werkstatt einen Ersatzwagen bereitstellen kann und ob hierfür eventuelle Zusatzkosten anfallen. Du wirst über unser erstelltes Angebot hierzu informiert und kannst dann bequem entscheiden, ob Du einen Ersatzwagen in Anspruch nehmen möchtest.",
                },
              },
            },
          },
          navigation: {
            mobileTitle: "Serviceauswahl",
          },
          buttons: {
            back: "Zurück",
            confirmSelection: "<service>Service</service> hinzufügen",
            confirmChanges: "Änderungen übernehmen",
            deleteSelection: "Serviceauswahl löschen",
          },
          readMore: "Weiterlesen",
          readLess: "Text reduzieren",
        },
        garages: {
          title: "Deine Werkstatt",
          headerTitle: "Werkstatt",
          inspectionModal: {
            title: "Hinweis zur Serviceauswahl",
            description:
              "Die Inspektion kann bei diesem Service-Partner, aufgrund der individuellen Angebotserstellung, aktuell noch nicht in Kombination mit weiteren Services gebucht werden.\nWenn Du weitere Services buchen möchtest, kannst Du dies gerne separat machen oder eine andere Werkstatt auswählen.",
            heading: "Wie möchtest Du fortfahren?",
            withInspection: "Nur die Inspektion anfragen",
            withoutInspection: "Ohne die Inspektion fortfahren",
            confirm: "Weiter",
            back: "Zurück zur Werkstattsuche",
          },
        },
        vehicle: {
          title: "Fahrzeugdaten hinterlegen",
          headerTitle: "Fahrzeug",
          signInDetails: {
            description:
              "Melde dich an, um sie automatisch ausfüllen zu lassen",
            title: "Sind Deine Fahrzeugdaten hinterlegt?",
            buttonText: "Anmelden",
          },
          vehicleCreationForm: {
            button: "Fahrzeugdaten speichern",
            success: "Fahrzeugdaten erfolgreich aktualisiert",
            error: "Speichern Fahrzeug ist fehlgeschlagen",
          },
          updateOrCreateModal: {
            title: "Änderungen entdeckt",
            text: "Du bist gerade dabei gespeicherte Fahrzeugdaten zu überschreiben.",
            subtitle: "Möchtest Du die Fahrzeugdaten überschreiben?",
            update: "Fahrzeugdaten überschreiben",
            create: "Als neues Fahrzeug speichern",
            continue: "Weiter",
            back: "Zurück",
          },
        },
        inspection: {
          header: {
            title: "Hallo",
            description:
              "für Deine Anfrage zur Inspektion können wir Dir folgendes Angebot unterbreiten. Im Folgenden findest Du die Kostenaufstellung basierend auf Deinen Fahrzeugdaten sowie Informationen zu unserem Service-Partner.",
            chooseDate:
              "Wenn Dir das Angbot zusagt, kannst Du gleich Deinen Abgabetermin auswählen und unkompliziert zum Festpreis online bezahlen.",
            note: "Bitte beachte:",
            offer: "Dein persönliches Angebot ist nur",
            valid: "gültig bis zum",
            dateFormat: "{dateTo, date, ::dd.MM.yyyy}",
          },
          vehicle: "Fahrzeug",
          expired: {
            title: "Dein Inspektionsangebot ist leider abgelaufen",
            content: [
              "Es tut uns leid, aber das ausgewählte Angebot ist inzwischen abgelaufen und kann nicht mehr in Anspruch genommen werden.",
              "Wir bemühen uns, Dir attraktive Sonderkonditionen anzubieten - diese sind jedoch nur für einen begrenzten Zeitraum gültig.",
              "Du kannst uns gerne eine neue Anfrage schicken - unser Partner wird sich zeitnah um ein neues Angebot für Dich kümmern.",
            ],
            links: {
              rebook: "Neues Angebot anfragen",
              back: "Zurück zur Startseite",
            },
          },
        },
        appointment: {
          title: "Dein Werkstatt-Abgabetermin",
          headerPwsTitle: "Abgabetermin",
          headerPitstopTitle: "Abgabetermin",
        },
        submit: "Jetzt Termin vereinbaren",
        buttons: {
          submit: {
            default: "Weiter",
            services: "Weiter zu Services",
            vehicle: "Weiter zu Fahrzeugdaten",
            garage: "Weiter zur Werkstatt",
            appointment: "Weiter zum Abgabetermin",
            appointmentRequest: "Weiter zum Wunschtermin",
            checkout: "Weiter zur Übersicht",
            complete: {
              payment: {
                withCustomerData: "Weiter zur Zahlung",
                withoutCustomerData: "Weiter zur Anmeldung",
              },
              request: {
                withCustomerData: "Anfrage senden",
                withoutCustomerData: "Weiter zur Anmeldung",
              },
              selectAppointment: "Termin wählen",
            },
          },
          back: {
            default: "Zurück",
            services: "Weitere Services aussuchen",
            vehicle: "Zurück zu den Fahrzeugdaten",
            garage: "Zurück zur Werkstattauswahl",
          },
        },
        payment: {
          title: "Zahlung",
        },
      },
      service: {
        confirmModal: {
          expressAppointment: {
            title: "Hinweis zur Serviceauswahl",
            description:
              "Der Expresstermin kann nur nur als Ergänzung zum Radwechsel gebucht werden, da nur hier eine zeitnahe Durchführung garantiert werden kann. Wenn Du weitere Services buchen möchtest, kannst Du dies gerne separat machen.",
            question: "Wie möchtest Du fortfahren?",
            option1: "Nur den Radwechsel als Expresstermin buchen.",
            option2: "Ohne Expresstermin fortfahren.",
            buttons: {
              confirm: "Weiter",
              cancel: "Abbrechen",
            },
          },
        },
      },
      bookingTimeline: {
        bookingProcess: {
          title: {
            online: "1. Ablauf der Buchung",
            offline: "1. Ablauf der Anfrage",
            sfk: "1. Ablauf der Buchung",
          },
          steps: [
            "Service auswählen",
            "Werkstatt auswählen",
            {
              offline: "Anfrage senden",
              online: "Abgabetermin auswählen",
              sfk: "Abgabetermin auswählen",
            },
            {
              online: "Zum Festpreis online buchen",
              sfk: "Abgabetermin verbindlich buchen",
            },
          ],
        },
        appointmentProcess: {
          title: {
            servicesPage: "2. Ablauf des Termins",
            online: "Nächste Schritte nach Deiner Buchung",
            offline: "Nächste Schritte nach Deiner Anfrage",
            inspection: "Nächste Schritte nach Deiner Inspektionsanfrage",
          },
          steps: {
            step1: {
              psInspection: "Angebot erhalten & prüfen",
              pws: "Kontaktaufnahme mit Werkstatt",
            },
            step2: "Auto zur Werkstatt bringen",
            step3: "Service wird durchgeführt",
            step4: "Auto abholen",
            step5: "Bezahlung vor Ort",
          },
        },
      },
      checkout: {
        headerTitle: "Buchung",
        requestTitle: "Anfrage",
        back: "Zurück",
        or: "oder",
        title: {
          request: "Anfrage abschließen",
          booking: "Buchung abschließen",
        },
        login: {
          buttons: {
            login: "Mit meinem HUK-Autoservice Konto",
            register: "Ich will mich registrieren",
            guest: "Ich will als Gast buchen",
          },
          modal: {
            step1: {
              modalTitle: "Deine Daten",
              title: "Wie möchtest Du Deinen Service buchen?",
              benefits: {
                title: "Deine Vorteile als registrierter Kunde",
                benefits: [
                  "Sichere Dir 10% Rabatt auf Deine Autoservice Buchung",
                  "Behalte Deine Buchungen immer im Blick",
                  "Einfache Verwaltung Deiner Fahrzeugdaten für noch schneller Buchungen",
                  "Bleib auf dem Laufenden mit der HUK Autowelt Vorteilskarte",
                  "Verpasse keine Aktion und spare bares Geld",
                ],
              },
            },
            step2: {
              guest: {
                buttonTitle: "Weiter zur Bestellübersicht",
                modalTitle: "Gastbestellung",
                title: "Deine Bestelldaten",
              },
            },
          },
        },
        form: {
          buttons: {
            request: "Anfrage senden",
            booking: "Kostenpflichtig buchen",
            registerAndRequest: "Registrieren und Anfrage senden",
            registerAndBook: "Registrieren und Kostenpflichtig buchen",
          },
        },
        marketingConsent: {
          content: {
            checkout:
              "Ich möchte die kostenlosen <nlLink>HUK-Autowelt News</nlLink> abonnieren. Eine Abmeldung ist jederzeit möglich.",
            customerCard:
              "Ich möchte die kostenlose <ccLink>Vorteilskarte</ccLink> und die kostenlosen <nlLink>HUK-Autowelt News</nlLink> abonnieren. Eine Abmeldung ist jederzeit möglich.",
          },
          modal: {
            title: {
              newsLetter: "Hinweis zum Newsletter-Abonnement",
              customerCard: "HUK-Autowelt Vorteilskarte",
            },
            content:
              "Ich bin damit einverstanden, dass mich die HUK-Autoservice GmbH und die HUK-Autowelt GmbH per elektronischer Post (z.B. E-Mail, Push-Nachrichten, SMS) über weitere Angebote und Produkte informiert und zur Marktforschung kontaktiert. Die Gesellschaften der Unternehmensgruppe sowie die Produkte finden Sie unter den weiteren Details. Diese Einwilligung ist freiwillig und kann jederzeit formlos widerrufen werden.",
            customerCardContent: {
              desc1:
                "Deine Vorteilskarte wird vom Anbieter Passcreator bereitgestellt. Dabei werden folgende Daten verarbeitet:",
              list: [
                "Name und Kontaktinformationen",
                "Kundenkartennummer und HAG-Kundennummer",
                "Fahrzeugdaten des ausgewählten Fahrzeugs",
                "Nutzungsdaten der Kundenkarte",
              ],
              desc2:
                "Diese Daten werden ausschließlich zum Zweck der Bereitstellung und Verwaltung Deiner Vorteilskarte erhoben, gespeichert und verarbeitet.",
              linkDesc: "Datenschutzhinweise des Anbieters:",
              linkText: "passcreator/privary-policy",
            },
            back: "Zurück",
          },
        },
        terms:
          "Mit Deiner Bestellung stimmst Du unseren <terms>AGB</terms> sowie unserer <privacy>Datenschutzerklärung</privacy> zu.",
        errors: {
          appointmentNotAvailable:
            "Dein gewählter Termin ist nicht mehr verfügbar. Bitte wähle einen Neuen.",
          serviceNotAvailable: {
            message:
              "Die ausgewählte Werkstatt kann den Service für Dein Fahrzeug leider nicht anbieten. Bitte wähle eine andere Werkstatt aus.",
          },
          additionalServiceNotAvailable: {
            message:
              "Die ausgewählte Werkstatt kann die Zusatzleistung für Dein Fahrzeug leider nicht anbieten. Bitte wähle eine andere Werkstatt aus oder lösche die ausgewählte Zusatzleistung.",
          },
          paymentError:
            "Bei Ihrer Zahlung ist ein Problem aufgetreten. Bitte wenden Sie sich an den Kundendienst",
        },
        buttons: {
          appointmentConfirm: "Weiter zur Übersicht",
        },
      },
      completed: {
        requestTitle: "Anfrage gesendet!",
        bookingTitle: "Buchung erfolgreich!",
        requestSubTitle:
          "Unser Service-Partner wird sich in Kürze bei Dir melden.",
        bookingSubTitle: "Eine Bestätigungsemail wurde verschickt!",
        inspectionSubTitle:
          "Vielen Dank! Deine Anfrage wurde an unser Service-Team weitergeleitet. In Kürze erhältst Du einen Link zu Deinem individuellen Angebot per E-Mail, um die Service-Buchung online abzuschließen.",
        service: "Zusammenfassung",
        buttonAccount: "Zu meinem Account",
        buttonGuest: "Zu meiner Homepage",
        next: "Wie geht es weiter?",
        inquiry: {
          button: "Zusätzliche Angaben zur Buchung",
          modal: {
            title: "Individuelle Anfrage",
            subject: "Betreff",
            message: "Deine Nachricht",
            continue: "Schließen",
          },
          deleteConfirmation: {
            title: "Anfrage wirklich löschen?",
            content:
              "Bist Du sicher, dass Du die Individuelle Anfrage löschen möchtest?",
            confirm: "Anfrage löschen",
          },
        },
        bookingId: "Buchungs-ID",
        phone: "Tel.",
        steps: {
          default: {
            step1: {
              title: "Buchung abgeschlossen",
            },
            step2: {
              title: "Terminabsprache",
              description:
                "Unser Werkstattpartner meldet sich mit einem Preisangebot bei Dir und ihr vereinbart Deinen Wunschtermin.",
            },
            step3: {
              title: "Du bringst Dein Fahrzeug vorbei",
              description:
                "Bring Dein Auto zu uns in die Werkstatt, ein Berater bespricht mit Dir die nächsten Schritte.",
            },
            step4: {
              title: "Wir führen die Arbeit durch",
              description:
                "Wenn unsere Techniker vor Ort mit den Serviceleistungen fertig sind, melden sie sich umgehend bei Dir.",
            },
            step5: {
              title: "Du holst Dein Fahrzeug ab",
              description:
                "Innerhalb der Öffnungszeiten kannst Du Dein Auto wieder abholen.",
            },
          },
          inspection: {
            step1: {
              title: "Anfrage gesendet",
            },
            step2: {
              title: "Angebot erhalten",
              description:
                "Unser Werkstattpartner meldet sich mit einem Preisangebot bei Dir. Du wählst Deinen Wunschtermin und kannst zum Festpreis online buchen.",
            },
            step3: {
              title: "Du bringst Dein Fahrzeug vorbei",
              description:
                "Bring Dein Auto zu uns in die  Werkstatt, ein Berater bespricht mit Dir die nächsten Schritte.",
            },
            step4: {
              title: "Wir führen die Arbeit durch",
              description:
                "Wenn unsere Techniker vor Ort mit den Serviceleistungen fertig sind, melden sie sich umgehend bei Dir.",
            },
            step5: {
              title: "Du holst Dein Fahrzeug ab",
              description:
                "Innerhalb der Öffnungszeiten kannst Du Dein Auto wieder abholen.",
            },
          },
        },
        customerCard: {
          title: "Deine Vorteile mit der HUK-Autowelt Vorteilskarte",
          benefits: [
            "Willkommensbonus: Spare 10€ bei Deiner nächsten Buchung",
            "Early Access: Erfahre als erster von limitierten Aktionen und Angeboten",
            "Maximal komfortabel: Persönliche Reminder & vereinfachte Buchung für beliebte Services",
          ],
          terms:
            "Ja, ich will gut und aktuell informiert sein! Ich bin damit einverstanden, dass mich die HUK-Autoservice GmbH und die HUK-Autowelt GmbH per elektronischer Post (z.B. E-Mail, Push-Nachrichten, SMS) über weitere Angebote und Produkte informiert und zur Marktforschung kontaktiert. Die Gesellschaften der Unternehmensgruppe sowie die Produkte finden Sie unter den weiteren Details. Diese Einwilligung ist freiwillig und kann jederzeit formlos widerrufen werden.",
          button: "Jetzt Karte anfordern",
        },
      },
    },
    serviceWizard: {
      goBack: "zurück",
      header: {
        headline: "Willkommen im Service-Finder",
        searchRequestText: "Nenne uns Dein Anliegen!",
        searchInputPlaceholder: "Service, Fahrzeugbereich oder Symptom",
      },
      helpText: "Du findest nicht,  wonach Du suchst? Wir helfen Dir",
      result: {
        noResult: {
          title: "0 Ergebnisse",
          description:
            "Leider haben wir keinen passenden Eintrag auf Deine Eingabe gefunden.",
          reasons: {
            one: "Deine Eingabe ist nicht korrekt geschrieben",
            two: "Dieses Wort gibt es nicht",
            three: "Anfrage ist nicht präzise",
          },
        },
        optionsCard: {
          title: "Nicht fündig geworden?",
          boldText: "Lass Dein Fahrzeug jetzt durchchecken.",
          description: "Folgende Optionen stehen Dir zu Verfügung:",
          options: {
            one: "Stelle eine detaillierte Anfrage an eine unserer Servicepartner.",
            two: "Buche einen Diagnosetermin bei unseren pitstop Werkstätten und lass Dein Auto durchchecken",
            three:
              "Kontaktiere unsere Support und lass Dich von uns weiter beraten.",
          },
          actions: {
            title: "Jetzt handeln",
            request: "Anfrage stellen",
            diagnose: "Diagnosetermin buchen",
          },
        },
        contact: {
          notFound: "Nicht fündig geworden?",
        },
      },
    },
    account: {
      index: {
        title: "Benutzerkonto",
        details: {
          title: "Guten Tag",
        },
        recommendation: {
          title: "Unsere Empfehlung",
          description:
            "Da wir noch Deine Daten von der letzten Buchung haben, kannst Du ganz bequem in wenigen Schritten erneut buchen.",
        },
        bookings: {
          title: "Aktuelle Termine",
          empty: "Du hast aktuell keine Termine.",
          card: {
            dateFormat: "{fullDayName}, {selectedDate, date, ::dd.MM.yyyy}",
            timeFormat: "{time, date, ::H:m} Uhr",
            status: {
              cancelled: "Storniert",
              customerActionNeeded: "Bitte prüfe Deine E-Mails",
            },
            pickup: "Abholen heute",
            inquiry: "Meine Anfrage",
            offerAvailable: "Angebot erhalten",
            paymentPending: "Zahlung ausstehend",
            moreInfoNeeded: "Werkstatt benötigt weitere Infos",
            cancelled: "Storniert",
            cancellationRequested: "Stornierung beantragt",
            validUntil: "noch {daysLeft} gültig",
            expiresToday: "nur noch heute gültig",
            noOffer: "Du erhältst in Kürze ein Angebot",
            day: "Tag",
            days: "Tage",
            recommendationText: "Termin wird im nächsten Schritt ausgewählt",
            tag: "NEU",
            detailsLink: "Details zeigen",
            offerLink: "Angebot prüfen",
            checkoutLink: "Zur Buchung",
            bookAgainLink: "Erneut Buchen",
          },
          inspection: {
            inspectionCard: {
              title: "Angebot erhalten",
              description:
                "Prüfe nun Dein individuelles Angebot für Deine Inspektion.\nDas Angebot ist noch bis zum {date} gültig.",
              button: "Angebot prüfen",
              offerChangeButton: "Angebot anpassen",
            },
            offerChange: {
              button: "Angebot anpassen",
              modal: {
                title: "Angebot anpassen",
                headerText:
                  "Du kannst Dich per E-Mail oder Telefon bei Pitstop melden, um Dein Angebot anpassen zu lassen.",
                bottomText:
                  "Dein <bold>Kfz-Kennzeichen</bold> lautet <bold>{info}</bold>.",
                email: {
                  subject:
                    "HUK-Autoservice: Anpassung meines Inspektionsangebots",
                  body: "Guten Tag,\n\nich bitte um Anpassung meines Inspektionsangebots. Mein Kennzeichen lautet {info}.\n\nBitte nehmen Sie mit mir Kontakt auf.\n\nVielen Dank.\n\nMit freundlichen Grüßen\n{firstName} {lastName}",
                },
              },
            },
          },
          action: {
            paymentPending: {
              title: "Zahlung ausstehend",
              description:
                "Klicke auf “Jetzt bezahlen” um Deine Buchung abzuschließen.",
              button: "Jetzt bezahlen",
            },
            moreInfoNeeded: {
              title: "Werkstatt benötigt weitere Infos",
              description: "Bitte prüfe Deine Emails.",
            },
            bookAgain: {
              title: "Auftrag erneut buchen",
              description:
                "Da wir noch die Daten von Deiner letzten Buchung haben, kannst Du ganz bequem in wenigen Schritten erneut buchen.",
              button: "Erneut Buchen",
            },
          },
        },
        myData: {
          title: "Meine HUK Autowelt",
          links: {
            bookService: "Service-Übersicht",
            bookings: "Aufträge & Erneut Buchen",
            costOverview: "Kostenübersicht",
            vehicles: "Gespeicherte Fahrzeuge",
            account: "Nutzerkonto & Vorteilskarte",
          },
        },
        help: {
          title: "Hilfe",
          links: {
            about: "Fragen und Antworten",
            support: "Support kontaktieren",
          },
        },
        buttons: {
          signOut: "Abmelden",
        },
      },
      bookings: {
        title: "Aufträge & Erneut Buchen",
        empty: "Du hast aktuell keine Buchungen.",
        sections: {
          bookings: {
            toggle: "Aufträge",
            text: "Behalte den Fortschritt Deiner Aufträge mit unserer Übersicht im Blick. Klicke auf “Details zeigen”, um detaillierte Informationen zum Auftrag zu erhalten.",
          },
          oneClickBookings: {
            toggle: "Erneut Buchen",
            text: "Deine abgeschlossenen & abgebrochenen Aufträge werden hier gesammelt, damit Du diese ganz bequem in wenigen Schritten erneut buchen kannst.",
          },
        },
        statuses: {
          actionNeeded: "Aktion erforderlich",
          upcoming: "Geplant",
          past: "Abgeschlossen",
          cancelled: "Abgebrochen",
        },
        manage: {
          title: {
            ps: "Termin verschieben oder stornieren",
            pws: "Buchung stornieren",
          },
          description: {
            ps: "Du kannst Deine Buchung noch bis <date>12:10Uhr am 28.11.2022</date> stornieren oder bis zu 2 Stunden vorher verschieben.",
            pws: "Du kannst Deine Buchung noch bis <date>12:10Uhr am 28.11.2022</date> stornieren.",
          },
          dateFormat:
            "{fullDayName}, {selectedDate, date, ::dd.MM.yyyy} um {selectedDate, time, ::H:m} Uhr",
          reschedule: {
            button: "Termin verschieben",
            modal: {
              title: "Termin verschieben",
              headerText1:
                "Leider ist diese Funktion online noch nicht verfügbar.",
              headerText2:
                "Du kannst Dich jedoch in der Zwischenzeit per E-Mail oder Telefon bei Pitstop melden.",
              bottomText:
                "Deine <bold>Buchungs-ID</bold> lautet <bold>{info}</bold>.",
              email: {
                link: "<link>vorausgefüllte E-Mail verwenden</link>",
                subject: "HUK-Autoservice: Terminverschiebung",
                body: "Guten Tag,\n\nich bitte um Verschiebung meines Termins mit der Buchungs-ID {info}.\n\nBitte nennen Sie mir mögliche Alternativen.\n\nVielen Dank.\n\nMit freundlichen Grüßen\n{firstName} {lastName}",
              },
              phone:
                "<link>0800 77 33 100</link> (Montag - Freitag, 08:00 - 18:00 Uhr)",
              back: "Zurück",
            },
          },
          cancel: {
            button: "Buchung stornieren",
            error: "Beim stornieren Deines Termins ist ein Fehler aufgetreten",
            modal: {
              dateFormat:
                "{fullDayName}, {selectedDate, date, ::dd.MM.yyyy} um {selectedDate, time, ::H:m} Uhr",
              title: "Buchung wirklich stornieren?",
              content:
                "Möchtest Du Deine Buchung stornieren und den Termin am <date>Morgen, 01.12.21 um 15:00 Uhr</date> bei <garage>pitstop Mariendorf</garage> absagen?",
              yes: "Buchung stornieren",
              back: "Zurück",
            },
            confirmation: {
              ps: {
                title: "Anfrage zur Stornierung wurde versendet",
                content:
                  "Deine Anfrage zur Stornierung der Buchung wurde versendet. Du wirst eine E-Mail mit allen weiteren Schritten erhalten.",
              },
              pws: {
                title: "Buchung storniert",
                content:
                  "Deine Stornierung wurde an die Werkstatt weitergeleitet. Zusätzlich erhältst Du von uns eine E-Mail mit der Stornierungsbestätigung.",
              },
              backToAccount: "Zurück zum Account",
              backToHome: "Zur Startseite",
            },
          },
          pitstopContactModal: {
            email: "<link>vorausgefüllte E-Mail verwenden</link>",
            phone:
              "<link>0800 77 33 100</link> (Montag - Freitag, 08:00 - 18:00 Uhr)",
            back: "Zurück",
          },
        },
      },
      costOverview: {
        title: "Kostenübersicht",
        list: {
          title: "Belegnummer und Service",
          details: "Details zeigen",
          fullDateFormat: "{fullDayName}, {selectedDate, date, ::dd.MM.yyyy}",
          documentNumber: "Belegnummer:",
          empty: "Es wurde noch keine Kostenaufstellung hinterlegt.",
        },
        details: {
          title: "Vielen Dank für Dein Vertrauen!",
          introText:
            "Im Folgenden findest Du eine Auflistung der Servicekosten**, welche am <date>27.12.2023</date> durchgeführt wurden.",
          dateFormat: "{selectedDate, date, ::dd.MM.yyyy}",
          header: {
            servicePartner: "Servicepartner",
            beneficiary: "Leistungsempfänger",
            costListing: "Kostenauflistung",
          },
          fields: {
            orderNumber: "Auftragsnummer",
            invoiceNumber: "Rechnungsnummer",
            customerNumber: "Kundennummer",
            vehicle: "Fahrzeug",
            totalPrice: "Gesamtpreis",
          },
          taxInfo: "*Preise inkl. 19% USt.",
          bottom: {
            text1:
              "Wir hoffen Du bist mit unserer Leistung zufrieden und freuen uns, wenn Du uns das nächste Mal wieder bei uns buchst.",
            text2: "Allzeit gute Fahrt wünscht Dir Dein HUK-Autoservice Team.",
            buttonText: "Du hast Fragen oder Anregungen?",
            text3:
              "**Die Kostenauflistung dient Deiner persönlichen Einsichtnahme. Eine vollständige Rechnung wurde Dir von unserem Servicepartner ausgehändigt.",
          },
        },
      },
      myAccount: {
        title: "Nutzerkonto & Vorteilskarte",
        customerId: "Kundennr.:",
        personalData: {
          title: "Persönliche Daten",
          link: "Bearbeiten",
        },
        password: {
          title: "Passwort",
          link: "Bearbeiten",
          description: "Hier kannst Du Dein Passwort ändern",
        },
        hukde: {
          title: "HUK-COBURG-Nutzerkonto",
          link: "Bearbeiten",
          description: "Hier kannst Du Dein HUK-COBURG-Nutzerkonto verwalten",
        },
        huk24: {
          title: "HUK24-Nutzerkonto",
          link: "Bearbeiten",
          description: "Hier kannst Du Dein HUK24-Nutzerkonto verwalten",
        },
        signOut: {
          success: "Abmeldung erfolgreich",
          deletionRequest:
            "Bitte bestätige die Löschung Deines Nutzerkontos durch Klick auf den dort hinterlegten Link.\nDer Link ist nur 48 Stunden lang gültig.\nBitte prüfe auch Deinen Spam-Ordner. Keine Mail erhalten?",
          contact: "<link>Unser Support hilft Dir.</link>",
        },
        customerCard: {
          title: "HUK AutoWelt Vorteilskarte",
          link: "Bearbeiten",
          availableSoon: "bald verfügbar",
          description: "Alles rund um Deine persönliche Vorteilskarte",
          tag: {
            registered: "noch nicht aktiviert",
            notRegistered: "noch nicht angemeldet",
            activated: "aktiviert",
          },
          delete: {
            trigger: "Vorteilskarte löschen",
            title: "Vorteilskarte wirklich löschen?",
            content:
              "Durch die Löschung Deiner Vorteilskarte werden die damit einhergehenden Vorteile gelöscht:",
            list1: "Exklusive Angebote nur für Vorteilskarten-Inhaber",
            list2: "Regelmäßige Aktionen: nichts mehr verpassen",
            confirm: "Vorteilskarte löschen",
            success: "Vorteilskarte erfolgreich gelöscht.",
            error:
              "Die Vorteilskarte konnte nicht gelöscht werden. Bitte wende Dich an den Kundensupport",
          },
          order: {
            title:
              "Bestelle jetzt Deine persönliche HUK AutoWelt Vorteilskarte",
          },
          success: {
            created: {
              title:
                "Um Deine HUK AutoWelt Vorteilskarte zu nutzen, lade sie herunter und aktiviere sie in Deiner Wallet!",
              link: "Zum Download",
            },
            activated: {
              title: "Deine HUK AutoWelt Vorteilskarte ist aktiv.",
              link: "Neuen Download Link anfordern",
            },
            customerData: {
              title: "Diese Informationen sind auf Deiner Karte gespeichert:",
              fields: {
                customerId: "Kundennr.",
                firstName: "Vorname",
                lastName: "Nachname",
                car: "Fahrzeug",
                licensePlate: "KFZ Kennzeichen",
                email: "Email",
              },
            },
          },
          modal: {
            steps: {
              personalData: "Bestätige Deine persönlichen Daten",
              vehicleData: "Wähle Dein Fahrzeug",
            },
            openButton: "Jetzt beantragen",
            createCard: {
              success: "Deine HUK Vorteilskarte ist eingerichtet.",
              error: "Autsch, das hat nicht funktioniert.",
            },
          },
          forms: {
            personalData: {
              title: "Persönliche Daten",
              fields: {
                title: "Anrede",
                firstName: "Vorname",
                lastName: "Nachname",
                email: "E-Mail",
                passCreatorTerms:
                  "Ja, ich will gut und aktuell informiert sein! Ich bin damit einverstanden, dass mich die HUK-Autoservice GmbH und die HUK-Autowelt GmbH per elektronischer Post (z.B. E-Mail, Push-Nachrichten, SMS) über weitere Angebote und Produkte informiert und zur Marktforschung kontaktiert. Die Gesellschaften der Unternehmensgruppe sowie die Produkte finden Sie unter den weiteren Details. Diese Einwilligung ist freiwillig und kann jederzeit formlos widerrufen werden.",
              },
              button: "Weiter zum Fahrzeug",
              success: "Du hast Dein Konto erfolgreich aktualisiert.",
              error: "Konto-Aktualisierung fehlgeschlagen.",
            },
            vehicleData: {
              title: "Fahrzeugdaten",
              description:
                "Die Kundenkarte bezieht sich auf ein Auto. Bitte wähle das Fahrzeug, das auf Deiner HUK AutoWelt Kundenkarte hinterlegt werden soll.",
              buttons: {
                orderCard: "Jetzt Karte anfordern",
                back: "Zurück",
              },
              noResults: {
                title: "Kein Fahrzeug hinterlegt",
                description: "Neues Fahrzeug anlegen",
              },
            },
          },
          info: {
            title:
              "Mit Deiner persönlichen Kundenkarte hast Du Deine HUK AutoWelt immer dabei! Hole sie Dir jetzt!",
            benefits: {
              notification: "Wir erinnern Dich an Deinen Termin.",
              identification:
                "Einfach vorzeigen - Alle nötigen Daten für die Identifikation vor Ort direkt auf Deiner Karte.",
              actions: "Angebote und Aktionen - nichts mehr verpassen!",
            },
          },
        },
        editPersonalData: {
          title: "Persönliche Daten",
          form: {
            fields: {
              title: "Anrede",
              firstName: "Vorname",
              lastName: "Nachname",
              email: "E-Mail",
              phoneNumber: "Telefonnummer (ggf. für Rückfragen der Werkstatt)",
              streetAndHouseNumber: "Straße und Hausnummer",
              postalCodeAndCity: "Postleitzahl und Ort",
              country: "Land",
            },
            values: {
              country: {
                germany: "Deutschland",
              },
            },
          },
          button: "Änderungen speichern",
          success: "Du hast Dein Konto erfolgreich aktualisiert.",
          error: "Konto-Aktualisierung fehlgeschlagen.",
        },
        changePassword: {
          form: {
            success: "Du hast Dein Passwort erfolgreich geändert.",
            cancelled: "Passwort ändern abgebrochen",
            error: "Passwort ändern fehlgeschlagen",
          },
        },
        accountDeletion: {
          trigger: "Nutzerkonto löschen",
          steps: {
            benefits: {
              description:
                "Durch die Löschung Deines Nutzerkontos werden folgende Daten und die damit einhergehenden <bold>Vorteile</bold> permanent gelöscht:",
              benefits: {
                benefit1: "Deine persönlichen Daten",
                benefit2: "Deine Fahrzeugdaten",
                benefit3: "Deine Auftrags- und Kostenübersicht",
                benefit4: "Deine HUK Vorteilskarte",
              },
              note: "Im Falle von erfolgten Buchungen werden nur die absolut notwendigen Daten zur Serviceabwicklung beibehalten.",
              buttonLabel: "Nutzerkonto löschen",
              modalTitle: "Nutzerkonto wirklich löschen?",
            },
            feedback: {
              description:
                "Deine Antwort wird uns helfen, unsere Plattform zu verbessern.",
              note: "(Mehrfachantwort möglich)",
              options: {
                offers: "Die Angebote sind für mich nicht interessant.",
                inquiries: "Meine Anfragen wurden nicht beantwortet.",
                workshop: "Meine präferierte Werkstatt ist nicht dabei.",
                area: "Keine Werkstätten in meiner Nähe.",
                other: "Sonstiges und zwar ...",
              },
              textarea: "Gründe warum Du gehst...",
              buttonLabel: "Weiter",
              modalTitle:
                "{name}, bevor Du gehst: sag uns doch bitte, warum Du uns verlässt.",
            },
            sendEmail: {
              text: "Wir senden Dir eine E-Mail an die im Konto hinterlegte Adresse.",
              confirm:
                "Bitte bestätige die Löschung Deines Nutzerkontos durch Klick auf den dort hinterlegten Link.",
              buttonLabel: "E-Mail senden",
              modalTitle: "Nutzerkonto löschen",
            },
            sendingRequest: {
              buttonLabel: "E-Mail senden",
              modalTitle: "E-Mail wird versendet",
            },
          },
          cancel: "Abbrechen",
          error:
            "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie unseren Support",
          confirmation: {
            success: "Nutzerkonto erfolgreich gelöscht.",
            error: "Der Link ist nicht mehr gültig.",
          },
        },
      },
      myVehicles: {
        title: "Gespeicherte Fahrzeuge",
        addVehicle: "Fahrzeug hinzufügen",
        editVehicle: "Bearbeiten",
        noVehicles: "Du hast aktuell keine Fahrzeuge gespeichert.",
        detailsLink: "Details",
        hsn: "HSN",
        tsn: "TSN",
        myVehicleData: {
          addVehicleData: "Fahrzeugdaten hinzufügen",
          vehicleData: "Fahrzeugdaten bearbeiten",
          form: {
            button: "Fahrzeug speichern",
            deleteButton: "Fahrzeug entfernen",
            success: "Fahrzeugdaten erfolgreich aktualisiert",
            successDiscountRegistered:
              "Dein Fahrzeug wurde erfolgreich für die Rabattaktion registriert.",
            deleteSuccess: "Fahrzeugdaten wurden gelöscht.",
            error: "Speichern Fahrzeug ist fehlgeschlagen",
            deleteVehicleModal: {
              title: "Fahrzeugdaten wirklich löschen?",
              content:
                "Die Daten können anschließend nicht mehr aufgerufen werden.",
              confirm: "Fahrzeugdaten löschen",
            },
          },
        },
      },
      bookingSummary: {
        title: "Mein Auftrag",
        dateFormat: "{fullDayName}, {selectedDate, date, ::dd.MM.yyyy}",
        timeFormat: "{time, date, ::H:m} Uhr",
        pickup: "Abholen heute",
        pickupToday: "Abholen",
        garageInfo: "Werkstatt Info",
        servicePrice: "Servicekosten",
        requestPrice: "Preis auf Anfrage",
        orderConfirmation: "Auftragsbestätigung",
        buttons: {
          bookAgain: "Service erneut buchen",
        },
      },
      bookingDetails: {
        title: "Deine Angaben",
        fields: {
          bookingId: "Buchungs-ID",
          requestId: "Anfrage-ID",
          total: "Summe",
          name: "Name",
          email: "E-Mail",
          phoneNumber: "Tel-Nr.",
        },
      },
      benefits: {
        title: "Deine Vorteile",
        booking: {
          benefit1: "Garantierter Festpreis, keine Überraschungen",
          benefit2: "Kein Verhandeln, einfach immer zum günstigen Preis buchen",
          benefit3: "Digital zahlen und vor Ort Zeit sparen",
          benefit4:
            "100% Rückerstattung bei Stornierung bis 24 Std. vor Termin",
        },
        request: {
          benefit1: "Unverbindliche Preis- und Terminanfrage",
          benefit2: "Unser Service-Partner wird sich zeitnah bei Dir melden",
        },
      },
      login: {
        form: {
          fields: {
            title: "Anrede",
            email: "E-Mail",
            firstName: "Vorname",
            lastName: "Nachname",
            street: "Straße",
            addressAddition: "Adresszusatz",
            houseNumber: "Hausnummer",
            postalCode: "Postleitzahl",
            city: "Ort",
            streetAndHouseNumber: "Straße & Hausnummer",
            postalCodeAndCity: "Postleitzahl & Ort",
            country: "Land",
            phoneNumber: "Telefonnummer (ggf. für Rückfragen der Werkstatt)",
            password: "Passwort",
            licensePlate: "KFZ Kennzeichen",
            terms:
              "Ich habe die <terms>AGB</terms> gelesen und erkläre mich einverstanden.",
            privacy:
              "Die <privacy>Datenschutzerklärung</privacy> habe ich zur Kenntnis genommen.",
          },
          validations: {
            title: {
              required: "Wie dürfen wir Dich ansprechen?",
            },
            terms: {
              required: "Du musst die AGB lesen und akzeptieren",
            },
          },
          values: {
            title: {
              mr: "Herr",
              mrs: "Frau",
              diverse: "Divers",
            },
            country: {
              germany: "Deutschland",
            },
          },
          button: "Weiter",
        },
        minCustomerData: {
          terms:
            "Mit der Reservierung bestätige ich die <terms>AGB</terms> und <privacy>Datenschutzerklärung</privacy> gelesen zu haben und erkläre mich einverstanden.",
        },
      },
    },
    error: {
      unknown: {
        background: "",
        title: "Unbekannter Fehler - Wir bitten um Entschuldigung!",
        content:
          "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
        buttonLabel: "Zurück zur Startseite",
        buttonTarget: "/",
      },
      "500": {
        background: "500",
        title: "500 Fehler - Wir bitten um Entschuldigung!",
        content:
          "Leider können wir Deine Anfrage zurzeit nicht bearbeiten. Bitte versuche es später erneut.",
        buttonLabel: "Zurück zur Startseite",
        buttonTarget: "/",
      },
      "501": {
        background: "501",
        title: "501 Fehler - Wir bitten um Entschuldigung!",
        content:
          "Leider können wir Deine Anfrage zurzeit nicht bearbeiten. Bitte versuche es später erneut.",
        buttonLabel: "Zurück zur Startseite",
        buttonTarget: "/",
      },
      "502": {
        background: "502",
        title: "502 Fehler - Wir bitten um Entschuldigung!",
        content:
          "Leider können wir Deine Anfrage zurzeit nicht bearbeiten. Bitte versuche es später erneut.",
        buttonLabel: "Zurück zur Startseite",
        buttonTarget: "/",
      },
      "503": {
        background: "503",
        title: "503 Fehler - Wir bitten um Entschuldigung!",
        content:
          "Wegen Wartungsarbeiten ist dieser Bereich im Moment nicht zu erreichen. Bitte versuche es später. Vielen Dank für Dein Verständnis.",
        buttonLabel: "Zurück zur Startseite",
        buttonTarget: "/",
      },
      "401": {
        background: "401",
        title: "401 Fehler - Wir bitten um Entschuldigung!",
        content:
          "Der angeforderte Inhalt befindet sich in einem passwortgeschützten Bereich.",
        buttonLabel: "Zurück zur Login",
        buttonTarget: "/account",
      },
      "403": {
        background: "403",
        title: "403 Fehler - Wir bitten um Entschuldigung!",
        content:
          "Der Zugriff auf den aufgerufenen Inhalt ist nicht möglich. Bitte überprüfe die Adresse. Oder nutze unsere Suche, um Informationen zum gewünschten Thema zu finden.",
        buttonLabel: "Zurück zur Startseite",
        buttonTarget: "/",
      },
      "404": {
        background: "404",
        title: "404 Fehler - Wir bitten um Entschuldigung!",
        content:
          "Den von Dir angeforderten Inhalt können wir leider nicht finden. Bitte überprüfe die Adresse.",
        buttonLabel: "Zurück zur Startseite",
        buttonTarget: "/",
      },
      "410": {
        background: "410",
        title: "410 Fehler - Wir bitten um Entschuldigung!",
        content:
          "Die von Dir aufgerufene Adresse ist leider nicht mehr verfügbar.",
        buttonLabel: "Zurück zur Startseite",
        buttonTarget: "/",
      },
    },
    contact: {
      meta: "Kontakt",
      title: "Kundensupport",
      form: {
        title: "Kontaktformular",
        submit: "Nachricht senden",
        error:
          "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
        fields: {
          topic: {
            label: "Wobei können wir Dir helfen?",
            value: "Bitte wähle Dein Thema",
            options: {
              one: "Ich möchte eine allgemeine Anfrage stellen.",
              two: "Ich habe eine Frage zu meiner Buchung.",
              three: "Ich weiß nicht, welchen Service ich benötige.",
              four: "Ich finde den Service nicht, den ich suche.",
            },
          },
          title: {
            label: "Anrede",
            value: "Anrede wählen",
          },
          firstName: {
            label: "Vorname",
            placeholder: "Vorname",
          },
          lastName: {
            label: "Nachname",
            placeholder: "Nachname",
          },
          email: {
            label: "Email",
            placeholder: "email@provider.com",
          },
          message: {
            label: "Worum geht es?",
            placeholder: "Deine Nachricht ...",
          },
        },
        privacy:
          "Deine Angaben behandeln wir selbstverständlich nach Datenschutzgrundverordnung und Bundesdatenschutzgesetz. Weitere Informationen zur Verwendung Deiner personenbezogenen Daten findest Du <terms>hier</terms>.",
      },
      hotline: {
        title: "Support-Hotline",
        timing: "Mo. - Fr. 8:00 - 17:30 Uhr",
      },
      faq: {
        title: "Fragen & Antworten",
        description:
          "Du hast Fragen zu unseren Services, dem Buchungsprozess oder Zahlungskonditionen. Unsere FAQs geben Auskunft.",
        button: "Zu den FAQs",
      },
      success: {
        headline: "Deine Nachricht wurde erfolgreich versand.",
        text: "Unser Support wird sich zeitnah bei Dir melden.",
        button: "Zurück zur Startseite",
      },
    },
  },
}
