import {
  GTMEvents,
  pushTrackingEventToDataLayer,
} from "src/helpers/tracking/gtm"
import {
  EventHandler,
  MetaEventData,
} from "src/helpers/tracking/tracking-event-handlers/types"
import { isValue } from "src/utils/typeGuards"

type AuthenticationEventData = Pick<MetaEventData, "authenticationType">

export const authentication: EventHandler<
  AuthenticationEventData,
  "authentication"
> = ({ data, gaEvent }, eventType = "authentication") => {
  const meta: GTMEvents[typeof eventType]["meta"] =
    isValue(data) && isValue(data.authenticationType)
      ? {
          authentication_type: data.authenticationType,
          content_name: eventType,
          status: true,
        }
      : undefined

  return pushTrackingEventToDataLayer({
    eventType,
    payload: { meta, gaEvent },
  })
}
