import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

import { StorageOptions } from "./syncToPersistentStorage"

export type NewsLetterStatus = {
  interacted?: boolean
}

// Please update cypress/support/newsletter.ts version as well
const sessionStorage: StorageOptions = {
  key: "newsletter-status",
  version: "2023-12-11",
  storage: "sessionStorage",
}

const { getValue, setValue, useValue, mergeValue } =
  createSimpleGlobalState<NewsLetterStatus>({}, sessionStorage)

export {
  getValue as getNewsLetterStatus,
  setValue as setNewsLetterStatus,
  useValue as useNewsLetterStatus,
  mergeValue as mergeNewsLetterStatus,
}
