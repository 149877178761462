import { Content, Description } from "@radix-ui/react-dialog"
import { forwardRef } from "react"

import { Button } from "src/components/common/button"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"
import {
  closeNotificationModal,
  NotificationModalButton,
  useNotificationModal,
} from "src/states/notificationModal"

import styles from "./NotificationModalContent.module.scss"

const bem = create(styles, "NotificationModalContent")

export const NotificationModalContent = forwardRef<HTMLDivElement, {}>(
  (_props, ref) => {
    const { messages } = useTranslation()
    const { notification } = messages.components.core

    const data = useNotificationModal()

    const renderActionButtonItem = (
      button: NotificationModalButton,
      index: number,
    ) => {
      return (
        <Button
          href={button.href}
          variant={button.variant}
          data-cy={`notification-modal-button-${button.variant}`}
          size={button.size}
          key={`notification-modal-${index}`}
          aria-label={button.label}
          className={bem("button", {
            "has-fullWidth": button.fullWidth ?? false,
          })}
          onClick={() => {
            button.onClick?.()
            closeNotificationModal()
          }}
        >
          {button.label}
        </Button>
      )
    }

    if (!data) {
      return null
    }

    const { size = "lg" } = data

    return (
      <Content
        className={bem(undefined, { [size]: true })}
        onEscapeKeyDown={() => closeNotificationModal()}
        ref={ref}
        data-cy="notification-modal-content"
      >
        {data.title && (
          <div className={bem("header")}>
            <div className={bem("title")}>{data.title}</div>
          </div>
        )}

        <Description className={bem("body")} asChild>
          <div className={bem("content")}>
            {!Array.isArray(data.content) ? (
              <p className={bem("paragraph")}>{data.content}</p>
            ) : (
              data.content.map((item, index) => (
                <p className={bem("paragraph")} key={index}>
                  {item}
                </p>
              ))
            )}
          </div>
        </Description>

        <div className={bem("buttons")}>
          {data.buttons && data.buttons.length > 0 ? (
            data.buttons.map(renderActionButtonItem)
          ) : (
            <Button
              variant="tertiary"
              aria-label={notification.close}
              className={bem("button")}
              onClick={() => closeNotificationModal()}
              data-cy="notification-modal-button-tertiary"
            >
              {notification.close}
            </Button>
          )}
        </div>
      </Content>
    )
  },
)

NotificationModalContent.displayName = "NotificationModalContent"
