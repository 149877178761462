import { useEffect } from "react"
import TagManager from "react-gtm-module"

import { EXTERNAL_SERVICES } from "src/config"
import { useHasServiceConsent } from "src/helpers/usercentrics/hooks/useHasServiceConsent"
import { serviceIds } from "src/helpers/usercentrics/types"

export const useInitializeGoogleTagManager = () => {
  const hasGAConsent = useHasServiceConsent(serviceIds.googleAnalitics)

  useEffect(() => {
    if (hasGAConsent) {
      const { containerId: gtmId } = EXTERNAL_SERVICES.google.gtm
      TagManager.initialize({ gtmId })
    }
    // this hook side effect should run only when user centrics data changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasGAConsent])
}
