import { account } from "src/helpers/tracking/tracking-event-handlers/account"
import { address } from "src/helpers/tracking/tracking-event-handlers/address"
import { appointment } from "src/helpers/tracking/tracking-event-handlers/appointment"
import { authentication } from "src/helpers/tracking/tracking-event-handlers/authentication"
import { checkin } from "src/helpers/tracking/tracking-event-handlers/checkin"
import { checkout } from "src/helpers/tracking/tracking-event-handlers/checkout"
import { completed } from "src/helpers/tracking/tracking-event-handlers/completed"
import { contact } from "src/helpers/tracking/tracking-event-handlers/contact"
import { garages } from "src/helpers/tracking/tracking-event-handlers/garages"
import { home } from "src/helpers/tracking/tracking-event-handlers/home"
import { landing } from "src/helpers/tracking/tracking-event-handlers/landing"
import { navigationBar } from "src/helpers/tracking/tracking-event-handlers/navigationBar"
import { newsLetter } from "src/helpers/tracking/tracking-event-handlers/newsLetter"
import { payment } from "src/helpers/tracking/tracking-event-handlers/payment"
import { services } from "src/helpers/tracking/tracking-event-handlers/services"
import { serviceWizard } from "src/helpers/tracking/tracking-event-handlers/serviceWizard"
import { shoppingCart } from "src/helpers/tracking/tracking-event-handlers/shoppingCart"
import { support } from "src/helpers/tracking/tracking-event-handlers/support"
import { vehicle } from "src/helpers/tracking/tracking-event-handlers/vehicle"

import { checkoutSummary } from "./tracking-event-handlers/checkoutSummary"
import { customerCard } from "./tracking-event-handlers/customerCard"
import { footer } from "./tracking-event-handlers/footer"
import { inspection } from "./tracking-event-handlers/inspection"
import { onpier } from "./tracking-event-handlers/onpier"
import { ratgeber } from "./tracking-event-handlers/ratgeber"
import { referFriends } from "./tracking-event-handlers/referFriends"
import { werkstatt } from "./tracking-event-handlers/werkstatt"

export const sendTrackingEvent = {
  account,
  address,
  appointment,
  authentication,
  checkin,
  checkout,
  completed,
  contact,
  garages,
  home,
  landing,
  navigationBar,
  newsLetter,
  payment,
  services,
  serviceWizard,
  shoppingCart,
  support,
  vehicle,
  checkoutSummary,
  footer,
  inspection,
  customerCard,
  ratgeber,
  referFriends,
  onpier,
  werkstatt,
}
