import { hookstate, none, useHookstate } from "@hookstate/core"

import { IconName } from "src/components/common/icon"

export type NotificationToastVariant = "info" | "success" | "error"

export type NotificationToastLink = {
  link: string
  variant?: "primary" | "secondary" | "tertiary"
  text?: string
  tracking?: () => void
}

export type NotificationToastData = {
  variant: NotificationToastVariant
  icon?: IconName
  content: string
  autoclose?: boolean
  autocloseTimeout?: number
  showOncePerSession?: boolean
  link?: NotificationToastLink
}

type NotificationToastItemData = {
  id: number
  data: NotificationToastData
}

type NotificationState = {
  toasts: NotificationToastItemData[]
}

let notificationToastCount = 1

export const AUTOCLOSE_TIMEOUT = 3000

const state = hookstate<NotificationState>({
  toasts: [],
})

/**
 * Adds a new toast notification and returns the id of the created notification.
 * @param data the notification to be added
 * @returns the id of the created toast notification
 */
export const addNotificationToast = (data: NotificationToastData): number => {
  const id = notificationToastCount++

  state.toasts.merge([{ id, data }])

  if ("autoclose" in data && data.autoclose) {
    const timeout = data.autocloseTimeout ?? AUTOCLOSE_TIMEOUT
    setTimeout(() => removeNotificationToast(id), timeout)
  }

  return id
}

/**
 * Removes a toast notification by id.
 * @param id the id of the toast notification
 */
export const removeNotificationToast = (id: number | null) => {
  if (!id) {
    return
  }

  const key = state.toasts.keys.find(
    (key) => state.toasts[key]?.id?.get() === id,
  )

  if (key === undefined) {
    return
  }

  state.toasts[key]?.set(none)
}

/**
 * Removes all notifications.
 */
export const removeAllNotificationToast = () => {
  state.toasts.set([])
}

/**
 * Returns all toast notifications added to the state.
 * @returns the list of toast notifications
 */
export const getNotificationToasts = () => state.toasts.get()

/**
 * React hook to receive all toast notifications from the state.
 * @returns the list of toast notifications
 */
export const useNotificationToasts = () =>
  useHookstate(state).toasts.get({ noproxy: true })

export function getState() {
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== "test") {
    throw Error("Accessing state is only allowed for testing")
  }

  return state
}
