type LogError = (props: {
  message?: string
  source?: string
  exception?: Error | null
  path?: string
  amazonTraceId?: string
}) => void

export const logError: LogError = ({
  message,
  source,
  exception,
  path,
  amazonTraceId,
}) => {
  // Do not log error for CSS paths
  if (path?.includes("/_next/static/css/")) return

  const error =
    exception ?? new Error(`The unknown error was caught at path: ${path}`)
  const errorMessage = message ?? error.message

  console.error(
    JSON.stringify({
      name: error.name,
      message: `Client Error - ${errorMessage}`,
      source,
      stack: error.stack || "",
      amazonTraceId: amazonTraceId || "",
    }),
  )
}
