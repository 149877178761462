import { stringify } from "query-string"

import { ValueOf } from "type-fest"

export const serviceFlowSteps = {
  service: "index",
  garages: "werkstaetten",
  vehicle: "fahrzeug",
  appointment: "termin",
  checkout: "persoenliche-daten",
  inspection: "inspektion",
  payment: "bezahlen",
  paymentSuccess: "bezahlen-erfolg",
  completed: "completed", // TODO: German translation
  cancelled: "cancelled", // TODO: German translation
  failed: "failed", // TODO: German translation
} as const

export type FlowStep = ValueOf<typeof serviceFlowSteps>

export const serviceRouteNames = {
  huau: "HU_AU",
  oelwechsel: "OIL_CHANGE",
  klimaanlage: "AIR_CONDITIONING",
  endschalldaempferwechsel: "EXHAUST_CHANGE",
  batteriewechsel: "BATTERY_CHANGE",
  bremsenservice: "BRAKE_DISC_CHANGE",
  radwechsel: "WHEEL_CHANGE",
  stossdaempferwechsel: "SHOCK_ABSORBER_CHANGE",
  inspektion: "BASIC_INSPECTION",
  diagnosetermin: "DIAGNOSE",
} as const

export type ServiceRouteName = keyof typeof serviceRouteNames

/**
 * 0: Default flow.
 * 2: Inspection offer acceptance flow.
 * 3: HUK24 iframe booking flow
 */
export type Flow = "0" | "2" | "3"

export type PaymentRouteParams = {
  bookingId: string
}

export type BookingRouteParams = {
  bookingId: string
}

export type MyVehicleDataRouteParams = {
  vehicleId?: string
}

export type ServiceRouteParams = {
  flowStep?: FlowStep
  serviceName?: ServiceRouteName
  /**
   * Flow is set when it differs from the default (0).
   */
  flow?: Flow
  /** Only set if it's a garage first approach. */
  garageId?: string
  /** Only set if a booking wasn't completed successfully. */
  bookingId?: string
  /** Only set if it is an inspection offer acceptance flow */
  inspectionOfferId?: string
  /** Only set if payment wasn't completed successfully. */
  paymentErrorId?: string
  /** Add a boolean param and its value will be set to true in URL. */
  booleanParam?: "openAppointments" | "completed"
}

const accountPathPrefix = "/account"
const myAccountPathPrefix = accountPathPrefix + "/mein-account"
const myVehiclesPathPrefix = accountPathPrefix + "/meine-fahrzeuge"
const coreApiPathPrefix = "/api/core"
const accountApiPathPrefix = "/api/account"
const accountVehiclesApiPathPrefix = "/api/account/vehicles"
const accountCustomerCardApiPathPrefix = "/api/account/customerCard"
const accountDeletionApiPathPrefix = "/api/account/accountDeletion"

const allServicesPrefix = "/ratgeber"

export const servicesBaseRoute = "services" as const
export const serviceBaseRoute = "service" as const
export const servicesBookingConfirmation = "buchungsbestaetigung" as const
export const successConfirmation = "erfolgreich" as const

const serviceIconLinks: { [id: string]: string } = {
  inquiry: "/services?openInquiry=true",
  diagnose: "/service/diagnosetermin",
  klimainspection: "/service/klimaanlage",
  vorabcheck: "/service/huau",
  huau: "/service/huau",
  bremsen: "/service/bremsenservice",
}

export const ROUTES = {
  //
  // User-visible routes should be in German because the audience is German.
  //
  index: "/",
  welcome: "/willkommen",
  terms: "/agb",
  aboutUs: "/ueber-uns",
  actions: "/praemienvorteil",
  allServices: {
    battery: `${allServicesPrefix}/batterie`,
    brakes: `${allServicesPrefix}/bremsen`,
    climate: `${allServicesPrefix}/klimaanlage`,
    exhaust: `${allServicesPrefix}/auspuff`,
    index: `${allServicesPrefix}`,
    inspection: `${allServicesPrefix}/inspektion`,
    landingGear: `${allServicesPrefix}/fahrwerk`,
    oil: `${allServicesPrefix}/oelwechsel`,
    security: `${allServicesPrefix}/sicherheits-check`,
    tuv: `${allServicesPrefix}/hu-au`,
    wheels: `${allServicesPrefix}/reifen`,
  },
  contact: "/kontakt",
  imprint: "/impressum",
  privacy: "/datenschutz",
  qa: "/fragen-und-antworten",
  serviceWizard: "/service-wizard",
  customerCard: "/vorteilskarte",
  referFriends: {
    index: "/freundewerben",
    inviter: "/freundewerben/einladen",
    invitee: "/freundewerben/eingeladen",
    termsAndConditions: "/freundewerben/teilnahmebedingungen",
  },
  inspection: {
    root: (bookingId: string) =>
      `/${servicesBaseRoute}/inspektion/${bookingId}`,
    expired: (bookingId: string) =>
      `/${servicesBaseRoute}/inspektion/${bookingId}/abgelaufen`,
  },
  serviceFlow: ({
    flowStep,
    flow,
    garageId,
    bookingId,
    inspectionOfferId,
    paymentErrorId,
    booleanParam,
  }: ServiceRouteParams) => {
    let url = `/${servicesBaseRoute}`

    if (flowStep === serviceFlowSteps.garages) {
      url += `/${serviceFlowSteps.garages}`
    } else if (flowStep === serviceFlowSteps.inspection && inspectionOfferId) {
      url += `/${serviceFlowSteps.inspection}/${inspectionOfferId}`
    } else if (flowStep === serviceFlowSteps.vehicle) {
      url += `/${serviceFlowSteps.vehicle}`
    } else if (flowStep === serviceFlowSteps.appointment) {
      url += `/${serviceFlowSteps.appointment}`
    } else if (flowStep === serviceFlowSteps.checkout) {
      if (bookingId) {
        url += `/${serviceFlowSteps.checkout}/${bookingId}`
      } else {
        url += `/${serviceFlowSteps.checkout}`
      }
    } else if (flowStep === serviceFlowSteps.payment) {
      url += `/${serviceFlowSteps.payment}`
    } else if (flowStep === serviceFlowSteps.paymentSuccess && bookingId) {
      url += `/${serviceFlowSteps.payment}/${successConfirmation}/${bookingId}`
    } else if (flowStep === serviceFlowSteps.failed) {
      url += `/${serviceFlowSteps.failed}`
    } else if (flowStep === serviceFlowSteps.cancelled) {
      url += `/${serviceFlowSteps.cancelled}`
    } else if (flowStep === serviceFlowSteps.completed && bookingId) {
      url += `/${servicesBookingConfirmation}/${bookingId}`
    }

    if (flow) {
      url += "?" + stringify({ flow })
    }

    if (flow && garageId) {
      url += "&" + stringify({ garageId })
    }

    if (paymentErrorId) {
      url += "?" + stringify({ paymentErrorId })
    }

    if (booleanParam) {
      url += `${flow ? "&" : "?"}${booleanParam}=true`
    }

    return url
  },
  service: ({ serviceName, flow }: ServiceRouteParams) => {
    let url = `/${serviceBaseRoute}`

    if (serviceName) {
      url += `/${serviceName}`
    }

    if (flow) {
      url += "?" + stringify({ flow })
    }

    return url
  },
  orderDetails: (orderId: string) => `/buchungen/${orderId}`,
  account: {
    home: accountPathPrefix,
    login: `${accountPathPrefix}/login`,
    register: `${accountPathPrefix}/register`,
    bookings: `${accountPathPrefix}/buchungen`,
    bookingCancel: (bookingId: string) =>
      `${accountPathPrefix}/buchungen/stornieren/${bookingId}`,
    bookingDetails: (bookingId: string) =>
      `${accountPathPrefix}/buchungen/${bookingId}`,
    myAccount: {
      index: myAccountPathPrefix,
      editPersonalData: `${myAccountPathPrefix}/personliche-daten`,
      customerCard: `${myAccountPathPrefix}/vorteilskarte`,
      referFriends: `${myAccountPathPrefix}/freundewerben`,
    },
    myVehicles: {
      index: myVehiclesPathPrefix,
      myVehicleData: (vehicleId?: string) =>
        `${myVehiclesPathPrefix}/fahrzeugdaten/${vehicleId || ""}`,
    },
    costOverview: `${accountPathPrefix}/kostenuebersicht`,
    costOverviewDetails: (invoiceId?: string) =>
      `${accountPathPrefix}/kostenuebersicht/${invoiceId}`,
  },

  //
  // API routes can be in English.
  //
  api: {
    appManifest: "/api/app.webmanifest",
    account: {
      pathPrefix: accountApiPathPrefix,
      signUp: `${accountApiPathPrefix}/signUp`,
      signOut: `${accountApiPathPrefix}/signOut`,
      updateDetails: `${accountApiPathPrefix}/updateDetails`,
      marketingConsent: {
        update: `${accountApiPathPrefix}/marketingConsent/update`,
        get: `${accountApiPathPrefix}/marketingConsent`,
      },
      vehicles: {
        get: `${accountVehiclesApiPathPrefix}/`,
        create: `${accountVehiclesApiPathPrefix}/create`,
        update: (vehicleId: string) =>
          `${accountVehiclesApiPathPrefix}/update/${vehicleId}`,
        delete: (vehicleId: string) =>
          `${accountVehiclesApiPathPrefix}/delete/${vehicleId}`,
      },
      customerCard: {
        create: `${accountCustomerCardApiPathPrefix}/create`,
        delete: `${accountCustomerCardApiPathPrefix}/delete`,
      },
      accountDeletion: {
        request: `${accountDeletionApiPathPrefix}/request`,
      },
      referFriends: {
        link: `${accountApiPathPrefix}/referFriends/link`,
      },
    },
    core: {
      pathPrefix: coreApiPathPrefix,
      availableTimeSlots: `${coreApiPathPrefix}/appointment/availableTimeSlots`,
      isAppointmentAvailable: `${coreApiPathPrefix}/appointment/isAppointmentAvailable`,
      validatePromoCode: `${coreApiPathPrefix}/promo-code/validation/`,
      garages: {
        nearest: `${coreApiPathPrefix}/garages/nearest`,
        googleRatings: (garageId: string) =>
          `${coreApiPathPrefix}/garages/${garageId}/googleRatings`,
      },
      booking: {
        create: `${coreApiPathPrefix}/booking`,
        cancel: (bookingId: string) =>
          `${coreApiPathPrefix}/booking/${bookingId}/cancel`,
      },
      inspection: {
        acceptOffer: `${coreApiPathPrefix}/inspection`,
      },
      vehicle: {
        brand: `${coreApiPathPrefix}/vehicle/brand`,
        model: `${coreApiPathPrefix}/vehicle/model`,
        engine: `${coreApiPathPrefix}/vehicle/engine`,
        dataFromEngineId: `${coreApiPathPrefix}/vehicle/dataFromEngineId`,
        dataFromHsnTsn: `${coreApiPathPrefix}/vehicle/dataFromHsnTsn`,
      },
      services: {
        prices: `${coreApiPathPrefix}/services/prices`,
        getContentfulServices: `${coreApiPathPrefix}/services/getContentfulServices`,
        getServiceConfiguratorContent: `${coreApiPathPrefix}/services/getServiceConfiguratorContent`,
        additionalServicesPrices: `${coreApiPathPrefix}/services/additionalServicesPrices`,
      },
      sendContactEnquiry: `${coreApiPathPrefix}/sendEnquiry`,
    },
    services: {
      payment: {
        canceled: ({ bookingId }: PaymentRouteParams) =>
          `/api/services/booking/${bookingId}/payment/callback/canceled`,
        failed: ({ bookingId }: PaymentRouteParams) =>
          `/api/services/booking/${bookingId}/payment/callback/failed`,
        completed: ({ bookingId }: PaymentRouteParams) =>
          `/api/services/booking/${bookingId}/payment/callback/completed`,
      },
    },
    leads: {
      tracking: "/api/leads/tracking",
    },
  },

  /*
    Service Wizard page service Icon links
  */
  serviceIconLinks,
} as const
