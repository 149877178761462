import { sumServiceCategoriesPricing } from "src/helpers/sumServiceCategoriesPricing"
import {
  buildGa4EcommerceEvent,
  buildMetaEvent,
  parseServiceCategoriesToECommerceItem,
} from "src/helpers/tracking/eventParsersAndHandlers"
import { pushTrackingEventToDataLayer } from "src/helpers/tracking/gtm"
import {
  EventHandler,
  MetaEventData,
} from "src/helpers/tracking/tracking-event-handlers/types"

type VehicleEventData = Pick<
  MetaEventData,
  "serviceCategories" | "detailedGarage"
>

export const vehicle: EventHandler<VehicleEventData, "vehicle"> = (
  { data, gaEvent },
  eventType = "vehicle",
) => {
  const meta = buildMetaEvent(eventType, data)

  const builtGaEvent =
    gaEvent &&
    Object.assign(gaEvent, {
      ecommerce:
        gaEvent?.ecommerce ??
        buildGa4EcommerceEvent(
          sumServiceCategoriesPricing(
            data?.serviceCategories,
            data?.detailedGarage?.services,
          ),
          parseServiceCategoriesToECommerceItem(
            data?.serviceCategories,
            data?.detailedGarage,
          ),
        ),
    })

  return pushTrackingEventToDataLayer({
    eventType,
    payload: { meta, gaEvent: builtGaEvent },
  })
}
