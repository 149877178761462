export type HttpStatus = number | "REQUEST_TIMEOUT" | "PARSE_RESPONSE" | "OTHER"

export class HttpError extends Error {
  readonly status: HttpStatus

  constructor(message: string, status: HttpStatus) {
    super(message)
    this.status = status
  }
}
