import IntlMessageFormat from "intl-messageformat"
import assert from "ow"
import React, {
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useContext,
} from "react"

import { LOCALE, Locale, TranslationMessages } from "../translations"
import { messages as translationMessages } from "src/translations/messages/de"

const Context = createContext<TranslationMessages | null>(null)

export type FormatMessage = (
  message: string,
  values?: Record<string, string | number | Date>,
) => string

export type FormatMessageRaw = (
  message: string,
  values?: Record<string, any>,
) => ReactNode

export type TranslationFeatures = {
  locale: Locale
  messages: TranslationMessages
  formatMessage: FormatMessage
  formatMessageRaw: FormatMessageRaw
}

export type TranslationHook = () => TranslationFeatures

export const useTranslation: TranslationHook = () => {
  let locale = LOCALE

  const messages =
    (useContext(Context) as TranslationMessages) || translationMessages

  assert<Locale>(locale, assert.string.equals("de"))

  const formatMessage: FormatMessage = (message, values = {}) => {
    const formatted = new IntlMessageFormat(message, locale).format(values)

    if (Array.isArray(formatted)) {
      return formatted[0].toString()
    }

    return formatted.toString()
  }

  // Format message and return raw data without transforming to string
  const formatMessageRaw: FormatMessageRaw = (message, values) => {
    const formatted = new IntlMessageFormat(message, locale).format(values)

    return formatted
  }

  return { locale, messages, formatMessage, formatMessageRaw }
}

export type TranslationProviderProps = PropsWithChildren<{
  messages: TranslationMessages
}>

export const TranslationProvider: FC<TranslationProviderProps> = ({
  children,
  messages,
}) => {
  const { Provider } = Context

  return <Provider value={messages}>{children}</Provider>
}

export const getTranslationMessages = () => {
  return translationMessages
}
