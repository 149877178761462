import { useRouter } from "next/router"
import { FC, PropsWithChildren, useMemo } from "react"
import React, { useCallback, useEffect, useState } from "react"

import { UsercentricsContext } from "../context"
import type { UCUICMPEvent, UCWindow } from "../types"
import { UCUICMPEventType } from "../types"
import { getServicesFromLocalStorage, showFirstLayer } from "../utils"
import { ROUTES } from "src/config"
import { useIsMounted } from "src/hooks/isMounted"
import { isBrowser } from "src/utils/envUtils"

const ucEvent = "ucEvent"
const ucUICMPEvent = "UC_UI_CMP_EVENT"
const ucInitialisedEvent = "UC_UI_INITIALIZED"
const isUCUICMPEvent = (event: Event): event is UCUICMPEvent =>
  event.type === ucUICMPEvent
const isDataProtectionPage = (url: string) =>
  url.includes(ROUTES.imprint) ||
  url.includes(ROUTES.privacy) ||
  url.includes(ROUTES.welcome)

export type UsercentricsProviderProps = PropsWithChildren<{
  timeout?: number
}>

export const UsercentricsProvider: FC<UsercentricsProviderProps> = ({
  children,
  timeout = 5000,
}) => {
  /**
   * A trivial unique value that should be updated whenever
   * Usercentrics data is updated. This is for making sure
   * hooks using the data are also update.
   */
  const [ping, pong] = useState(Symbol())
  const [isInitialized, setIsInitialized] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isMounted = useIsMounted()

  const router = useRouter()

  const initializedCallback = useCallback(() => {
    setIsInitialized(true)
    pong(Symbol())
  }, [])

  const ucEventCallback = useCallback(() => {
    pong(Symbol())
  }, [])

  const ucUIEventCallback = useCallback((event: Event) => {
    if (!isUCUICMPEvent(event)) return

    /**
     * Detect whether the Usercentrics modal is open or closed:
     *
     * - if event type was 'CMP_SHOWN', it is open
     * - if event type was one of 'ACCEPT_ALL', 'DENY_ALL', or
     *   'SAVE', it means user clicked one of those buttons,
     *   and so it should now be closed
     */
    switch (event.detail.type) {
      case UCUICMPEventType.CMP_SHOWN:
        setIsModalOpen(true)
        break
      case UCUICMPEventType.ACCEPT_ALL:
      case UCUICMPEventType.DENY_ALL:
      case UCUICMPEventType.SAVE:
        setIsModalOpen(false)
    }
  }, [])

  useEffect(() => {
    if (isFailed || !isBrowser()) return

    if ((window as UCWindow).UC_UI?.isInitialized?.()) {
      setIsInitialized(true)
    }

    if (!isInitialized) {
      /** @see https://docs.usercentrics.com/#/v2-events?id=app-initialization-browser-ui */
      window.addEventListener(ucInitialisedEvent, initializedCallback)
    }

    /**
     * The event name is arbitrary and has to configured in the Admin UI
     * @see https://docs.usercentrics.com/#/v2-events?id=usage-as-window-event
     */
    window.addEventListener(ucEvent, ucEventCallback)

    /** @see https://docs.usercentrics.com/#/v2-events?id=uc_ui_cmp_event */
    window.addEventListener(ucUICMPEvent, ucUIEventCallback)

    /**
     * If user has blocked Usercentrics initialization script, update the var.
     * Used to eg. allow search using minimal consent options
     */
    let isAppMounted = true
    if (!isInitialized) {
      setTimeout(() => {
        if (isAppMounted && !isInitialized) {
          setIsFailed(true)
        }
      }, timeout)
    }

    return () => {
      window.removeEventListener(ucInitialisedEvent, initializedCallback)
      window.removeEventListener(ucEvent, ucEventCallback)
      window.removeEventListener(ucUICMPEvent, ucUIEventCallback)
      isAppMounted = false
    }
  }, [
    timeout,
    isInitialized,
    isFailed,
    initializedCallback,
    ucEventCallback,
    ucUIEventCallback,
    router.asPath,
  ])

  useEffect(() => {
    if (!isInitialized) {
      return
    }

    /**
     * Hide the Usercentrics modal on privacy pages
     */
    if (isDataProtectionPage(router.asPath)) {
      ;(window as UCWindow)?.UC_UI?.closeCMP?.()
    } else if ((window as UCWindow)?.UC_UI?.isConsentRequired?.()) {
      showFirstLayer()
    }
  }, [isInitialized, router.asPath])

  /**
   * Setting from localStorage are only used until the CMP has been loaded,
   * and after window.UC_UI is preferred. There should be no need to refresh the values.
   */
  const localStorageState = useMemo(() => {
    if (isMounted) {
      return getServicesFromLocalStorage()
    }
    return []
  }, [isMounted])

  return (
    <UsercentricsContext.Provider
      value={{
        isFailed,
        isInitialized,
        isOpen: isModalOpen,
        localStorageState,
        ping,
      }}
    >
      {children}
    </UsercentricsContext.Provider>
  )
}
