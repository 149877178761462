import { pushTrackingEventToDataLayer } from "src/helpers/tracking/gtm"
import { EventHandler } from "src/helpers/tracking/tracking-event-handlers/types"

export const newsLetter: EventHandler<undefined, "newsLetter"> = (
  { gaEvent },
  eventType = "newsLetter",
) => {
  return pushTrackingEventToDataLayer({
    eventType,
    payload: { gaEvent },
  })
}
