import { isPWSGarage } from "src/helpers/isPWSGarage"
import { sumServiceCategoriesPricing } from "src/helpers/sumServiceCategoriesPricing"
import {
  buildGa4EcommerceEvent,
  buildMetaEvent,
  parseServiceCategoriesToECommerceItem,
} from "src/helpers/tracking/eventParsersAndHandlers"
import { pushTrackingEventToDataLayer } from "src/helpers/tracking/gtm"
import {
  EventHandler,
  MetaEventData,
} from "src/helpers/tracking/tracking-event-handlers/types"
import { isValue } from "src/utils/typeGuards"

type CheckoutEventData = Pick<
  MetaEventData,
  "serviceCategories" | "detailedGarage"
>

export const checkout: EventHandler<
  CheckoutEventData,
  "checkout" | "pwsCheckout"
> = ({ data, gaEvent }) => {
  if (!isValue(data) || !isValue(data.detailedGarage) || !isValue(gaEvent))
    return

  const eventType = isPWSGarage(data.detailedGarage.garage.dmsProviderType)
    ? "pwsCheckout"
    : "checkout"

  const meta = buildMetaEvent(eventType, data)

  const builtGaEvent =
    gaEvent &&
    Object.assign(gaEvent, {
      ecommerce:
        gaEvent?.ecommerce ??
        buildGa4EcommerceEvent(
          sumServiceCategoriesPricing(
            data?.serviceCategories,
            data?.detailedGarage?.services,
          ),
          parseServiceCategoriesToECommerceItem(
            data?.serviceCategories,
            data?.detailedGarage,
          ),
          data.detailedGarage.garage.id,
        ),
    })

  return pushTrackingEventToDataLayer({
    eventType,
    payload: { meta, gaEvent: builtGaEvent },
  })
}
