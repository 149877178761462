import { createContext, FC, PropsWithChildren, useContext } from "react"

import { GlobalVariables } from "src/config"

const Context = createContext<GlobalVariables | null>(null)

export const useGlobalVariables = (): GlobalVariables => {
  return useContext(Context) as GlobalVariables
}

export type GlobalVariablesProviderProps = PropsWithChildren<{
  variables?: GlobalVariables
}>

export const GlobalVariablesProvider: FC<GlobalVariablesProviderProps> = ({
  children,
  variables,
}) => {
  const { Provider } = Context

  return <Provider value={variables ?? {}}>{children}</Provider>
}
