import { forwardRef, PropsWithChildren } from "react"

import { BaseLink } from "../base-link"
import { Icon, IconName } from "../icon"
import { create } from "src/helpers/bem"

import styles from "./Button.module.scss"

export const bem = create(styles, "Button")

export type ButtonProps = PropsWithChildren<{
  variant: "primary" | "secondary" | "tertiary" | "danger" | "delete"
  href?: string
  size?: "md" | "lg"
  type?: "button" | "submit" | "reset"
  className?: string
  labelClassName?: string
  disabled?: boolean
  icon?: {
    name: IconName
    position: "left" | "right" | "top"
    size?: "md" | "lg"
  }
  onClick?: () => void
  "data-cy"?: string
  "aria-label"?: string
  id?: string
  target?: string
  download?: boolean
}>

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      size = "md",
      className,
      children,
      labelClassName,
      disabled,
      icon,
      type = "button",
      "data-cy": dataCy,
      onClick,
      "aria-label": ariaLabel,
      id,
      href,
      target,
      download,
    },
    ref,
  ) => {
    const defaultIcon = {
      name: icon?.name,
      position: icon?.position || "left",
      size: icon?.size || "md",
    }

    const modifiers = {
      [`${variant}`]: true,
      [`size-${size}`]: !!size,
      [`icon-position-${icon?.position}`]: !!icon?.position,
    }

    const iconSize =
      icon?.size === "lg" || icon?.position === "top" ? "lg" : icon?.size

    const iconModifiers = {
      [`icon-position-${icon?.position}`]: !!icon?.position,
      [`icon-size-${iconSize}`]: !!iconSize,
    }

    const content = (
      <>
        {defaultIcon?.name && (
          <Icon
            name={defaultIcon.name}
            className={bem("icon", iconModifiers)}
          />
        )}

        <span className={bem("label", undefined, labelClassName)}>
          {children}
        </span>
      </>
    )

    if (href) {
      return (
        <BaseLink
          id={id}
          href={href}
          target={target}
          onClick={disabled ? undefined : onClick}
          className={bem(undefined, modifiers, className)}
          data-cy={dataCy}
          aria-label={ariaLabel}
          download={download}
        >
          {content}
        </BaseLink>
      )
    }

    return (
      <button
        ref={ref}
        id={id}
        className={bem(undefined, modifiers, className)}
        disabled={disabled}
        type={type}
        data-cy={dataCy}
        onClick={onClick}
        aria-label={ariaLabel}
      >
        {content}
      </button>
    )
  },
)

Button.displayName = "Button"
