import { useContext } from "react"

import { UsercentricsContext } from "../context"
import type { ServiceId } from "../types"

import { hasServiceConsent } from "../utils"

import { useServiceInfo } from "./useServiceInfo"

/**
 * Returns `true` or `false` based on consent status, or `null` when unknown (not initialized).
 */
export const useHasServiceConsent = (serviceId: ServiceId): boolean | null => {
  const serviceInfo = useServiceInfo(serviceId)
  const { isInitialized, localStorageState } = useContext(UsercentricsContext)

  if (!isInitialized) {
    const saved = localStorageState.find((service) => service.id === serviceId)
    return saved ? saved.status : null
  }

  return hasServiceConsent(serviceInfo)
}
